import React from "react";

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import packageJson from '../../../../package.json';
import Colors from "../../../constants/Colors";
import CurrentPaymentsCard from "./cards/CurrentPaymentsCard";
import SitesCard from "./cards/SitesCard";
import WelcomeCard from "./cards/WelcomeCard";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    }
})

const DashboardComponent = ({ classes, history }) => {

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={2}>
                        <WelcomeCard history={history} />
                        {/* <CurrentHoursCard history={history} /> */}
                        <CurrentPaymentsCard history={history} />
                        <SitesCard history={history} />
                    </Grid>
                    <Typography variant='caption' component='h2' style={{ textAlign: 'center', fontSize: 14, marginTop: 16, color: Colors.darkGreyText}}>
                        Designed + Managed by <b>BCK</b> | {packageJson.version}
                    </Typography>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(DashboardComponent);