import React, { useState } from "react";
import { Button, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardBackspace } from "@material-ui/icons";
import { isEmpty } from "lodash";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { createNewUser } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { isValidEmail } from "../../../utilities/Validation";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    textField: {
        marginTop: 0
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
      }
})

const AdminAddUserComponent = ({ classes, history }) => {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const isValidForm = () => {
        const foundErrors = [];
        if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
        if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
        if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
        
        setErrors(foundErrors)
        return foundErrors.length === 0
      }

    const handleCreateUser = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);
            const user = await createNewUser(firstName, lastName, email);
            NotificationBarActions.showNotificationBar('success', 'User has been created');
            history.goBack();
        } catch(err) {
            const resp = await err;
            if (resp.code === 'user-exists') {
                setErrors(['email'])
                NotificationBarActions.showNotificationBar('error', 'A user with this email already exists.');
                return;
            }
            console.error(resp);
            NotificationBarActions.showNotificationBar('error', 'Could not create user at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Add User
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Required Information
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5 }}>
                                        Provide the necessary infomation to create the new account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                    autoFocus
                                    error={errors.includes('firstName')}
                                    fullWidth
                                    value={firstName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*First Name"
                                    variant="outlined"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                    error={errors.includes('lastName')}
                                    fullWidth
                                    value={lastName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Last Name"
                                    variant="outlined"
                                    onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    error={errors.includes('email')}
                                    fullWidth
                                    value={email}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*E-Mail"
                                    variant="outlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                        <Button 
                    className={classes.buttonContainedStyle}
                    variant='contained'
                    onClick={handleCreateUser} color="primary">
                    { isLoading ?
                      <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                      : <span>Create User</span>
                    }
                </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminAddUserComponent);