import { Card, CardContent, Grid, Tooltip, Typography, withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import Colors from "../../../../constants/Colors";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    maxWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  centerContent: {
    marginTop: 28,
    textAlign: 'center',
    fontSize: 48
  },
  pos: {
    marginBottom: 12,
    textAlign: 'center'
  },
  footer: {
    marginTop: 30,
    marginBottom: 2,
    textAlign: 'center',
    fontSize: 16
  },
 });

const GenericInfoCard = ({classes, history, title, content, subcontent, footer, info}) => {

  return (
    <Grid item xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container justify='space-between'>
            <Grid item xs={'auto'}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {title}
              </Typography>
            </Grid>
            {info !== undefined &&
            <Grid item xs={'auto'}>
              <Tooltip title={info}>
                <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
              </Tooltip>
            </Grid>
            }
            
          </Grid>
          <Typography className={classes.centerContent} variant="h5" component="h2">
            {content}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {subcontent}
          </Typography>
          <Typography className={classes.footer} color="textSecondary">
            {footer}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(GenericInfoCard);