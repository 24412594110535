import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, Close, KeyboardBackspace } from "@material-ui/icons";
import { capitalize, find, isEmpty } from "lodash";
import { Redirect } from "react-router";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { createNewSiteSubscription, fetchSiteSubscription, getProducts } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { isValidEmail } from '../../../utilities/Validation';

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
    },
    defaultText: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.darkGreyText
    }
})

const AdminCreateSubscriptionComponent = ({ classes, match, sites, users, history }) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ errors, setErrors ] = useState([])
    const [ subscriptionPrice, setSubscriptionPrice ] = useState(0)

    const [ hostingProduct, setHostingProduct ] = useState(undefined);
    const [ websiteDomain, setWebsiteDomain ] = useState(undefined);
    const [ websiteDomainProvider, setWebsiteDomainProvider ] = useState(undefined);
    const [ websiteDepStatus, setWebsiteDepStatus ] = useState('PROCESSING');

    const [ emailProduct, setEmailProduct ] = useState(undefined);
    const [ emailPrice, setEmailPrice ] = useState();
    const [ emails, setEmails ] = useState([]);
    const [ emailInEdit, setEmailInEdit ] = useState();

    const productsRef = useRef([]);
    const generalConsultingPriceRef = useRef(3000);
    const developmentConsultingPrice = useRef(10000);
    const selectedPricesRef = useRef([]);
    const hostingProductRef = useRef();
    const sendWelcomeEmailRef = useRef(true);

    const siteRef = useRef(find(sites, {site_id: match.params.site_id}));

    useEffect(() => {
        loadProducts()
    }, [])

    const loadProducts = async () => {
        try {
            setIsLoading(true)
            const resp = await getProducts()
            productsRef.current = resp.products;
            hostingProductRef.current = resp.products.find((product) => product.metadata.group !== undefined && product.metadata.group === 'hosting')
        } catch (err) {
            NotificationBarActions.showNotificationBar('error', 'Could not load stipe products at this time.');
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const isFormValid = () => {
        const foundErrors = [];
        if (generalConsultingPriceRef.current === undefined || generalConsultingPriceRef.current < 0) foundErrors.push('generalConsultingPrice')
        if (developmentConsultingPrice.current === undefined || developmentConsultingPrice.current < 0) foundErrors.push('developmentConsultingPrice')
        if (hostingProduct === undefined) foundErrors.push('hostingProduct')
        else if (hostingProduct !== 'NONE') {
            if (!websiteDomain || isEmpty(websiteDomain)) foundErrors.push('websiteDomain')
            if (!websiteDomainProvider || isEmpty(websiteDomainProvider)) foundErrors.push('websiteDomainProvider')
            if (!websiteDepStatus || isEmpty(websiteDepStatus)) foundErrors.push('websiteDeploymentStatus')
        } else {
            if (!websiteDomain || isEmpty(websiteDomain)) foundErrors.push('websiteDomain')
        }

        if (emailProduct && !emailPrice) foundErrors.push('emailPrice')
        if (emailProduct && emailPrice && emails.length === 0) foundErrors.push('emailToAdd')
        setErrors(foundErrors)

        return foundErrors.length === 0;
    }

    const createSubscription = async () => {
        if (!isFormValid()) {
            NotificationBarActions.showNotificationBar('error', 'Oh no! There seems to be an error in the form. Take a look above to fix before resubmitting.')
            return;
        }

        // create service modules
        const consulting = {
            general_consulting: {
                pricing: {
                    default: generalConsultingPriceRef.current,
                    onsite_markup_pct: 20,
                    weekend_markup_pct: 50,
                    emergency_markup_pct: 50
                }
            },
            development_consulting: {
                pricing: {
                    default: developmentConsultingPrice.current,
                    onsite_markup_pct: 20,
                    weekend_markup_pct: 50,
                    emergency_markup_pct: 50
                }
            }
        };
        const website = hostingProduct === 'NONE' ? {
            unmanaged: true,
            domain: {
                url: websiteDomain
            }
        } : {
            price_id: hostingProduct.id,
            status: websiteDepStatus,
            domain: {
                url: websiteDomain,
                provider: websiteDomainProvider
            }
        }
        const email = emailProduct === undefined ? undefined : {
            product_id: emailProduct.id,
            price_id: emailPrice.id,
            emails: emails
        }
        const site_service_modules = { consulting, website, email };
        const options = {
            send_welcome_email: sendWelcomeEmailRef.current
        }

        const site_subscription_products = selectedPricesRef.current.map((priceId) => ({ price: priceId, quantity: 1 }))
        if(emailProduct !== undefined) site_subscription_products.push({ price: emailPrice.id, quantity: emails.length });
        if (hostingProduct !== 'NONE') site_subscription_products.push({ price: hostingProduct.id, quantity: 1 });

        try {
            setIsLoading(true)
            const resp = await createNewSiteSubscription(getUserId(), siteRef.current.site_id, site_service_modules, site_subscription_products, options)
            const updatedSite = resp.site;
            // need to update global site holder
            NotificationBarActions.showNotificationBar('success', 'Subscription created successfully!');
            history.goBack();
        } catch(err) {
            console.error(err)
            NotificationBarActions.showNotificationBar('error', 'Could not create subscription at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    const setGeneralConsultingPrice = (price) => {
        if (price === undefined) return
        generalConsultingPriceRef.current = Number(price) * 100
    }

    const setDevelopmentConsultingPrice = (price) => {
        if (price === undefined) return
        developmentConsultingPrice.current = Number(price) * 100
    }

    const handleCheckboxChange = (price, checked) => {
        if (checked && !selectedPricesRef.current.includes(price.id)) {
            selectedPricesRef.current.push(price.id)
            setSubscriptionPrice(subscriptionPrice + price.unit_amount)
        } 
        else if (!checked && selectedPricesRef.current.includes(price.id)) {
            const prices = [...selectedPricesRef.current];
            const index = prices.indexOf(price.id)
            if (index >= 0) prices.splice(index, 1);
            selectedPricesRef.current = prices;
            setSubscriptionPrice(subscriptionPrice - price.unit_amount)
        }
    }

    /*const handleChangeWebsiteHosting = (prod) => {
        const previousPrice = hostingProduct ? hostingProduct.unit_amount : 0
        const newPrice = prod ? prod.unit_amount : 0
        setSubscriptionPrice(subscriptionPrice - previousPrice + newPrice)
        setHostingProduct(prod);
    }*/

    const handleChangeWebsiteHosting = (price_id) => {
        const priceObj = hostingProductRef.current.prices.find((price) => price.id === price_id);
        const previousPriceObj = hostingProductRef.current.prices.find((price) => price.id === hostingProduct)
        if (price_id === 'NONE') {
            const previousPrice = previousPriceObj ? previousPriceObj.unit_amount : 0
            setSubscriptionPrice(subscriptionPrice - previousPrice)
            setHostingProduct('NONE');
            return;
        } 
        
        const previousPrice = previousPriceObj ? previousPriceObj.unit_amount : 0
        const newPrice = priceObj ? priceObj.unit_amount : 0;
        setSubscriptionPrice(subscriptionPrice - previousPrice + newPrice)
        setHostingProduct(priceObj);
    }

    const handleRemoveEmail = (email) => {
        const updatedEmails = [ ...emails ];
        const index = updatedEmails.indexOf(email)
        if (index < 0) return
        updatedEmails.splice(index, 1)
        setEmails(updatedEmails)
        setSubscriptionPrice(subscriptionPrice - emailPrice.unit_amount);
    }

    const handleAddEmail = () => {
        if (!isValidEmail(emailInEdit) || isEmpty(emailInEdit)) { setErrors(['emailToAdd']); return; }
        else setErrors([])
        
        setEmails([...emails, emailInEdit]);
        setEmailInEdit('')
        setSubscriptionPrice(subscriptionPrice + emailPrice.unit_amount);
    }

    const renderAddEmailSection = () => (
        <Grid item xs={12}>
            <List>
                {emails.length > 0 ? emails.map((email) => (
                <ListItem dense key={email}>
                    <ListItemText primary={email} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveEmail(email)}>
                            <Close />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                )) : 
                <ListItem dense key={'none'}>
                    <i className={classes.defaultText}>No Emails Added...</i>
                </ListItem>
                }
            </List>
            <TextField
                fullWidth
                className={classes.textField}
                error={errors.includes('emailToAdd')}
                helperText={errors.includes('emailToAdd') && 'Please enter a unique and valid email.'}
                onKeyPress={(e) => {if (e.key === 'Enter') { handleAddEmail();}}}
                label="Email To Add"
                variant="outlined"
                value={emailInEdit}
                onChange={(e) => setEmailInEdit(e.target.value)}
                InputProps={{
                    endAdornment:
                    <IconButton variant='contained' style={{ marginTop: 5}} onClick={handleAddEmail}>
                        <Add />
                    </IconButton>
                }}
            />
        </Grid>
    )
    const renderWebsiteDetails = () => (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    error={errors.includes('websiteDomain')}
                    helperText={errors.includes('websiteDomain') && 'Please provide a website domain for this package.'}
                    label="Primary Website Domain"
                    variant="outlined"
                    onChange={(e) => setWebsiteDomain(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    fullWidth
                    error={errors.includes('websiteDomainProvider')}
                    helperText={errors.includes('websiteDomainProvider') && 'Please select a provider for this website.'}
                    className={classes.textField}
                    label="Domain Provider"
                    variant="outlined"
                    onChange={(e) => setWebsiteDomainProvider(e.target.value)}
                >
                    <MenuItem key={'self-godaddy'} value={'CLIENT:GODADDY'}>
                        Client Purchased - Go Daddy
                    </MenuItem>
                    <MenuItem key={'self-aws'} value={'CLIENT:AWS'}>
                        Client Purchased - AWS
                    </MenuItem>
                    <MenuItem key={'self-other'} value={'CLIENT:OTHER'}>
                        Client Purchased - Other
                    </MenuItem>
                    <MenuItem key={'bck-godaddy'} value={'BCK:GODADDY'}>
                        BCK Managed - Go Daddy
                    </MenuItem>
                    <MenuItem key={'bck-aws'} value={'BCK:AWS'}>
                        BCK Managed - AWS
                    </MenuItem>
                    <MenuItem key={'unknown'} value={'UNKNOWN'}>
                        Unknown
                    </MenuItem>
                    <MenuItem key={'none'} value={'NONE'}>
                        Not Applicable
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    fullWidth
                    error={errors.includes('websiteDeploymentStatus')}
                    helperText={errors.includes('websiteDeploymentStatus') && 'Please provide a status for this website.'}
                    className={classes.textField}
                    label="Deployment Status"
                    variant="outlined"
                    defaultValue={'PROCESSING'}
                    onChange={(e) => setWebsiteDepStatus(e.target.value)}
                >
                    <MenuItem key={'processing'} value={'PROCESSING'}>
                        Processing
                    </MenuItem>
                    <MenuItem key={'progress'} value={'IN_PROGRESS'}>
                        In Progress
                    </MenuItem>
                    <MenuItem key={'draft'} value={'DRAFT'}>
                        Draft
                    </MenuItem>
                    <MenuItem key={'finalizing'} value={'FINALIZING'}>
                        Finalizing
                    </MenuItem>
                    <MenuItem key={'active'} value={'ACTIVE'}>
                        Active
                    </MenuItem>
                    <MenuItem key={'inactive'} value={'INACTIVE'}>
                        Inactive
                    </MenuItem>
                    <MenuItem key={'hidden'} value={'HIDDEN'}>
                       Hidden
                    </MenuItem>
                    <MenuItem key={'none'} value={'NONE'}>
                        Not Applicable
                    </MenuItem>
                </TextField>
            </Grid>
        </React.Fragment>
    )

    if (siteRef.current === undefined) {
        NotificationBarActions.showNotificationBar('error', `Could not find Site ID: ${match.params.site_id}`);
        return <Redirect to='/admin' />
    }

    if (siteRef.current.site_external_connections !== undefined && siteRef.current.site_external_connections.stripe !== undefined && siteRef.current.site_external_connections.stripe.subscription_id !== undefined) {
        NotificationBarActions.showNotificationBar('error', `A subscription for this site already exists...`);
        history.goBack();
        return <div></div>
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Create Subscription
                            </Typography>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                {siteRef.current.site_details.site_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" style={{ width: '100%'}}>
                        <div style={{ padding: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h2">
                                        Consulting Fees
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        General Consulting
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                        Convering general technology guidance, questions and basic set up for most online accounts and devices.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.includes('generalConsultingPrice')}
                                        helperText={errors.includes('generalConsultingPrice') && 'Must provide at least a positive price in this field.'}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="Default Price"
                                        defaultValue={'30.00'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>$</span>
                                        }}
                                        onChange={(e) => setGeneralConsultingPrice(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="On Site"
                                        defaultValue={'20'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="Weekends"
                                        defaultValue={'50'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        label="Emergencies"
                                        defaultValue={'50'}
                                        variant="outlined"
                                        type="number"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>
                                        ,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Development Consulting
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                        Creating or maintaining of large systems that involve (but not limited too) software development, development operations, cloud development, custom application management and application deployment
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.includes('developmentConsultingPrice')}
                                        helperText={errors.includes('developmentConsultingPrice') && 'Must provide at least a positive price in this field.'}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="Default Price"
                                        defaultValue={'100.00'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>$</span>
                                        }}
                                        onChange={(e) => setDevelopmentConsultingPrice(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="On Site"
                                        defaultValue={'20'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        type="number"
                                        label="Weekends"
                                        defaultValue={'50'}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        className={classes.textField}
                                        margin="normal"
                                        label="Emergencies"
                                        defaultValue={'50'}
                                        variant="outlined"
                                        type="number"
                                        InputProps={{
                                            startAdornment:
                                        <span onClick={()=>0}>+</span>
                                        ,
                                            endAdornment:
                                        <span onClick={()=>0}>%</span>
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <Paper variant="outlined" style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
                        <div style={{ padding: 16}}>
                            <Grid container spacing={2}>
                                {hostingProductRef.current !== undefined && 
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h2">
                                        {hostingProductRef.current.name}
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{marginTop: -5}}>
                                        {hostingProductRef.current.description}
                                    </Typography>
                                </Grid>
                                }
                                {hostingProductRef.current === undefined && isLoading &&
                                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                }
                                {!isLoading && hostingProductRef.current.prices.length > 0 && <Grid item xs={12} style={{ maringTop: 16 }}>
                                    <TextField
                                        select
                                        error={errors.includes('hostingProduct')}
                                        helperText={errors.includes('hostingProduct') && 'Please choose a product.'}
                                        fullWidth
                                        className={classes.textField}
                                        label="Package"
                                        variant="outlined"
                                        onChange={(e) => handleChangeWebsiteHosting(e.target.value)}
                                    >
                                        <MenuItem key={'NONE'} value={'NONE'}>
                                            <i>No Hosting Package</i>
                                        </MenuItem>
                                        {hostingProductRef.current.prices.map(price => (
                                        <MenuItem key={price.id} value={price.id}>
                                            {price.nickname ? price.nickname : 'No Name'}: ${(price.unit_amount / 100).toFixed(2)}/mo
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> }
                                {(hostingProduct !== undefined && hostingProduct !== 'NONE') && renderWebsiteDetails()}
                                {!isLoading && hostingProduct === 'NONE' &&
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Unmanaged Website
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                        Provide the domain of the website for this business for record purposes.
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        className={classes.textField}
                                        error={errors.includes('websiteDomain')}
                                        helperText={errors.includes('websiteDomain') && 'Please provide a website domain for our records.'}
                                        label="Primary Website Domain"
                                        variant="outlined"
                                        value={emailInEdit}
                                        onChange={(e) => setWebsiteDomain(e.target.value)}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </div>
                        
                    </Paper>
                    <Paper variant="outlined" style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
                        <div style={{ padding: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h2">
                                        Products & Services
                                    </Typography>
                                </Grid>
                                {productsRef.current.length === 0 && isLoading &&
                                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                }
                                {productsRef.current.filter(product => (product.metadata.group === undefined || (product.metadata.group !== 'email' && product.metadata.group !== 'hosting')) && product.prices.length > 0).map((product) => (
                                    <Grid item xs={12} style={{ maringTop: 16 }}>
                                        <Typography variant="subtitle1" component="h2">
                                            {product.name}
                                        </Typography>
                                        <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                            {product.description}
                                        </Typography>
                                        {product.prices.map((price) => (
                                            <div style={{ fontFamily:'Nunito Sans, sans-serif' }}>
                                                <Checkbox onChange={(e) => handleCheckboxChange(price, e.target.checked)}/> {price.nickname === null ? <i>No Name</i> : price.nickname} ({capitalize(price.type)}) - ${(price.unit_amount / 100).toFixed(2)}
                                            </div>
                                        ))}
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        
                    </Paper>
                    <Paper variant="outlined" style={{ width: '100%', marginTop: 16, marginBottom: 16 }}>
                        <div style={{ padding: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h2">
                                        Email Services
                                    </Typography>
                                </Grid>
                                {productsRef.current.length === 0 && isLoading &&
                                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                }
                                {!isLoading && productsRef.current.length > 0 && <Grid item xs={12} style={{ maringTop: 16 }}>
                                    <TextField
                                        select
                                        error={errors.includes('companyStructure')}
                                        fullWidth
                                        className={classes.textField}
                                        label="Provider"
                                        variant="outlined"
                                        defaultValue={undefined}
                                        onChange={(e) => setEmailProduct(e.target.value)}
                                    >
                                        <MenuItem key={'none'} value={undefined}>
                                            <i>No Email Service</i>
                                        </MenuItem>
                                        {productsRef.current.filter(product => product.metadata.group !== undefined && product.metadata.group === 'email').map((product) => (
                                        <MenuItem key={product.id} value={product}>
                                            {product.name ? product.name : 'No Name'}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> }
                                {emailProduct && <Grid item xs={12} style={{ maringTop: 16 }}>
                                    <TextField
                                        select
                                        error={errors.includes('emailPrice')}
                                        helperText={errors.includes('emailPrice') && 'Please choose a price for this product.'}
                                        fullWidth
                                        className={classes.textField}
                                        label="Pricing"
                                        variant="outlined"
                                        defaultValue={'No Email Service'}
                                        onChange={(e) => setEmailPrice(e.target.value)}
                                    >
                                        {emailProduct.prices.map((price) => (
                                        <MenuItem key={price.id} value={price}>
                                            {price.nickname ? price.nickname : 'No Name'}: ${(price.unit_amount / 100).toFixed(2)}/mo
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> }
                                {emailProduct && emailPrice && renderAddEmailSection()}
                            </Grid>
                        </div>
                        
                    </Paper>
                    <Grid container justify="flex-end" style={{marginTop: 10, marginBottom: 50 }}>
                        <Switch defaultChecked color="primary" onChange={(e) => sendWelcomeEmailRef.current = e.target.checked}/> <span style={{ paddingTop: 8, paddingRight: 16, fontFamily:'Nunito Sans, sans-serif', }}>Send Welcome Email</span>
                        <Button 
                            className={classes.buttonContainedStyle}
                            variant='contained'
                            onClick={createSubscription}
                            color="primary">
                            { isLoading ?
                            <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                            : <span>Create Subscription (${(subscriptionPrice / 100).toFixed(2)} / mo)</span>
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminCreateSubscriptionComponent);