import { InputAdornment, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, withStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useState } from "react";
import Colors from "../../../constants/Colors";
import { JOB_STATUS, JOB_STATUS_MAP } from "../../../constants/Statuses";
import groupBy from 'lodash/groupBy';


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminJobsViewTabPanel = ({classes, history, jobs = [], sites = [], users = []}) => {

  const [filteredJobs, setFilteredJobs] = useState()

  const handleFilter = (input) => {
    if (input === undefined || input.trim() === '') {
      setFilteredJobs(undefined)
      return;
    }
    const filtered = jobs.filter(job => job.job_details.job_name.toLowerCase().includes(input.toLowerCase()))
    setFilteredJobs(filtered);
  }

  const getJobs = () => filteredJobs === undefined ? sortJobs(jobs) : sortJobs(filteredJobs);

  /*
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  QUEUED: 'QUEUED',
  REJECTED:  'REJECTED',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  REQUESTED_CLIENT_FEEDBACK: 'REQUESTED_CLIENT_FEEDBACK',
  REQUESTED_CLIENT_ITEMS: 'REQUESTED_CLIENT_ITEMS',
  STOPPED: 'STOPPED',
  COMPLETED: 'COMPLETED'
  */
  const sortJobs = (jobs) => {
    const importanceOrder = [JOB_STATUS_MAP.IN_PROGRESS, JOB_STATUS_MAP.QUEUED, JOB_STATUS_MAP.APPROVED, JOB_STATUS_MAP.PENDING_APPROVAL, JOB_STATUS_MAP.DRAFT, JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK, JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS, JOB_STATUS_MAP.PAUSED, JOB_STATUS_MAP.COMPLETED , JOB_STATUS_MAP.STOPPED , JOB_STATUS_MAP.REJECTED]
    const groups = groupBy(jobs, (j) => j.job_details.job_status);
    const sortedJobs = []
    for (const status of importanceOrder) {
      if (!groups[status]) continue;
      sortedJobs.push(...groups[status].sort((a, b) => a.job_details.job_name - b.job_details.job_name))
    }
    
    return sortedJobs;
  }

  return (
    <React.Fragment>
      <TextField
        fullWidth
        id="filter-bar__search"
        label="Search"
        InputLabelProps={{
            style: { fontSize: 20 }
        }}
        onChange={(e) => handleFilter(e.target.value)}
        inputProps={{
            style: {
                textAlign: "left",
                fontFamily:'Nunito Sans, sans-serif',
                fontWeight: 'bold',
                fontSize: 20
            },
        }}
        InputProps={{
            startAdornment: <InputAdornment position="start"><Search style={{fontSize: 24, color: Colors.greyColor }}/></InputAdornment>,
        }}
        variant="outlined"
      />
      <TableContainer component={Paper} style={{ marginTop: 24 }}>
      <Table sx={{ minWidth: 650, fontSize: 12 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Project</TableCell>
            <TableCell align="right">Site</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getJobs().map((row) => {
            const site = sites.find(site => site.site_id === row.job_site_id)
            if (!site) return undefined
            console.log('row', row)
            return (<TableRow
              key={row.job_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', fontSize: 12 }}
            >
              <TableCell component="th" scope="row" style={{ fontSize: 16 }}>
                <Link href={`/dashboard/site/${row.job_site_id}/jobs/${row.job_id}`}>{row.job_details.job_name}</Link>
              </TableCell>
              <TableCell align="right" style={{ fontSize: 16 }}>{sites.find(site => site.site_id === row.job_site_id).site_details.site_name}</TableCell>
              <TableCell align="right" style={{ fontSize: 16 }}>{JOB_STATUS.find(status => status.value === row.job_details.job_status).label}</TableCell>
            </TableRow>)
          }).filter((r) => r)}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
  )
}

export default withStyles(styles)(AdminJobsViewTabPanel);