import React from 'react';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { Dialog, Grid, Button, DialogActions, TextField, CircularProgress } from '@material-ui/core';
import Styles from '../../../constants/Styles';
import { useState } from 'react';
import Colors from '../../../constants/Colors';
import { isEmpty } from 'lodash';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
      },
      buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
      }
}

const CreateAccountModal = ({classes, open, onCreateAccount, onLeave, isLoading }) =>  {

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [errors, setErrors] = useState([]);

    const isValid = () => {
        if (!password || isEmpty(password)) {
            setErrors(['password']);
            return;
        }
        const foundErrors = [];
        const capitalVal = (/[A-Z]/.test(password));
        const numberVal = (/\d/.test(password));
        if (!password || password.length < 8 || !capitalVal || !numberVal) foundErrors.push('password');
        if (!foundErrors.includes('password') && password !== confirmPassword) foundErrors.push('confirmPassword');
        setErrors(foundErrors);
        return foundErrors.length === 0; 
    }

    const handleCreateAccount = () => {
        if (!isValid()) return;
        onCreateAccount(password);
    }

    return (
        <Dialog
        aria-labelledby="add-item-modal"
        aria-describedby="add an item to your inventory"
        open={open}
        >
            <Grid container justify="center">
                <Grid item xs={11}>
                    <div className={classes.headerTextStyle}>Last Step!</div>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={11}>
                    <div className={classes.headerSubTextStyle}>Please provide a secure password to finish creating your account with Better Call Kyle. Make sure to be at least <b>8 characters long</b>, containing <b>at least one number, and one capital letter.</b></div>
                </Grid>
                <Grid item xs={11}>
                    <TextField
                      error={errors.includes('password')}
                      helperText={errors.includes('password') && 'Required: At least 8 characters, one number, one capital letter.'}
                      fullWidth
                      value={password}
                      className={classes.textField}
                      onKeyPress={(e) => {if (e.key === 'Enter') { handleCreateAccount();}}}
                      margin="normal"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={11}>
                    <TextField
                      error={errors.includes('confirmPassword')}
                      helperText={errors.includes('confirmPassword') && 'The two passwords did not match.'}
                      fullWidth
                      value={confirmPassword}
                      className={classes.textField}
                      onKeyPress={(e) => {if (e.key === 'Enter') { handleCreateAccount();}}}
                      margin="normal"
                      type="password"
                      label="Confirm Password"
                      variant="outlined"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{ marginTop: 0 }}
                    />
                </Grid>
            </Grid>
            <DialogActions>
                <Button 
                    className={classes.buttonStyle}
                    onClick={onLeave} color="primary">
                    Not Now
                </Button>
                <Button 
                    className={classes.buttonContainedStyle}
                    variant='contained'
                    onClick={handleCreateAccount} color="primary">
                    { isLoading ?
                      <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                      : <span>Create Account</span>
                    }
                </Button>
                
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(CreateAccountModal);