import { observable, action, decorate } from "mobx";

class ExternalHeaderStore {
    location = 'home';
    showDrawer = false;

    setLocation(location) {
        this.location = location;
    }

    setShowDrawer(show) {
        this.showDrawer = show;
    }
    
};

decorate(ExternalHeaderStore, {
    location: observable,
    showDrawer: observable,

    setLocation: action,
    setShowDrawer: action
});

export default new ExternalHeaderStore();
