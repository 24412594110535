import React from "react";
import { Card, CardActions, CardContent, Grid, Typography, withStyles } from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import { groupBy } from "lodash";

const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const CurrentHoursCard = ({classes, sites, users}) => {
  const siteGroups = groupBy(sites, 'site_details.site_status')
  return (
    <React.Fragment>
      <Grid item md={6} xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Sites
            </Typography>
            <Typography variant="h6" component="h2">
              {sites.length} Accounts
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {Object.keys(siteGroups).map(key => `${siteGroups[key].length} ${key !== 'undefined' ? key.toLowerCase() : 'with no status'}`).join(' | ')}
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Users
            </Typography>
            <Typography variant="h6" component="h2">
              {users.length} Users
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              created.
            </Typography>
          </CardContent>
          <CardActions>
          </CardActions>
        </Card>
      </Grid>
    </React.Fragment>
    
  )
}

export default withStyles(styles)(CurrentHoursCard);