import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, useMediaQuery } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Styles from '../../../constants/Styles';
import { getCurrentMonthName, getCurrentYear } from '../../../utilities/DateUtilities';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const ViewInvoiceModal = ({ classes, history, invoices = [], onClose, open }) => {

    const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const showInvoiceTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    }

    return (
        <Dialog
        fullWidth
        aria-labelledby="view-invoice"
        aria-describedby="view invoices"
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        fullScreen={matches}
        >
            <DialogTitle>
                Open Invoices
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <List className={classes.root}>
                            {invoices.map((invoice) => (
                            <ListItem button onClick={() => showInvoiceTab(invoice.hosted_invoice_url)} key={invoice.id}>
                                <ListItemText primary={invoice.name} secondary={invoice.customer_name} />
                                <ListItemSecondaryAction>
                                    <Typography variant='caption' component='h2'>
                                        ${(invoice.amount_due/100).toFixed(2)}
                                    </Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            ))}
                            {(!invoices || invoices.length === 0) &&
                            <Typography variant='subtitle1' component='h2'>
                                No Invoices Reported
                            </Typography>
                            }
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={classes.buttonStyle}
                    onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(ViewInvoiceModal);