import React, { useState } from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, List, ListItem, ListItemText, Typography, withStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import NotificationBarActions from "../../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../../actions/profile/UserActions";
import { connectUsersToSite } from "../../../../clients/bettercallkyle/BckApi";
import Colors from "../../../../constants/Colors";
import AddUserToSiteModal from "../../modals/AddUserToSiteModal";

const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminSiteUsersDetailsCard = ({classes, history, site, users}) => {

  const [isLoading, setIsLoading] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false);

  const getUsersFullName = (user) => {
    if (!user) return 'Unknown User';
    else return `${user.user_personal.first_name} ${user.user_personal.last_name}`
  }

  const handleSaveAccessibleUserIds = async (accessible_user_ids, sendEmail) => {
    try {
      setIsLoading(true)
      
      const disconnect_user_ids = site.site_security.accessible_user_ids.filter((id) => !accessible_user_ids.includes(id));
      const connect_user_ids = accessible_user_ids.filter((id) => !site.site_security.accessible_user_ids.includes(id));

      await connectUsersToSite(site.site_id, connect_user_ids, disconnect_user_ids, getUserId());
      NotificationBarActions.showNotificationBar('success', "Successfully updated the site's users!");
      setShowAddModal(false)
    } catch(err) {
      NotificationBarActions.showNotificationBar('error', 'Could not update users for this site. Please try again later.');
      console.error(err);
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseModal = () => {
    setShowAddModal(false)
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
       <CardHeader
        action={
          <IconButton aria-label="settings" onClick={() => setShowAddModal(true)}>
            <Edit />
          </IconButton>
        }
        title="Users"
        subtitleText="Accounts that can access this site"
      />
        <CardContent>
          <List className={classes.root}>
            {users && users.filter((user) => site.site_security.accessible_user_ids.includes(user.user_id)).map((user) => (
              <ListItem button onClick={() => history.push(`/admin/users/${user.user_id}`)} key={user.user_id}>
                <ListItemText primary={getUsersFullName(user)} secondary={user.user_login_id} />
              </ListItem>
            ))}
            {(!users || users.length === 0) && 
              <React.Fragment>
                <Typography variant="body1" component="h2">
                  No Accounts Open Yet...
                </Typography>
              </React.Fragment>
            }
          </List>
        </CardContent>
      </Card>
      <AddUserToSiteModal
        history={history}
        site={site}
        users={users}
        selectedUserIds={site.site_security.accessible_user_ids}
        open={showAddModal} onSave={handleSaveAccessibleUserIds} onClose={handleCloseModal} isLoading={isLoading} />
    </Grid>
  )
}

export default withStyles(styles)(AdminSiteUsersDetailsCard);