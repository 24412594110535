import { Card, CardContent, CircularProgress, Grid, IconButton, Tooltip as MaterialToolTip, Typography, withStyles } from "@material-ui/core";
import { ArrowDownwardSharp, ArrowUpwardSharp, Dashboard } from "@material-ui/icons";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getSiteWebsiteReport } from "../../../../clients/bettercallkyle/BckApi";
import Colors from "../../../../constants/Colors";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  graphGrid: {
    display: 'default',
    [theme.breakpoints.down('sm')]: {
        display:'none'
    },
  },
  clickableGrid: {
    '&:hover': {
      backgroundColor:  'rgba(25,25,25,0.2)',
      borderRadius: 5,
      cursor: 'pointer'
    },
  },
  statFont: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign:'left',
    },
  },
  statValueFont: {
    textAlign: 'center',
    fontSize: 48,
    [theme.breakpoints.down('sm')]: {
      textAlign:'left',
      fontSize: 36
    },
  },
 });

 const fillerData = [
  {visitors: 0, visitors_last_week: 0, name: 'Sun'},
  {visitors: 0, visitors_last_week: 0, name: 'Mon'},
  {visitors: 0, visitors_last_week: 0, name: 'Tues'},
  {visitors: 0, visitors_last_week: 0, name: 'Wed'},
  {visitors: 0, visitors_last_week: 0, name: 'Thurs'},
  {visitors: 0, visitors_last_week: 0, name: 'Fri'},
  {visitors: 0, visitors_last_week: 0, name: 'Sat'},
 ]


const WebsiteStatsCard = ({classes, history, site}) => {

  const websiteData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  
  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      const website = await getSiteWebsiteReport(site.site_id)
      websiteData.current = website;
    } catch (ex) {
      setError('Could not load website data!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const prepareData = () => {
    return websiteData.current.report.visitors.timeline.map((stat) => ({
      visitors: stat.count,
      visitors_last_week: stat.visitor_court_past,
      name: stat.date_string,
  })).reverse();
  }

  const renderContent = () => isLoading ? (
    <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
  ) : (
    <React.Fragment>
      <Grid item xs={11} className={classes.graphGrid}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart width={800} height={400} data={websiteData.current && websiteData.current.report.visitors ? prepareData() : fillerData}>
            <Line type="linear" dataKey="visitors" stroke={Colors.purpleThemeColor} strokeWidth={2} />
            <Line type="linear" dataKey="visitors_last_week" stroke={Colors.blueColor} strokeWidth={1}/>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" style={{ fontSize: 14 }}/>
            <YAxis />
            <Tooltip formatter={(value, name, props) => { return [`${value}`, `${capitalize(name.replace('_',' ').replace('_',' '))}`] }} />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </React.Fragment> 
  );

  const hasClickyEnabled = () => {
    return websiteData.current !== undefined && (websiteData.current.message === undefined || websiteData.current.message !== 'no-clicky-connected')
  }

  const renderPercentageSection = (current_number, past_number) => {
    const isNegative = current_number < past_number;
    const color = isNegative ? Colors.redColor : Colors.greenColor;
    let percentChange = current_number - past_number;
    if (isNegative) percentChange *= -1;
    percentChange = percentChange / past_number * 100;
    return (
        <span style={{ color }}>{isNegative ? <ArrowDownwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> : <ArrowUpwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> } {(percentChange).toFixed(1)}% </span>
    )
  }

  if (isLoading) {
    return (
      <Grid item xs={12} md={6}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Website Statistics
          </Typography>
          <Typography variant="h6" component="h2" style={{ marginTop: 0, paddingTop: 0 }}>
            Loading...
          </Typography>
          <div style={{marginTop: 16 }}>
            <CircularProgress thickness={5} />
          </div>
        </CardContent>
      </Card>
    </Grid>
    )
  }

  return (
    <Grid item xs={12} md={6}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Grid container justify="space-between">
            <Grid item={'auto'}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Statistics
              </Typography>
              <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0, marginBottom: 16 }}>
                {websiteData.current && websiteData.current.report.visitors ? 'Visitors' : 'Not Enabled'}
              </Typography>
            </Grid>
            <Grid item={'auto'}>
              {websiteData.current !== undefined && websiteData.current.report.visitors && 
              <MaterialToolTip title={'View dashboard'}>
                <IconButton onClick={() => history.push(`/dashboard/site/${site.site_id}/statistics`, { site })}>
                  <Dashboard />
                </IconButton>
              </MaterialToolTip>
              }
            </Grid>
          </Grid>

          
        {websiteData.current === undefined || !websiteData.current.report.visitors ?
          <Typography variant="caption" component="h2" style={{ marginTop: -5, marginBottom: 10 }}>
            Website Statistics is not yet available on your account. Please reach out to us if you would like this feature enabled.
          </Typography>
          :
          <React.Fragment>
            <Typography variant="body1" component="h2" className={classes.statValueFont} >
              {websiteData.current.report.visitors.count}
            </Typography>
            <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 10, marginBottom: -30 }}>
             {renderPercentageSection(websiteData.current.report.visitors.count, websiteData.current.report.visitors.count_past)}
            </Typography>
            <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 28, marginBottom: 13 }}>
             in the past 7 days.
            </Typography>
          </React.Fragment>
         
        }
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(WebsiteStatsCard);


 /*<React.Fragment>
            {hasClickyEnabled() && renderContent()}
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12} md={4} className={classes.clickableGrid} onClick={() => history.push(`/dashboard/site/${site.site_id}/statistics`, { site })}>
              <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 10, marginBottom: -5 }}>
                  Visitors (Recent 7 Days)
                </Typography>
                <Typography variant="body1" component="h2" className={classes.statValueFont} style={{ color: Colors.purpleThemeColor }}>
                  {websiteData.current.report.visitors.count}
                </Typography>
                
              </Grid>
              <Grid item xs={12} md={4} className={classes.clickableGrid} onClick={() => history.push(`/dashboard/site/${site.site_id}/statistics`, { site })}>
                <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 10, marginBottom: -5}}>
                  Visitors (Previous 7 Days)
                </Typography>
                <Typography variant="body1" component="h2" className={classes.statValueFont} style={{ color: Colors.blueColor }}>
                  {websiteData.current.report.visitors.count_past}
                </Typography>
                
              </Grid>
              <Grid item xs={12} md={4} className={classes.clickableGrid} onClick={() => history.push(`/dashboard/site/${site.site_id}/statistics`, { site })}>
                <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 10, marginBottom: -5 }}>
                  Percent Of Change
                </Typography>
                <Typography variant="body1" component="h2" className={classes.statValueFont}>
                  {renderPercentageSection(websiteData.current.report.visitors.count, websiteData.current.report.visitors.count_past)}
                </Typography>
                
              </Grid>
            </Grid>
          </React.Fragment>*/