import { observable, action, decorate } from "mobx";

class NotificationBarStore {
    show = false;
    message = undefined;
    variant = undefined;

    showBar(variant, message) {
        this.show = true;
        this.message = message;
        this.variant = variant;
    }

    setShowBar(show) {
        this.show = show;
    }

    setMessage(message) {
        this.message = message;
    }

    setVariant(variant) {
        this.variant = variant;
    }
    
};

decorate(NotificationBarStore, {
    show: observable,
    message: observable,
    variant: observable,

    showBar: action,
    setShowBar: action,
    setMessage: action,
    setVariant: action
});

export default new NotificationBarStore();
