import React from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {AppBar, Button, Toolbar, Menu, MenuItem, IconButton, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';

import BckLogo from '../../assets/logo/logo-bck-sm.png' // relative path to image
import { useState } from "react";
import Colors from "../../constants/Colors";
import { TEST_MODE } from "../../constants/System";

const styles = theme => ({
  root: {
    width: '100%',
  },
  menuTextButton: {
      textTransform:'none',
      fontSize:15,
      fontWeight: 500,
      fontFamily:'Open Sans,Arial,sans-serif',
      color: '#000'
  },
  button: {
      textTransform:'none',
      fontSize:14,
      fontFamily:'Open Sans,Arial,sans-serif',
      fontWeight: 500,
      backgroundColor: Colors.primaryColor,
      color: '#fff',
      borderRadius: 0
  },
  textButton: {
      textTransform:'none',
      fontSize:14,
      fontWeight: 500,
      fontFamily:'Open Sans,Arial,sans-serif',
      color: '#000',
      '-webkit-font-smoothing': 'antialiased'
  },
  mobileMenu: {
      [theme.breakpoints.up('md')]: {
          display: 'none'
      }
  },
  desktopMenu: {
      [theme.breakpoints.down('sm')]: {
          display: 'none'
      },
      marginTop: 5
  },
  logo: {
    width:'auto',
    height:'auto',
    cursor:'pointer',
    [theme.breakpoints.down('sm')]: {
       marginTop: 10
    },
    [theme.breakpoints.up('md')]: {
        marginTop: 16
    }
    }
});

const ExternalHeaderV2 = ({ classes, history }) => {

    const [ menuElement, setMenuElement ] = useState();

    const handleMenuOpen = event => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(undefined);
    };

    const handleNavigate = (url) => {
        window.location.href = url;
    }

    const handleMyAccountOnClick = (e) => {
        e.stopPropagation();
        if(history.location.pathname !== '/login') {
            history.push('/login');
        }
    }

    const renderMenu = () => (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={menuElement !== undefined}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleNavigate('https://consultwithkyle.com/#services')} className={classes.menuTextButton}>SERVICES</MenuItem>
            <MenuItem onClick={() => handleNavigate('https://consultwithkyle.com/contact')}  className={classes.menuTextButton}>CONTACT</MenuItem>
            <MenuItem onClick={handleMyAccountOnClick} className={classes.menuTextButton}>MY ACCOUNT</MenuItem>
        </Menu>
    )
  
    return (
        <div className={classes.root}>
            <AppBar className="css-transition" position="fixed" elevation={3} style={{backgroundColor: '#fff', height: 70}}>
                { TEST_MODE && <div style={{ position: 'fixed', top: 0, left: '50%', backgroundColor: '#f00', padding: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, fontSize: 12 }}>TEST MODE</div> }
                <Toolbar>
                    <Grid container justify='space-between'>
                        <Grid item xs={'auto'}>
                            <div className={classes.logo} onClick={() => handleNavigate('https://consultwithkyle.com')}>
                                <img className="header-logo" style={{width:'auto', height:45}} src={BckLogo} alt={"logo"}/>
                            </div>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <div style={{height:60, paddingTop:16}} className={classes.desktopMenu}>
                                <Grid container direction='row'>
                                    <Grid item xs={'auto'}>
                                        <Button
                                            variant="text"
                                            className={classes.textButton}
                                            onClick={() => handleNavigate('https://consultwithkyle.com/#services')}
                                        >
                                            <span style={{paddingLeft: 5, paddingRight: 5, color: '#000'}}>SERVICES</span>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Button
                                            variant="text"
                                            className={classes.textButton}
                                            onClick={() => handleNavigate('https://consultwithkyle.com/contact')}
                                        >
                                            <span style={{paddingLeft: 5, paddingRight: 5}}>CONTACT</span> 
                                        </Button>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Button
                                            className={classes.button}
                                            variant="text"
                                            onClick={handleMyAccountOnClick}
                                            style={{marginLeft: 10 }}
                                        >
                                            <span style={{paddingLeft: 10, paddingRight: 10}}>MY ACCOUNT</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{height:60, paddingTop:8}} className={classes.mobileMenu}>
                                <Grid item xs={'auto'}>
                                    <IconButton
                                        onClick={handleMenuOpen}
                                        color="inherit"
                                    >
                                        <MenuIcon style={{ fontSize: 24, color: '#000' }}/>
                                    </IconButton>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            
            {renderMenu()}
        </div>
        );

}

export default withStyles(styles)(withRouter(ExternalHeaderV2));

/*
<div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
    <div style={{height:60, paddingTop:16}} className={classes.desktopMenu}>
        
        
        <Button
            className={classes.button}
            variant="outlined"
            onClick={handleMyAccountOnClick}
            style={{marginLeft: 10 }}
        >
            <span style={{paddingLeft: 10, paddingRight: 10}}>MY ACCOUNT</span>
        </Button>
        
    </div>
    <div style={{height:60, paddingTop:16}} className={classes.mobileMenu}>
        <IconButton
            onClick={handleMenuOpen}
            color="inherit"
        >
            <Menu style={{ fontSize: 24 }}/>
        </IconButton>
    </div>
</div>
*/