import ExternalHeaderStore from '../../stores/external/ExternalHeaderStore';

const ExternalHeaderActions = {
    externalHeaderStore : ExternalHeaderStore,

    updateLocation(location) {
        const pageName = this.__getPageName(location);
        if (this.externalHeaderStore.location !== pageName) {
            this.externalHeaderStore.setLocation(pageName);
        }
    },

    handleShowDrawer(show) {
        this.externalHeaderStore.setShowDrawer(show);
    },

    __getPageName(location) {
        if(location === '/') return 'home'
        else if(location === '/home') return 'home'
        else if(location === '/contact') return 'contact'
        else if(location === '/our-story') return 'story'
        else if(location === '/login') return 'login'
        else if(location === '/register') return 'register'
    }
}

export default ExternalHeaderActions;