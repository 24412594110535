import React, { useRef, useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Switch, TextField } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const AddUserToSiteModal = ({ classes, site, users, selectedUserIds, onSave, onClose, open, isLoading }) => {
    const sendEmailRef = useRef(true);

    const [ selectedIds, setSelectedIds ] = useState([...selectedUserIds]);

    const handleRemoveId = (id) => {
        const index = selectedIds.indexOf(id);
        const arr = [...selectedIds]
        arr.splice(index, 1)
        setSelectedIds(arr);
    }
    

    return (
        <Dialog
        fullWidth
        aria-labelledby="view-invoice"
        aria-describedby="view invoices"
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
        >
            <DialogTitle>
                Add User To Site
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item xs={12} style={{ display: 'none'}}>
                        <Switch
                            defaultChecked color="secondary"
                            onChange={(e) => sendEmailRef.current = e.target.checked}
                        /> <span style={{ fontFamily:'Nunito Sans, sans-serif' }}>Send Welcome Email</span>
                    </Grid>
                    <Grid item xs={12}>
                        <List className={classes.root}>
                        {users.filter(user => selectedIds.includes(user.user_id)).map((user) => (
                        <ListItem dense key={user.user_id}>
                            <ListItemText primary={`${user.user_personal.first_name} ${user.user_personal.last_name} `} secondary={user.user_login_id} />
                            {user.user_id !== site.site_security.primary_user_id &&
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => handleRemoveId(user.user_id)}>
                                        <Close />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                        ))}
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            className={classes.textField}
                            label="User"
                            variant="outlined"
                        >
                            {users.filter(user => !selectedIds.includes(user.user_id)).map((user) => (
                            <MenuItem key={user.user_id} value={user.user_id} onClick={(e) => setSelectedIds([...selectedIds, user.user_id])}>
                                {user.user_personal.first_name} {user.user_personal.last_name} <span style={{ fontSize: 14, paddingLeft: 10 }}>({user.user_login_id})</span>
                            </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={() => onSave(selectedIds, sendEmailRef.current)} color="primary">
                    { isLoading ?
                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    : 'Save'
                    }
                </Button>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(AddUserToSiteModal);