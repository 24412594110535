import React from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import { Edit, EmailOutlined, PhoneOutlined } from "@material-ui/icons";
import Colors from "../../../../constants/Colors";
import IconTextSection from "../../../common/IconTextSection";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminUserDetailsCard = ({ classes, history, user }) => {

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={() => history.push(`/admin/users/${user.user_id}/edit`)}>
              <Edit />
            </IconButton>
          }
          title="User Details"
        />
        <CardContent>
          <IconTextSection icon={<EmailOutlined />} primaryText={'Primary Email'} secondaryText={user.user_contact.email.primary} />
          <IconTextSection icon={<PhoneOutlined />} primaryText={'Primary Phone'} secondaryText={user.user_contact.phone && user.user_contact.phone.primary ? user.user_contact.phone.primary : <i>Not Provided</i>} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(AdminUserDetailsCard);