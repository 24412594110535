import React from "react";

import { Card, CardContent, Grid, Typography, withStyles } from "@material-ui/core";
import Colors from "../../../../constants/Colors";
import IconTextSection from "../../../common/IconTextSection";
import { AccountBox, Web, LocationOn } from "@material-ui/icons";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    height: '100%'
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const SiteDetailsCard = ({ classes, history, site }) => {


  const renderWebsite = () => {
    if (site && site.site_service_modules && site.site_service_modules.website && site.site_service_modules.website.domain )
    return <a style={{ color: Colors.primaryColor }} href={`https://${site.site_service_modules.website.domain.url}`} target='_blank'>{site.site_service_modules.website.domain.url}</a>
    else return <i>Not Available</i>
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Business Account
          </Typography>
          <Typography variant="h6" component="h2" style={{ fontSize: 24 }}>
            { site.site_details.site_name }
          </Typography>
          <IconTextSection
              icon={<Web />}
              primaryText={renderWebsite()}
              primaryTextStyle={{ fontSize: 18  }}
            />
          <IconTextSection
            icon={<LocationOn />}
            primaryText={ site.site_details.site_address && site.site_details.site_address.address_line_1 ? <span>{site.site_details.site_address.address_line_1} {site.site_details.site_address.address_line_2}</span> : <i>Not Provided</i> }
            primaryTextStyle={{ fontSize: 18  }}
            secondaryText={ site.site_details.site_address && site.site_details.site_address.address_line_1 ? <span>{site.site_details.site_address.city} {site.site_details.site_address.state}, {site.site_details.site_address.zipcode}</span> : '' }
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(SiteDetailsCard);