import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import { getUserId } from '../../../actions/profile/UserActions';
import { updateSite } from '../../../clients/bettercallkyle/BckApi';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const EditConsultingHoursModal = ({ classes, open, onClose, site }) => {

    const [generalConsultingPrice, setGeneralConsultingPrice] = useState(0)
    const [devConsultingPrice, setDevConsultingPrice] = useState(0);
    
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (open) {
            const consulting = site.site_service_modules && site.site_service_modules.consulting ? site.site_service_modules.consulting : undefined;
            if (!consulting) return;
            if (consulting && consulting.general_consulting && consulting.general_consulting.pricing) setGeneralConsultingPrice(consulting.general_consulting.pricing.default / 100)
            if (consulting && consulting.development_consulting && consulting.development_consulting.pricing) setDevConsultingPrice(consulting.development_consulting.pricing.default / 100)
        } else {
            setGeneralConsultingPrice(0)
            setDevConsultingPrice(0)
        }
    }, [open])

    const handleClose = () => {
        onClose();
    }

    const isFormValid = () => {
        const foundErrors = [];
        if (generalConsultingPrice === undefined || generalConsultingPrice < 0) foundErrors.push('generalConsultingPrice')
        if (devConsultingPrice === undefined || devConsultingPrice < 0) foundErrors.push('developmentConsultingPrice')
        setErrors(foundErrors)
        return foundErrors.length === 0;
    }

    const handleSave = async () => {
        if (!isFormValid()) return;

        // create service modules

        // TODO UPDATE THESE! DO not just create new ones
        const consulting = {
            general_consulting: {
                pricing: {
                    default: Number(generalConsultingPrice) * 100,
                    onsite_markup_pct: 20,
                    weekend_markup_pct: 50,
                    emergency_markup_pct: 50
                }
            },
            development_consulting: {
                pricing: {
                    default: Number(devConsultingPrice) * 100,
                    onsite_markup_pct: 20,
                    weekend_markup_pct: 50,
                    emergency_markup_pct: 50
                }
            }
        };

        try {
            setIsLoading(true)
            const resp = await updateSite(site.site_id, getUserId(), ['consulting'], { consulting })
            // need to update global site holder
            NotificationBarActions.showNotificationBar('success', 'Consulting hours updated.');
            handleClose()
        } catch(err) {
            console.error(err)
            const error = await err;
            NotificationBarActions.showNotificationBar('error', `Could not update consulting hours at this time. Details: [${error.code}] - ${error.message ? error.message : 'No Message'}`)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            aria-describedby="add an expense to a client"
            open={open}
            onClose={handleClose}
            >
            <DialogTitle>
                Edit Consulting Fees
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="h2">
                            General Consulting
                        </Typography>
                        <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                            Convering general technology guidance, questions and basic set up for most online accounts and devices.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={errors.includes('generalConsultingPrice')}
                            helperText={errors.includes('generalConsultingPrice') && 'Must provide at least a positive price in this field.'}
                            fullWidth
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="Default Price"
                            value={generalConsultingPrice}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                                <span onClick={()=>0}>$</span>
                            }}
                            onChange={(e) => setGeneralConsultingPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="On Site"
                            defaultValue={'20'}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="Weekends"
                            defaultValue={'50'}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            label="Emergencies"
                            defaultValue={'50'}
                            variant="outlined"
                            type="number"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>
                            ,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="h2">
                            Development Consulting
                        </Typography>
                        <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                            Creating or maintaining of large systems that involve (but not limited too) software development, development operations, cloud development, custom application management and application deployment
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={errors.includes('developmentConsultingPrice')}
                            helperText={errors.includes('developmentConsultingPrice') && 'Must provide at least a positive price in this field.'}
                            fullWidth
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="Default Price"
                            defaultValue={devConsultingPrice}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>$</span>
                            }}
                            onChange={(e) => setDevConsultingPrice(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="On Site"
                            defaultValue={'20'}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            type="number"
                            label="Weekends"
                            defaultValue={'50'}
                            variant="outlined"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            disabled
                            className={classes.textField}
                            margin="normal"
                            label="Emergencies"
                            defaultValue={'50'}
                            variant="outlined"
                            type="number"
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>+</span>
                            ,
                                endAdornment:
                            <span onClick={()=>0}>%</span>
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                className={classes.buttonStyle}
                disabled={isLoading}
                onClick={handleSave} color="primary">
                    { isLoading ?
                      <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                      : <span>Save</span>
                    }
                </Button>
            </DialogActions>
            </Dialog>
    )
}

export default withStyles(styles)(EditConsultingHoursModal);