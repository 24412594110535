import React, { useState, useEffect, useRef } from "react";
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Paper, Typography, CardHeader, Card, CardContent, Button, List, CircularProgress, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import Colors from "../../../constants/Colors";
import { AccountBoxOutlined, Close, Email, KeyboardBackspace, Pages, Phone } from "@material-ui/icons";
import Styles from "../../../constants/Styles";
import { observer } from "mobx-react";
import { getUserId, getUserObject } from "../../../actions/profile/UserActions";
import IconTextSection from "../../common/IconTextSection";
import { getAllSitesForUser } from "../../../actions/internal/SiteActions";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";

const styles = theme => ({
    innerPaper: Styles.innerPaperPadding,
    paper: {
        width: '100%',
        height: '100%',
        paddingBottom: 30
    },
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    crew: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.link,
    },
    actionButton: {
        fontFamily:'Nunito Sans, sans-serif',
        borderColor: Colors.primaryColor,
        textTransform: 'none',
        fontSize: 18,
        height: '100%',
        color: Colors.primaryColor,
    },
})

const UserAccountComponent = ({ classes, history }) => {

    const sitesData = useRef()
    const [ isLoading, setIsLoading ] = useState(true);
    
    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        try {
            const user_id = getUserId();
            if (user_id === undefined) throw 'No User ID';
            const sites = await getAllSitesForUser(user_id)
                .then((s) => (
                    s.sites.filter(site => site && site.site_security && site.site_security.primary_user_id === user_id)
                ));
            sitesData.current = sites;
        } catch (ex) {
            NotificationBarActions.showNotificationBar('error', 'Could not load business account information at this time. Please try again later.')
            console.error(ex);
        } finally {
            setIsLoading(false);
        }
    }

    const handleScheduleConsult = () => {
        window.open('https://consultwithkyle.com/start', '_blank').focus();
      };

    const getFullName = () => (
        `${getUserObject().user_personal.first_name} ${getUserObject().user_personal.last_name}`
    )

    const getEmail = () => (
        getUserObject().user_login_id
    )

    return (
    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 100, marginBottom: 50}}>
        <Grid container item xs={12} sm={9} md={7}>
            <Grid container justify='space-between'>
                <Grid item>
                    <Grid container justify='flex-start' spacing={1}>
                        <Grid item xs={2} md={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10} md={'auto'}>
                            <Typography variant="h6" component="h2">
                                Account Details
                            </Typography>
                            <Typography variant="caption" component="h2">
                                Review your info and security to make BCK Consulting work better for you.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Card className={classes.root} variant="outlined" style={{ marginTop: 18 }}>
                        <CardContent>
                            <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0 }}>
                                Personal Info
                            </Typography>
                            <IconTextSection
                                icon={<AccountBoxOutlined />}
                                primaryText={getFullName()}
                                primaryTextStyle={{ fontSize: 18  }}
                                />
                            <IconTextSection
                                icon={<Email />}
                                primaryText={getEmail()}
                                primaryTextStyle={{ fontSize: 18  }}
                                />
                            <IconTextSection
                                icon={<Phone />}
                                primaryText={<i>Not Provided</i>}
                                primaryTextStyle={{ fontSize: 18  }}
                                />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{ display: 'none' }}>
                    <Card className={classes.root} variant="outlined" style={{ marginTop: 18 }}>
                        <CardContent>
                            <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0 }}>
                                Security
                            </Typography>
                            <div style={{ marginTop: 20 }} >
                                <Button
                                    variant="outlined"
                                    className={classes.actionButton}
                                    
                                >
                                    Change Password
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.root} variant="outlined" style={{ marginTop: 18 }}>
                        <CardContent>
                            <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0 }}>
                                Billing
                            </Typography>
                            <Typography variant="caption" component="h2">
                                Payments, subscriptions, and invoice information is available for the primary account holder, within the business account details.
                            </Typography>
                            <List className={classes.root}>
                                {isLoading && <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />}
                                {sitesData.current && sitesData.current.map((site) => (
                                <ListItem key={site.site_id} button onClick={() => history.push(`/dashboard/site/${site.site_id}`, { site })}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Pages />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={site.site_details.site_name} />
                                </ListItem>
                                ))}
                                {!sitesData.current || sitesData.current.length === 0 && 
                                    <React.Fragment>
                                        <Typography variant="body1" component="h2">
                                            You are currently not a primary account holder for any business account.
                                        </Typography>
                                    </React.Fragment>
                                }
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </div>
    )
}

export default withStyles(styles)(UserAccountComponent);