import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Paper, IconButton, Typography, List, ListItem, ListItemText, Link, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import Colors from "../../../constants/Colors";
import { AssignmentOutlined, KeyboardBackspace, Payment, Receipt } from "@material-ui/icons";
import Styles from "../../../constants/Styles";
import { useEffect } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { useState } from "react";
import { getCurrentMonthName, getCurrentYear } from "../../../utilities/DateUtilities";
import { getSite, getSitePaymentReport } from "../../../actions/internal/SiteActions";

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const ViewSiteInvoicesComponent = ({ classes, history, location, match }) => {

    const [ siteData, setSiteData ] = useState();
    const [ stripeReport, setStripeReport ] = useState();
    const [ isLoading, setIsLoading ] = useState();

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        const stripe_invoice_report = state && state.stripe_invoice_report ? state.stripe_invoice_report : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined || stripe_invoice_report === undefined) loadData(site_id);
        else {
          setSiteData(site);
          setStripeReport(stripe_invoice_report);
        }
    }

    const showInvoiceTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
          const site = await getSite(site_id);
          if (site === undefined) {
            history.push('/dashboard');
            return;
          }
          const stripe_invoice_report = await getSitePaymentReport(site_id);
          setStripeReport(stripe_invoice_report);
          setSiteData(site);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details } = siteData ? siteData : {};
    const dueInvoices = stripeReport && stripeReport.report.paid_invoices ? stripeReport.report.open_invoices.invoices : [];
    const invoices = stripeReport && stripeReport.report.paid_invoices ? stripeReport.report.paid_invoices.invoices : [];
    const upcomingInvoice = stripeReport && stripeReport.report.upcoming_invoice ? stripeReport.report.upcoming_invoice : undefined;
    const upcomingExpenses = upcomingInvoice && upcomingInvoice.lines ? upcomingInvoice.lines.data.filter((item) => !item.price.active) : [];

    return (
        <div className={classes.container}>
            
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'}>
                                    <IconButton onClick={() => history.goBack()}>
                                        <KeyboardBackspace />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6" component="h2">
                                        Invoices
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9} md={8}>
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        {dueInvoices !== undefined && <Paper style={{ display: dueInvoices.length > 0 ? 'default' : 'none', marginBottom: 28 }}>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' component='h2'>
                                            Open Invoices
                                        </Typography>
                                        <Typography variant='body1' component='h2' style={{ fontSize: 17 }}>
                                            The invoices below are currently open and able to be paid. To take action or view more details, please click on an item below.
                                        </Typography>
                                        <List className={classes.root}>
                                            {dueInvoices !== undefined && dueInvoices.map((invoice) => (
                                            <ListItem key={invoice.hosted_invoice_url} dense>
                                                <ListItemText primary={<span style={{fontSize: 18}}>{invoice.name}</span>} secondary={`$${(invoice.amount_due/100).toFixed(2)}`}/>
                                                <ListItemSecondaryAction>
                                                    {invoice.project_id && <Tooltip title='View Project Details'><IconButton onClick={() => showInvoiceTab(invoice.hosted_invoice_url)}><AssignmentOutlined /></IconButton></Tooltip>}
                                                    <Tooltip title='Pay Now'><IconButton onClick={() => showInvoiceTab(invoice.hosted_invoice_url)}><Payment/></IconButton></Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper> }
                        {upcomingInvoice && <Paper style={{ marginBottom: 28, paddingBottom: 28, paddingTop: 8 }}>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' component='h2'>
                                            Subscription
                                        </Typography>
                                        <Typography variant='body1' component='h2' style={{ fontSize: 17 }}>
                                            The invoice for this subscription will be sent on {new Date(upcomingInvoice.period_end * 1000).toLocaleDateString()}.
                                        </Typography>
                                        <Typography variant='subtitle1' component='h2' style={{ color: Colors.darkGreyText, fontSize: 18, marginTop: 18}}>
                                            REOCCURING SERVICES
                                        </Typography>
                                        {upcomingInvoice !== undefined && upcomingInvoice.lines.data.filter((item) => item.price.active).length === 0 &&
                                        <Typography variant='caption' component='h2'>
                                            <i>No Subscription Items</i>
                                        </Typography>
                                        }
                                        <List className={classes.root}>
                                            {upcomingInvoice !== undefined && upcomingInvoice.lines.data.filter((item) => item.price.active).map((item) => (
                                            <ListItem dense key={item.id}>
                                                <ListItemText primary={<span style={{fontSize: 18 }}>{item.description}</span>} />
                                                <ListItemSecondaryAction>
                                                    <Typography variant='caption' component='h2'>
                                                        { item.amount === 0 ? 'FREE' : `$${(item.amount/100).toFixed(2)}` }
                                                    </Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            ))}
                                            
                                        </List>
                                        {upcomingExpenses.length > 0 &&
                                        <React.Fragment>
                                            <Typography variant='subtitle1' component='h2' style={{ color: Colors.darkGreyText, fontSize: 18, marginTop: 6}}>
                                                ONE-TIME EXPENSES
                                            </Typography>
                                            <List className={classes.root}>
                                                {upcomingExpenses !== undefined && upcomingExpenses.map((item) => (
                                                <ListItem key={item.id}>
                                                    <ListItemText primary={<span style={{fontSize: 18 }}>{item.description}</span>} secondary={item.metadata.evidence && <Link href={item.metadata.evidence}>Download Receipt</Link>} />
                                                    <ListItemSecondaryAction>
                                                        <Typography variant='caption' component='h2'>
                                                            ${(item.amount/100).toFixed(2)}
                                                        </Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body1' component='h2' style={{fontWeight: 'bold', fontSize: 20}}>
                                            Estimated Total: ${(upcomingInvoice.amount_due / 100).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper> }
                        <Paper>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' component='h2'>
                                            Paid Invoices
                                        </Typography>
                                        <List className={classes.root}>
                                            {invoices !== undefined && invoices.map((invoice) => (
                                            <ListItem dense key={invoice.id}>
                                                <ListItemText primary={<span style={{fontSize: 18}}>{invoice.name}</span>} secondary={`$${(invoice.amount_due/100).toFixed(2)}`} />
                                                <ListItemSecondaryAction>
                                                    {invoice.project_id && <Tooltip title='View Project Details'><IconButton onClick={() => showInvoiceTab(invoice.hosted_invoice_url)}><AssignmentOutlined /></IconButton></Tooltip>}
                                                    <Tooltip title='View Receipt'><IconButton onClick={() => showInvoiceTab(invoice.hosted_invoice_url)}><Receipt/></IconButton></Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            ))}
                                            {(invoices === undefined || invoices.length === 0) &&
                                            <Typography variant='subtitle1' component='h2'>
                                                No Invoices Reported
                                            </Typography>
                                            }
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Typography variant='caption' component='h2' style={{ textAlign: 'center', fontSize: 14, marginTop: 16}}>
                            If you are looking for an older invoice or do not see the one you are searching for, please contact us.
                        </Typography>
                    </div>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ViewSiteInvoicesComponent);