import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Paper, Grid, Button, TextField, CircularProgress, MenuItem} from '@material-ui/core';
import Colors from '../../../constants/Colors';
import background from '../../../assets/backgrounds/brick-background.jpeg';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { isValidEmail } from '../../../utilities/Validation';
import { registerNewUser } from '../../../clients/bettercallkyle/BckApi';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import CreateAccountModal from '../modals/CreateAccountModal';
import { registerProfile } from '../../../clients/profile/ProfileClient';
import { useRef } from 'react';
import { COMPANY_TYPES } from '../../../constants/Common';

const styles = theme => ({
 root: {
   width: '100%',
 },
 paperStyle: {
    width: '100%',
    position: 'relative',
    marginBottom: 50
},
sectionText: {
  fontSize:24,
  color: Colors.defaultDarkText,
  fontFamily:'Nunito Sans, sans-serif',
  textAlign:'center',
  fontWeight:'bold',
  marginTop:15,
  [theme.breakpoints.up('sm')]: {
      textAlign:'left',
      fontSize:24
  },
},
 titleText: {
    fontSize:44,
    color: Colors.primaryColor,
    fontFamily:'Rock Salt, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    fontStyle:'italic',
    marginTop:15,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:56
    },
  },
  subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:18,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.primaryColor,
    fontWeight:'bold',
    color: Colors.lightColor,
    height: 50
  },
  imageStyle: {
    opacity: 0.6,
    height: '100%',
    width:'auto',
},
textLink: {
  textDecoration: 'underline',
  color: Colors.primaryColor,
  cursor: 'pointer'
},
textButton: {
  marginBottom: 50,
  textTransform:'none',
  fontSize:16,
  fontFamily:'Nunito Sans, sans-serif',
  fontWeight:'bold',
  color: Colors.darkColor,
  height: 'auto'
},
mobile: {
  [theme.breakpoints.up('md')]: {
      display: 'none'
  },
  backgroundColor: '#fff'
},
desktop: {
  [theme.breakpoints.down('sm')]: {
      display: 'none'
  },
  marginTop: 5
},
});

const NewCompanyForm = ( { classes, history }) => {

  const userRef = useRef();
  const siteRef = useRef();

  const [firstName, setFirstName ] = useState();
  const [lastName, setLastName ] = useState();
  const [email, setEmail ] = useState();
  const [companyName, setCompanyName ] = useState();
  const [companyStructure, setCompanyStructure ] = useState();
  const [companyAddressLine1, setCompanyAddressLine1 ] = useState();
  const [companyAddressLine2, setCompanyAddressLine2 ] = useState();
  const [companyCity, setCompanyCity] = useState();
  const [companyState, setCompanyState] = useState();
  const [companyZipCode, setCompanyZipCode] = useState();
  const [companyEin, setCompanyEin] = useState();

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal ] = useState();

  const isValidForm = () => {
    const foundErrors = [];
    if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
    if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
    if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
    if (!companyName || isEmpty(companyName)) foundErrors.push('companyName');
    if (!companyStructure || isEmpty(companyStructure)) foundErrors.push('companyStructure');
    if (!companyAddressLine1 || isEmpty(companyAddressLine1)) foundErrors.push('companyAddressLine1')
    if (!companyCity || isEmpty(companyCity)) foundErrors.push('companyCity')
    if (!companyState || isEmpty(companyState)) foundErrors.push('companyState')
    if (!companyZipCode || isEmpty(companyZipCode) || isNaN(companyZipCode) || Number(companyZipCode) < 10000 || Number(companyZipCode) > 99999 ) foundErrors.push('companyZipCode')
    
    setErrors(foundErrors)
    return foundErrors.length === 0
  }

  const register = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);
    
    try {
      const user = {
        user_first_name: firstName,
        user_last_name: lastName,
        user_email: email
      }

      const site = {
        site_name: companyName,
        site_structure: companyStructure,
        site_ein: companyEin,
        site_address: {
          address_line_1: companyAddressLine1,
          address_line_2: companyAddressLine2,
          city: companyCity,
          state: companyState,
          zipcode: companyZipCode
        }
      }
      // register new user info with DB (NOT cognito - will only create account if user wants in next step)
      const response = await registerNewUser(user, site);
      userRef.current = response.user;
      siteRef.current = response.site;

      // push to next screen to create account
      setShowCreateAccountModal(true)

    } catch(ex) {
      const resp = await (new Promise(resolve => resolve(ex)));
      if (resp && resp.code === 'user-already-exists') {
        setErrors(['email'])
        NotificationBarActions.showNotificationBar('error', `The email you provided has already been registered.`)
      } else {
        NotificationBarActions.showNotificationBar('error', `The registration was unable to be completed. Please try again.`)
      }
    } finally {
      setIsLoading(false)
    }

  }

  const handleCreateAccount = async (password) => {
    try {
      setIsLoading(true)
      // const resp = await registerProfile('kylewegener@gmail.com', password, 'Kyle', 'Wegener', 'f4a53320-4a5b-11ed-b1b6-1f47abd8917c')
      await registerProfile(userRef.current.user_login_id, password, userRef.current.user_personal.first_name, userRef.current.user_personal.last_name, userRef.current.user_id)
      setShowCreateAccountModal(false);
      history.push('/verify', { user: { email: userRef.current.user_login_id, password } });
      NotificationBarActions.showNotificationBar('success', `Woohoo! Your account has been created. Now, let's verify your account.`)
    } catch(ex) {
      NotificationBarActions.showNotificationBar('error', `Oh no! Something wen't wrong when creating your account. Please try again.`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleLeave = () => {
    setShowCreateAccountModal(false);
    NotificationBarActions.showNotificationBar('success', `Woohoo! You're registration was a success! Please make sure to create your account as soon as possible.`)
    history.push('/register');
  }

  const handleShowTermsAndConditions = () => {
    const win = window.open('/terms', '_blank');
    win.focus();
  }
  
  const handleShowPrivacyPolicy = () => {
    const win = window.open('/privacy', '_blank');
    win.focus();
  }

  const renderForm = () => (
    <div style={{ padding: 16}}>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <div className={classes.titleText}>Welcome!</div>
          <div className={classes.subtitleText}>We are excited to be working with you and your organization, to help with your technology needs! Please review, and provide any necessary information below. Once finished, we will reach out with the next steps of the process!</div>
          <div className={classes.subtitleText} style={{ fontSize: 16, color: '#f00'}}>* Required Field</div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <div className={classes.sectionText}>Personal Information</div>
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              error={errors.includes('firstName')}
              fullWidth
              value={firstName}
              className={classes.textField}
              margin="normal"
              type="text"
              label="*First Name"
              variant="outlined"
              onChange={(e) => setFirstName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
              error={errors.includes('lastName')}
              fullWidth
              value={lastName}
              className={classes.textField}
              margin="normal"
              type="text"
              label="*Last Name"
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
              error={errors.includes('email')}
              helperText={'This is the email that you will receive account information and invoices too. Please do not use your business email if it has not been set up yet.'}
              fullWidth
              value={email}
              className={classes.textField}
              margin="normal"
              type="text"
              label="*E-Mail"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <div className={classes.sectionText}>Company/Organization Information</div>
        </Grid>
        <Grid item xs={12}>
            <TextField
              error={errors.includes('companyName')}
              fullWidth
              value={companyName}
              className={classes.textField}
              margin="normal"
              type="text"
              label="*Company/Organization Name"
              variant="outlined"
              onChange={(e) => setCompanyName(e.target.value)}
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            error={errors.includes('companyStructure')}
            fullWidth
            className={classes.textField}
            label="*Business Structure"
            variant="outlined"
            onChange={(e) => setCompanyStructure(e.target.value)}
          >
            {COMPANY_TYPES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.includes('companyEin')}
            fullWidth
            value={companyEin}
            className={classes.textField}
            margin="normal"
            type="text"
            label="Employer ID Number (EIN)"
            variant="outlined"
            onChange={(e) => setCompanyEin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.sectionText}>Legal Address</div>
          <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Please provide your legal business address. If you do not yet have one, please provide your current address. This information is securely saved, and will never be shared externally.</div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.includes('companyAddressLine1')}
            fullWidth
            value={companyAddressLine1}
            className={classes.textField}
            margin="normal"
            type="text"
            label="*Street Address"
            variant="outlined"
            onChange={(e) => setCompanyAddressLine1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors.includes('companyAddressLine2')}
            fullWidth
            value={companyAddressLine2}
            className={classes.textField}
            margin="normal"
            type="text"
            label="Unit/Apt/Suite"
            variant="outlined"
            onChange={(e) => setCompanyAddressLine2(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            error={errors.includes('companyCity')}
            fullWidth
            value={companyCity}
            className={classes.textField}
            margin="normal"
            type="text"
            label="*City"
            variant="outlined"
            onChange={(e) => setCompanyCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            error={errors.includes('companyState')}
            fullWidth
            value={companyState}
            className={classes.textField}
            margin="normal"
            type="text"
            label="*State"
            variant="outlined"
            onChange={(e) => setCompanyState(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            error={errors.includes('companyZipCode')}
            fullWidth
            value={companyZipCode}
            className={classes.textField}
            margin="normal"
            type="text"
            label="*Zip Code"
            variant="outlined"
            onChange={(e) => setCompanyZipCode(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container style={{marginTop: 30}} justify='center' >
        <Grid item xs={12}>
          <div className={classes.subtitleText}>By clicking 'I Agree & Continue' below you are accepting the <span
          className={classes.textLink} onClick={handleShowTermsAndConditions}
          >Terms and Conditions</span> and the <span
          className={classes.textLink} onClick={handleShowPrivacyPolicy}
          >Privacy Policy</span> of Better Call Kyle, LLC.</div>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item xs={'auto'}>
          <Button variant="contained" className={classes.button} onClick={register} disabled={isLoading}>
            { isLoading ?
              <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
              : <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>I Agree & Continue</span>
            }
          </Button>
        </Grid>
      </Grid>
    </div>
  )

   return (
    <div>
      <div id="background-img" className={classes.desktop} style={{ backgroundColor: Colors.defaultDarkText, position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%' }}>
          <img src={background} alt={"logo"} className={classes.imageStyle} />
      </div>
      <div className={classes.desktop}>
        <Grid container justify='center' style={{ minHeight: '100vh' }}>
          <Grid item xs={12} sm={11} md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Paper style={{ width: '100%', position: 'relative', marginTop: '100px', marginBottom: '50px' }} variant='outlined'>
                {renderForm()}
              </Paper>
            </Grid>
        </Grid>
      </div>
      <div className={classes.mobile}>
        <Grid container justify='center' style={{ minHeight: '100vh', backgroundColor: '#fff', paddingTop: '70px', paddingBottom: '50px' }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  {renderForm()}
              </Grid>
          </Grid>
      </div>
      <CreateAccountModal open={showCreateAccountModal} onLeave={handleLeave} onCreateAccount={handleCreateAccount} isLoading={isLoading} />
    </div>
   )
}

NewCompanyForm.propTypes = {
 classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewCompanyForm);