import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, KeyboardBackspace } from "@material-ui/icons";
import { isEmpty } from "lodash";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { createNewUser, getUser, updateUser } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { isValidEmail } from "../../../utilities/Validation";
import { getUserId } from "../../../actions/profile/UserActions";
import { getClockifyClients } from "../../../clients/clockify/ClockifyApi";

const CLOCKIFY_GENERATE_CLIENT_STRING = 'GENERATE_CLOCKIFY_CLIENT'

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    textField: {
        marginTop: 0
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
      }
})

const AdminEditUserComponent = ({ classes, history, match }) => {

    const selectableClockifyClients = useRef([])

    const [user, setUser] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [clockifyClient, setClockifyClient] = useState('')
    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        loadSession()
    }, [])

    const loadSession = async () => {
        try {
            const userId = match.params.user_id
            setIsLoading(true)
            const user = await getUser(userId);
            if (!user) { throw 'No user found.'; }
            const clockifyClients = await getClockifyClients()
            selectableClockifyClients.current = clockifyClients;
            setFirstName(user.user_personal.first_name)
            setLastName(user.user_personal.last_name)
            setEmail(user.user_login_id)
            if (user.user_external_connections.clockify) setClockifyClient(user.user_external_connections.clockify.clockify_client_id)
            setUser(user)
        } catch (err) {
            NotificationBarActions.showNotificationBar('error', 'Could not load site to edit at this time. Please try again later.');
            console.error(err)
            history.goBack()
        } finally {
            setIsLoading(false)
        }
    }

    const isValidForm = () => {
        const foundErrors = [];
        if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
        if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
        if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
        
        setErrors(foundErrors)
        return foundErrors.length === 0
      }

    const preparePayload = () => {
        const masks = []
        const maskProps = {}
        if (firstName !== user.user_personal.first_name) { masks.push('first_name'); maskProps.first_name = firstName }
        if (lastName !== user.user_personal.last_name) { masks.push('last_name'); maskProps.last_name = lastName }
        if (!isEmpty(clockifyClient)
            && (
                !user.user_external_connections.clockify
                || !user.user_external_connections.clockify.clockify_client_id
                || user.user_external_connections.clockify.clockify_client_id !== clockifyClient
                )
         ) { masks.push('clockify'); maskProps.clockify = { clockify_client_id: clockifyClient } }

        return { masks, maskProps }
    }
    const handleGenerateNewClient = async () => {
        try {
            setIsLoading(true)
            const updatedUser = await updateUser(user.user_id, getUserId(), ['clockify'], { clockify: { clockify_client_id: CLOCKIFY_GENERATE_CLIENT_STRING }})
            selectableClockifyClients.current = await getClockifyClients()
            setClockifyClient(updatedUser.user_external_connections.clockify.clockify_client_id)
            NotificationBarActions.showNotificationBar('success', 'New clockify client has been created and attached to this user.');
        } catch(err) {
            NotificationBarActions.showNotificationBar('error', 'Could not generate new clockify client at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateUser = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);
            const payload = preparePayload();
            if (payload.masks.length > 0) {
                await updateUser(user.user_id, getUserId(), payload.masks, payload.maskProps)
                NotificationBarActions.showNotificationBar('success', 'User has been created');
            }
            history.goBack();
        } catch(err) {
            const resp = await err;
            if (resp.code === 'user-exists') {
                setErrors(['email'])
                NotificationBarActions.showNotificationBar('error', 'A user with this email already exists.');
                return;
            }
            console.error(resp);
            NotificationBarActions.showNotificationBar('error', 'Could not create user at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Update User
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Required Information
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5 }}>
                                        Provide the necessary infomation to create the new account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                    autoFocus
                                    error={errors.includes('firstName')}
                                    fullWidth
                                    value={firstName}
                                    disabled={isLoading}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*First Name"
                                    variant="outlined"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                    error={errors.includes('lastName')}
                                    fullWidth
                                    disabled={isLoading}
                                    value={lastName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Last Name"
                                    variant="outlined"
                                    onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    disabled
                                    error={errors.includes('email')}
                                    fullWidth
                                    value={email}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*E-Mail"
                                    variant="outlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    helperText={'Email cannot be updated since it is linked to the users login id'}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Additional Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        error={errors.includes('clockifyClient')}
                                        fullWidth
                                        className={classes.textField}
                                        label="*Clockify Client"
                                        variant="outlined"
                                        value={clockifyClient}
                                        disabled={isLoading}
                                        onChange={(e) => setClockifyClient(e.target.value)}
                                    >
                                        {selectableClockifyClients.current.map((client) => (
                                        <MenuItem key={client.id} value={client.id}>
                                            {client.name}
                                        </MenuItem>
                                        ))}
                                        <MenuItem key={'generate-client'} value="" onClick={handleGenerateNewClient}>
                                            <Add/><i>Generate New Client</i>
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                        <Button 
                            className={classes.buttonContainedStyle}
                            variant='contained'
                            disabled={isLoading}
                            onClick={handleUpdateUser} color="primary">
                            { isLoading ?
                            <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                            : <span>Update User</span>
                            }
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminEditUserComponent);