import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { observer } from "mobx-react";
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:20,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        [theme.breakpoints.down('sm')]: {
            fontSize:18,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

class ConfirmationModal extends React.Component {

    constructor(props) {
        super(props);
    }

    handleClose=()=>{ this.props.onClose() }

    render() {
        const { classes, open } = this.props;
        return (
            <Dialog
            aria-labelledby="add-item-modal"
            aria-describedby="add an item to your inventory"
            open={open}
            onClose={this.handleClose}
            >
                <DialogTitle>
                    {this.props.confirmationTitle ? this.props.confirmationTitle : 'Are you sure?'}
                </DialogTitle>
                <DialogContent>
                            <div className={classes.headerSubTextStyle}>{this.props.confirmationDetails}</div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={this.props.onOk} color="primary">
                        {this.props.okText ? this.props.okText : 'Yes'}
                    </Button>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={this.props.onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
);
    }
}

export default withStyles(styles)(observer(ConfirmationModal));