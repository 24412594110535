import React, { useEffect, useRef, useState } from 'react';

import { Button, CircularProgress, Grid, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import { setUserFromCognito } from '../../../actions/profile/UserActions';
import background from '../../../assets/backgrounds/brick-background.jpeg';
import { logProfileIn, resendVerificationCode, verifyCode } from '../../../clients/profile/ProfileClient';
import Colors from '../../../constants/Colors';
import { SESSION_REDIRECT_KEY, getSessionProperty } from '../../../utilities/SessionUtilities';

const styles = theme => ({
 root: {
   width: '100%',
 },
 paper: {
  width: '100%',
  position: 'relative',
  marginTop: 130,
  marginBottom: 50
 },
 titleText: {
    fontSize:26,
    color: Colors.primaryColor,
    fontFamily:'Rock Salt, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    fontStyle:'italic',
    marginTop:25,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:30
    },
  },
  subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
  },
  errorText: {
    fontSize:18,
    color: Colors.errorOutline,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 20,
    marginBottom:5,
    [theme.breakpoints.up('sm')]: {
        textAlign:'center',
        fontSize:20
    },
  },
  textField: {
    marginTop: 5,
    marginBottom: 5
  },
  button: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.primaryColor,
    fontWeight:'bold',
    color: Colors.lightColor,
    height: 50
  },
  outlinedButton: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    fontWeight:'bold',
    color: Colors.defaultDarkText,
    height: 50
  },
  imageStyle: {
    opacity: 0.6,
    height: '100%',
    width:'auto',
},
});

const VerificationForm = ({ classes, location, history }) => {

  const [hasResentCode, setHasRecentCode] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const userRef = useRef(location.state ? location.state.user : undefined);

  useEffect(() => {
    if (!userRef
      || !userRef.current
      || userRef.current.email === undefined
      || userRef.current.password === undefined) history.push('/register');
  }, []);

  const handleResendCode = async event => {
    try {
      if (userRef.current.email === undefined) history.push('/login');
      await resendVerificationCode(userRef.current.email);
      setHasRecentCode(true);
      NotificationBarActions.showNotificationBar('info', 'Verification code sent.');
    } catch(err) {
      console.error('resend code error', err)
      NotificationBarActions.showNotificationBar('error', 'An issue happened when sending the verification code.');
    }
  }
  
  const handleVerify = async () => {
    try {
      const email = userRef.current.email;
      const password = userRef.current.password;
      if (email === undefined || password === undefined) {
        history.push('/login');
        return;
      } else if(verificationCode.trim() === '') {
        setErrorMessage('Please enter a code.');
        return;
      }
      setIsLoading(true);
      await verifyCode(email, verificationCode)
      .then(() => handleSignIn(email, password));
    } catch(err) {
      if(err.code === 'CodeMismatchException') {
        setErrorMessage('Oh no! That code was not correct.');
      } else {
        console.error('err', err);
        setErrorMessage('Oh no! Something went wrong. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

const handleSignIn = async (email, password) => {
    try {
      let username = email.toLowerCase();
      let user = await logProfileIn(username, password)
      setUserFromCognito(user);
      const redirect = getSessionProperty(SESSION_REDIRECT_KEY, true)
      if (redirect) history.push(redirect);
      else history.push('/dashboard')
    } catch (e) {
      console.error('LOGIN ERROR', e)
      history.push('/login');
    }
  }
  
  return (
    <div>
      <div id="background-img" style={{ backgroundColor: Colors.defaultDarkText, position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%' }}>
          <img src={background} alt={"logo"} className={classes.imageStyle} />
      </div>
      <Grid container justify="center">
        <Grid item xs={11} sm={9} md={8} lg={6}>
          <Paper className={ `${classes.paper} animated fadeIn` }>
            <Grid container spacing={2} justify='center'>
              <Grid item xs={11}>
                <div className={classes.titleText} >We Just Sent You A Message!</div>
                <div className={classes.subtitleText}>This is the last step. Please check your email to retrieve your activation code and insert it below.</div>
                { errorMessage &&
                  <div className={`${classes.errorText} animated shake`}>{errorMessage}</div>
                }
              </Grid>
              <Grid item xs={11} sm={10} md={8} style={{paddingTop: 50, paddingBottom: 60}} className="animated fadeIn">
                  <TextField
                    autoFocus
                    fullWidth
                    value={verificationCode}
                    className={classes.textField}
                    onKeyPress={(e) => {if (e.key === 'Enter') { handleVerify();}}}
                    margin="normal"
                    type="text"
                    label="Verification Code"
                    variant="outlined"
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
              </Grid>
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={'auto'}>
                <Button className={classes.outlinedButton} onClick={handleResendCode} disabled={isLoading || hasResentCode }>
                  <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>Resend Code</span>
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant="contained" className={classes.button} onClick={handleVerify} disabled={isLoading}>
                  { isLoading ?
                    <CircularProgress style={{ color: '#fcfcfc', marginLeft: 10, marginRight: 10 }} thickness={5} />
                    : <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>Verify</span>
                  }
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(VerificationForm);