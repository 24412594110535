import React from "react";
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getUserObject } from "../../../actions/profile/UserActions";
import AdminSitesAndUsersDetailsCard from "./cards/AdminSitesAndUsersDetailsCard";
import AdminWelcomeCard from "./cards/AdminWelcomeCard";
import SiteUserCountCard from "./cards/SiteUserCountCard";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    }
})

const AdminComponent = ({ classes, sites, users, history }) => {


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='center' spacing={2}>
                        <AdminWelcomeCard
                            primaryText={`Welcome${getUserObject() !== undefined ? ` ${getUserObject().user_personal.first_name}!` : '!'}`}
                            secondaryText={'Administration Panel'}
                            history={history} />
                        <SiteUserCountCard sites={sites} users={users} />
                        <AdminSitesAndUsersDetailsCard sites={sites} users={users} history={history} />
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminComponent);