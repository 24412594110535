import React, { useRef, useState } from 'react';

import { Button, CircularProgress, Grid, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import background from '../../../assets/backgrounds/brick-background.jpeg';
import { beginForgottenPasswordProcess, resendVerificationCode, resetForgottenPassword } from '../../../clients/profile/ProfileClient';
import Colors from '../../../constants/Colors';
import { isValidEmail } from '../../../utilities/Validation';

const styles = theme => ({
 root: {
   width: '100%',
 },
 paper: {
  width: '100%',
  position: 'relative',
  marginTop: 130,
  marginBottom: 50
 },
 titleText: {
    fontSize:26,
    color: Colors.primaryColor,
    fontFamily:'Rock Salt, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    fontStyle:'italic',
    marginTop:25,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:30
    },
  },
  subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
  },
  errorText: {
    fontSize:18,
    color: Colors.errorOutline,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 20,
    marginBottom:5,
    [theme.breakpoints.up('sm')]: {
        textAlign:'center',
        fontSize:20
    },
  },
  textField: {
    marginTop: 5,
    marginBottom: 5
  },
  button: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.primaryColor,
    fontWeight:'bold',
    color: Colors.lightColor,
    height: 50
  },
  outlinedButton: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    fontWeight:'bold',
    color: Colors.defaultDarkText,
    height: 50
  },
  imageStyle: {
    opacity: 0.6,
    height: '100%',
    width:'auto',
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
        display: 'none'
    },
    backgroundColor: '#fff'
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
    marginTop: 5
  },
});

const ForgotPasswordForm = ({ classes, location, history }) => {

  const [hasResentCode, setHasRecentCode] = useState();
  const [hasSentCode, setHasSentCode] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errors, setErrors] = useState([]);

  const userRef = useRef(location.state ? location.state.user : undefined);

  const handleResendCode = async event => {
    try {
      if (userRef.current.email === undefined) history.push('/login');
      await resendVerificationCode(userRef.current.email);
      setHasRecentCode(true);
      NotificationBarActions.showNotificationBar('info', 'Verification code sent.');
    } catch(err) {
      console.error('resend code error', err)
      NotificationBarActions.showNotificationBar('error', 'An issue happened when sending the verification code.');
    }
  }

const isFormValid = () => isValidEmail(email)

const isPasswordFormValid = () => {
  const foundErrors = [];
  const capitalVal = (/[A-Z]/.test(newPassword));
  const numberVal = (/\d/.test(newPassword));
  if (!code || code.length !== 6) foundErrors.push('verificationCode');
  if (!newPassword || newPassword.length < 8 || !capitalVal || !numberVal) foundErrors.push('newPassword');
  if (!foundErrors.includes('password') && newPassword !== confirmNewPassword) foundErrors.push('confirmNewPassword');
  setErrors(foundErrors);
  return foundErrors.length === 0; 
}

const handleSubmitEmailToResetPassword = async () => {
    try {
      if (!isFormValid()) {
        setErrorMessage('Please enter a valid email')
        return;
      }
      setIsLoading(true)
      let username = email.toLowerCase();
      let response = await beginForgottenPasswordProcess(username);
      setHasSentCode(true);
      setErrorMessage(undefined);
    } catch (e) {
      console.error('LOGIN ERROR', e)
      setErrorMessage(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangePassword = async () => {
    try {
      if (isPasswordFormValid().length > 0) {
        setErrorMessage('Please enter a valid email')
        return;
      }
      setIsLoading(true)
      let username = email.toLowerCase();
      let response = await resetForgottenPassword(username, code, newPassword);
      setHasSentCode(true);
      setErrorMessage(undefined);
      NotificationBarActions.showNotificationBar('success', 'Successfully updated your password! Please try to login now.')
      history.push('/login')
    } catch (e) {
      console.error('LOGIN ERROR', e)
      setErrorMessage('Uh oh! Something went wrong, please double check your verification code.')
    } finally {
      setIsLoading(false)
    }
  }

  const renderForm = () => (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={11}>
          <div className={classes.titleText} >Find Your Account</div>
          <div className={classes.subtitleText}>Enter the email associated with your account.</div>
          { errorMessage &&
            <div className={`${classes.errorText} animated shake`}>{errorMessage}</div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 16, paddingBottom: 60}} className="animated fadeIn">
            <TextField
              autoFocus
              key='email'
              fullWidth
              value={email}
              className={classes.textField}
              onKeyPress={(e) => {if (e.key === 'Enter') { handleSubmitEmailToResetPassword();}}}
              margin="normal"
              type="text"
              label="Email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={'auto'}>
        </Grid>
        <Grid item xs={'auto'}>
          <Button variant="contained" className={classes.button} onClick={handleSubmitEmailToResetPassword} disabled={isLoading}>
            { isLoading ?
              <CircularProgress style={{ color: '#fcfcfc', marginLeft: 10, marginRight: 10 }} thickness={5} />
              : <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>Recover Account</span>
            }
          </Button>
        </Grid>
      </Grid>
    </>
  )

  const renderResetPasswordForm = () => (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={11}>
          <div className={classes.titleText} >Find Your Account</div>
          <div className={classes.subtitleText}>We emailed you a code. Provide it below along with the new password for your account.</div>
          { errorMessage &&
            <div className={`${classes.errorText} animated shake`}>{errorMessage}</div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 16, paddingBottom: 0}} className="animated fadeIn">
            <TextField
              key='code'
              fullWidth
              value={code}
              className={classes.textField}
              error={errors.includes('verificationCode')}
              helperText={errors.includes('verificationCode') && 'This is not valid. The code sent should have been 6 digits.'}
              margin="normal"
              type="text"
              label="Verification Code"
              variant="outlined"
              onChange={(e) => setCode(e.target.value)}
            />
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 16, paddingBottom: 0}} className="animated fadeIn">
            <TextField
              fullWidth
              error={errors.includes('newPassword')}
              helperText={errors.includes('newPassword') && 'A password must be 8 characters containing a capital letter and a number.'}
              value={newPassword}
              className={classes.textField}
              margin="normal"
              type="password"
              label="New Password"
              variant="outlined"
              onChange={(e) => setNewPassword(e.target.value)}
            />
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{paddingTop: 0, paddingBottom: 60}} className="animated fadeIn">
            <TextField
              fullWidth
              error={errors.includes('confirmNewPassword')}
              helperText={errors.includes('confirmNewPassword') && 'The passwords did not match.'}
              value={confirmNewPassword}
              className={classes.textField}
              margin="normal"
              type="password"
              label="Confirm New Password"
              variant="outlined"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={'auto'}>
          <Button className={classes.outlinedButton} onClick={handleResendCode} disabled={isLoading || hasResentCode }>
            <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>Resend Code</span>
          </Button>
        </Grid>
        <Grid item xs={'auto'}>
          <Button variant="contained" className={classes.button} onClick={handleChangePassword} disabled={isLoading}>
            { isLoading ?
              <CircularProgress style={{ color: '#fcfcfc', marginLeft: 10, marginRight: 10 }} thickness={5} />
              : <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>Reset Password</span>
            }
          </Button>
        </Grid>
      </Grid>
    </>
  )

  return (
    <div>
      <div id="background-img" className={classes.desktop} style={{ backgroundColor: Colors.defaultDarkText, position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%' }}>
          <img src={background} alt={"logo"} className={classes.imageStyle} />
      </div>
      <div className={classes.desktop}>
        <Grid container justify='center' style={{ minHeight: '100vh' }}>
          <Grid item xs={12} sm={11} md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Paper style={{ width: '100%', position: 'relative', marginTop: '100px', marginBottom: '50px' }} variant='outlined'>
                {hasSentCode ? renderResetPasswordForm() : renderForm()}
              </Paper>
            </Grid>
        </Grid>
      </div>
      <div className={classes.mobile}>
        <Grid container justify='center' style={{ minHeight: '100vh', backgroundColor: '#fff', paddingTop: '70px', paddingBottom: '50px' }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  {hasSentCode ? renderResetPasswordForm() : renderForm()}
              </Grid>
          </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(ForgotPasswordForm);