import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getUserId } from "../../../../actions/profile/UserActions";
import { getUserPaymentReport } from "../../../../clients/bettercallkyle/BckApi";
import Colors from "../../../../constants/Colors";
import ViewInvoiceModal from "../../modals/ViewInvoiceModal";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const CurrentPaymentsCard = ({classes, history}) => {

  const paymentData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [ showInvoices, setShowInvoices ] = useState(false);
  
  useEffect(() => {
    loadData();
  }, [])

  const handleCloseModal = () => {
    setShowInvoices(false);
  }

  const loadData = async () => {
    try {
      const user_id = getUserId();
      if (user_id === undefined) throw 'No User ID';
      const payment = await getUserPaymentReport(user_id)
      paymentData.current = payment;
    } catch (ex) {
      setError('Could not load payment data!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const renderContent = () => isLoading ? (
    <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
  ) : (
    <React.Fragment>
      {error ?
        <Typography color='error' variant="h5" component="h2">
          {error}
        </Typography> : 
        <Typography variant="h6" component="h2">
          ${(paymentData.current && paymentData.current.report) ? (paymentData.current.report.open_invoices.invoice_amount_due/100).toFixed(2) : '0.00'}
        </Typography>}
      <Typography className={classes.pos} color="textSecondary">
        {(paymentData.current && paymentData.current.report) && paymentData.current.report.open_invoices.invoice_amount_due > 0 ? 'is currently due.' : "You're all caught up!"}
      </Typography>
    </React.Fragment> 
  );

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Invoices
          </Typography>
          {renderContent()}
        </CardContent>
        <CardActions>
          {paymentData.current && paymentData.current.report && <Button size="small" onClick={() => setShowInvoices(true)}>Pay Now</Button>}
        </CardActions>
      </Card>
      <ViewInvoiceModal open={showInvoices} onClose={handleCloseModal} invoices={paymentData.current && paymentData.current.report ? paymentData.current.report.open_invoices.invoices : []} />
    </Grid>
  )
}

export default withStyles(styles)(CurrentPaymentsCard);