import { CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite, getSiteHoursReport } from '../../../actions/internal/SiteActions';
import Colors from "../../../constants/Colors";
import Styles from "../../../constants/Styles";
import { getMonthName } from "../../../utilities/DateUtilities";

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const ViewSiteHoursComponent = ({ classes, history, location, match }) => {

    const reportCacheRef = useRef({});

    const [ siteData, setSiteData ] = useState();
    const [ hoursReport, setHoursReport ] = useState();
    const [ isLoading, setIsLoading ] = useState();
    const [ isLoadingDate, setIsLoadingDate ] = useState(false);
    const [ currentDate, setCurrentDate ] = useState(new Date())

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        const stripe_invoice_report = state && state.stripe_invoice_report ? state.stripe_invoice_report : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined || stripe_invoice_report === undefined) loadData(site_id);
        else {
          setSiteData(site);
        }
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
            const site = await getSite(site_id);
            if (site === undefined) {
                history.push('/dashboard');
                return;
            }
            setSiteData(site);
            const date = new Date();
            const startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
            
            const report = await getSiteHoursReport(site.site_id, startDate.getTime(), endDate.getTime());
            reportCacheRef.current[`${date.getMonth()}_${date.getFullYear()}`] = report;
            setHoursReport(report)

        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    const getMonthHours = async (date) => {
        if (reportCacheRef.current[`${date.getMonth()}_${date.getFullYear()}`] !== undefined) {
            setHoursReport(reportCacheRef.current[`${date.getMonth()}_${date.getFullYear()}`])
            return;
        } 
        try {
            setIsLoadingDate(true);
            const startDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
            
            const report = await getSiteHoursReport(siteData.site_id, startDate.getTime(), endDate.getTime());
            reportCacheRef.current[`${date.getMonth()}_${date.getFullYear()}`] = report;
            setHoursReport(report)
        } catch(err) {
            console.error(err);
            NotificationBarActions.showNotificationBar('error', 'An error has occurred while fetching hours. Please try again later.')
        } finally {
            setIsLoadingDate(false)
        }
        
    }

    const decrementMonth = () => {
        if (currentDate.getMonth() === 1) {
            currentDate.setMonth(12);
            currentDate.setFullYear(currentDate.getFullYear() - 1);
        } else {
            currentDate.setMonth(currentDate.getMonth() - 1);
        }
        const date = new Date(currentDate);
        setCurrentDate(date);
        getMonthHours(date);
    }

    const incrementMonth = () => {
        if (currentDate.getMonth() === 12) {
            currentDate.setMonth(1);
            currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else {
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        const date = new Date(currentDate);
        setCurrentDate(date);
        getMonthHours(date);
    }

    const renderTimeStamp = (entry) => {
        var options = { hour: 'numeric', minute: 'numeric' };
        const startDate = new Date(entry.timeInterval.start)
        const endDate = new Date(entry.timeInterval.end)
        return `${startDate.toLocaleTimeString('en-US', options)} - ${endDate.toLocaleTimeString('en-US', options)} on ${startDate.toLocaleDateString()}`
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details } = siteData ? siteData : {};
    const hours = hoursReport !== undefined && hoursReport.report.current_hours ? hoursReport.report.current_hours : undefined;
    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='flex-start' spacing={1}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Consulting Hours
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        <Grid container justify='space-between' spacing={2} direction='row'>
                            <Grid item xs={'auto'}>
                                <Typography variant='h6' component='h2' style={{ marginLeft: 5 }}>
                                    {getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}
                                </Typography>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <IconButton onClick={decrementMonth}>
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton onClick={incrementMonth} disabled={currentDate.getTime() >= Date.now()}>
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" >
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    {isLoadingDate ?
                                        <Grid item xs={12} style={{ marginTop: 18, marginBottom: 18 }}>
                                            <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                        </Grid>
                                    :
                                        <Grid item xs={12}>
                                            <List className={classes.root}>
                                                {hours && hours.time_entries && hours.time_entries.map((entry) => (
                                                <ListItem key={entry.id} dense>
                                                    <ListItemText primary={entry.description} secondary={renderTimeStamp(entry)} />
                                                    <Typography variant='caption' component='h2'>
                                                        {(entry.timeInterval.duration / 1000 / 60 / 60).toFixed(1)} Hrs
                                                    </Typography>
                                                </ListItem>
                                                ))}
                                                {(!hours || hours.time_entries.length === 0) ?
                                                <Typography variant='subtitle1' component='h2' style={{ marginTop: 18, marginBottom: 18 }}>
                                                    No Hours Reported
                                                </Typography>
                                                :
                                                <Typography variant='body1' component='h2' style={{ marginLeft: 5, marginTop: 18 }}>
                                                    Total: {hours.total_hours.toFixed(1)} Hours
                                                </Typography>
                                                }
                                            </List>
                                            
                                        </Grid>
                                    }
                                    
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ViewSiteHoursComponent);