import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, Tooltip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, Delete, Edit, ExpandMore, HelpOutline, Payment, Send, Update } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite } from "../../../actions/internal/SiteActions";
import { getUserId, isAdmin } from "../../../actions/profile/UserActions";
import { deleteJob, getJob, sendApprovalRequest, updateJobStatus } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { FINAL_JOB_STATUSES, JOB_STATUS, JOB_STATUS_MAP } from "../../../constants/Statuses";
import Styles from "../../../constants/Styles";
import ConfirmationModal from "../modals/ConfirmationModal";
import RejectJobModal from "../modals/RejectJobModal";
import AcceptProjectModal from '../modals/AcceptProjectModal';
import EditJobStatusModal from '../modals/EditJobStatusModal';

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    actionOutlinedButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        borderColor: Colors.redColor,
        fontWeight:'bold',
        color: Colors.redColor,
        height: 50,
        marginRight: 8
    },
    rejectButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color: Colors.redColor,
        height: 50,
        marginRight: 8
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const ACTION_REQUIRED_JOB_TITLES = {
    'PENDING_APPROVAL': 'This project requires approval to begin.'
}

const ViewSiteJobComponent = ({ classes, history, location, match }) => {

    const jobRef = useRef([])
    const [ siteData, setSiteData ] = useState();
    const [ isLoading, setIsLoading ] = useState();
    const [ isApproving, setIsApproving ] = useState(false);
    const [ showRejectionModal, setShowRejectionModal ] = useState(false);
    const [ showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [ showApprovalModal, setShowApprovalModal ] = useState(false);
    const [ showConfirmSendApprovalRequest, setShowConfirmSendApprovalRequest] = useState(false);
    const [ requestedStatusChange, setRequestStatusChange] = useState(false);

    const { job_details, job_quote, job_metadata, job_hours, job_security } = jobRef.current || {};

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        const job_id = params.job_id;
        let site = state && state.site ? state.site : undefined;
        const site_job = state && state.site_job ? state.site_job : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        loadData(site_id, job_id, site)


        if (site === undefined || site_job === undefined) loadData(site_id, job_id);
        else {
            jobRef.current = site_job;
            setSiteData(site);
        }
    }

    const loadData = async (site_id, job_id, site) => {
        setIsLoading(true);
        try {
            let loadedSite = site;
            if (!loadedSite) {
                loadedSite = await getSite(site_id)
            }
            if (loadedSite === undefined) {
                history.push('/dashboard');
                return;
            }
            const userIsAdmin = isAdmin();
            const site_job = await getJob(site_id, job_id, getUserId(), userIsAdmin, userIsAdmin)
            if (site_job === undefined || site_job.length === 0) { history.goBack(); return; }
            if (site_job.job_details.job_status === JOB_STATUS_MAP.PENDING && !userIsAdmin) {
                history.replace(`/dashboard/site/${loadedSite.site_id}/projects/${site_job.job_id}/review`);
            }
            jobRef.current = site_job;
            setSiteData(loadedSite);
            setIsLoading(false);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        }
    }

    const handleApproveJob = async () => {
        try {
            setIsApproving(true)
            const updatedJob = await updateJobStatus(getUserId(), siteData.site_id, jobRef.current.job_id, JOB_STATUS_MAP.APPROVED)
            jobRef.current = updatedJob;
            NotificationBarActions.showNotificationBar('success', 'This project has been approved!');
            setShowApprovalModal(false)
        } catch(err) {
            NotificationBarActions.showNotificationBar('error', 'A problem has occurred while approving this project. Please try again later.');
        } finally {
            setIsApproving(false)
        }
    }

    const handleRejectJob = async (reason) => {
        try {
            setShowRejectionModal(false)
            setIsApproving(true)
            const updatedJob = await updateJobStatus(getUserId(), siteData.site_id, jobRef.current.job_id, JOB_STATUS_MAP.REJECTED, `Client Provided Reason: "${reason}"`)
            jobRef.current = updatedJob;
            NotificationBarActions.showNotificationBar('info', 'This project has been rejected.');
        } catch(err) {
            NotificationBarActions.showNotificationBar('error', 'A problem has occurred while approving this project. Please try again later.');
        } finally {
            setIsApproving(false)
        }
    }

    const handleDeleteJob = async () => {
        try {
            setShowConfirmDelete(false)
            setIsLoading(true)
            await deleteJob(jobRef.current.job_id, getUserId());
            NotificationBarActions.showNotificationBar('success', `The project "${jobRef.current.job_details.job_name}" has been deleted successfully.`);
            history.push(`/dashboard/site/${jobRef.current.job_site_id}/jobs`)
        } catch(err) {
            const ex = await err;
            NotificationBarActions.showNotificationBar('error', `A problem has occurred while deleting this project. Details: ${ex && ex.message ? ex.message : 'No error Details.'}`);
        } finally {
            setIsLoading(false)
        }
    }

    const handleSendApprovalRequest = async () => {
        try {
            setShowConfirmDelete(false)
            setIsLoading(true)
            await sendApprovalRequest(jobRef.current.job_id, getUserId());
            NotificationBarActions.showNotificationBar('success', `The project "${jobRef.current.job_details.job_name}" has been deleted successfully.`);
            history.push(`/dashboard/site/${jobRef.current.job_site_id}/jobs`)
        } catch(err) {
            const ex = await err;
            NotificationBarActions.showNotificationBar('error', `A problem has occurred while deleting this project. Details: ${ex && ex.message ? ex.message : 'No error Details.'}`);
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateJobStatus = (updatedJob) => {
        // statusRef.current = updatedJob.job_details.job_status;
        jobRef.current = updatedJob;
        setRequestStatusChange(false);
        if (FINAL_JOB_STATUSES.includes(updatedJob.job_details.job_status)) history.replace(`/dashboard/site/${updatedJob.job_site_id}/jobs/${updatedJob.job_id}`)
    }

    const handleShowNewTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
      }

    const renderDescription = (description) => description.split('\n').reduce((acc, val)=> [...acc, <div>{val}</div>],[])

    const renderQuoteSection = ({quote_amount_due_at_start, quote_amount_due_at_complete, quote_amount, quote_items }) => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                        <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Units</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody >
                            {quote_items !== undefined && quote_items.map((item) => (
                                <TableRow
                                key={item.item_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                                >
                                    <TableCell component="th" scope="row" style={{ fontSize: 16 }}>{item.item_name}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>{item.item_units}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>${(item.item_unit_cost / 100).toFixed(2)}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>${(item.item_total / 100).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16, color: Colors.darkGreyText }}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right', color: Colors.darkGreyText }}>
                                ${(quote_amount/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Start
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount_due_at_start/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Completion
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount_due_at_complete/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 18 }}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderHourlySection = ({ hours_estimated_total }, site) => {
        let hourly_rate = -1;
        if (site && site.site_service_modules && site.site_service_modules.consulting) {
            hourly_rate = site.site_service_modules.consulting.general_consulting.pricing.default
        }

        return hours_estimated_total === -1 ?
        (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project will be billed by incurred hours. The incurred hours will be charged at the base rate of <b>${(hourly_rate/100).toFixed(2)}</b> per hour.
                    </Typography>
                </Grid>
            </Grid>
        )
        :
        (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                        <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Units</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                            >
                                <TableCell component="th" scope="row" style={{ fontSize: 16 }}>Consulting Hour (Base Rate)</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>{hours_estimated_total}</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>${(hourly_rate / 100).toFixed(2)}</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16, color: Colors.darkGreyText }}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right', color: Colors.darkGreyText }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Start
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                $0.00
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Completion
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 18 }}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18, marginRight: 14, textAlign: 'right' }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderNoCostSection = () => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project is <b>free</b>! 🎉
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const renderProBonoSection = ({ hours_estimated_total }) => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project will incurr hours at no cost to you. { hours_estimated_total !== -1 ? `The estimated amount of hours for this project is ${hours_estimated_total}.` : ' There is no estimation on the amount of hours this project will take.' }
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const renderPricingSection = () => (
        <Accordion style={{ marginBottom: 8 }} variant='outlined' defaultExpanded={job_details.job_status === 'PENDING_APPROVAL'}>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography variant='h6' component='h2' style={{fontSize: 28 }}>
                Pricing
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {job_details.job_payment_structure === 'QUOTE' && renderQuoteSection(job_quote || {})}
                {job_details.job_payment_structure === 'HOURLY' && renderHourlySection(job_hours, siteData)}
                {job_details.job_payment_structure === 'NO_COST' && renderNoCostSection(job_quote)}
                {job_details.job_payment_structure === 'PRO_BONO' && renderProBonoSection(job_hours)}
            </AccordionDetails>
        </Accordion>
    )

    const renderHoursSection = () => (
        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
                <Typography variant='h6' component='h2' style={{fontSize: 28 }}>
                    Hours
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                            <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Start</TableCell>
                                <TableCell align="right">End</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody >
                                {(job_hours && job_hours.hours_incurred && job_details.job_status !== 'PENDING_APPROVAL') ? job_hours.hours_incurred.entries.map((entry) => (
                                    <TableRow
                                    key={entry.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                                    >
                                        <TableCell component="th" scope="row" style={{ fontSize: 16 }}>{entry.description}</TableCell>
                                        <TableCell align="right" style={{ fontSize: 16 }}>{new Date(entry.timeInterval.start).toLocaleString()}</TableCell>
                                        <TableCell align="right" style={{ fontSize: 16 }}>{new Date(entry.timeInterval.end).toLocaleString()}</TableCell>
                                    </TableRow>
                                )):
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12 }}>
                                    <Typography variant="caption" component="h2" style={{ fontSize: 16, textAlign: 'left', paddingLeft: 16, paddingRight: 16 }}>
                                        {job_details.job_status === 'PENDING_APPROVAL' ? 'This project proposal is awaiting approval. No hours will be logged until approved to start.' : 'No hours have been logged.' }
                                    </Typography>  
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Grid item xs={'auto'}>
                                <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                    Total Logged Hours
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                    {(job_hours && job_hours.hours_incurred) ? (job_hours.hours_incurred.estimated_hours).toFixed(1) : '0'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify='flex-end'>
                            <Grid item xs={'auto'}>
                                <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                    Total Estimated Hours
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                    {(job_hours && job_hours.hours_estimated_total > 0) ? job_hours.hours_estimated_total : 'Not Estimated'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )

    const renderAdminHoursSection = () => (
        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                Reported Hours <i style={{ fontSize: 14 }}>[ID: {job_hours && job_hours.hours_clockify_project_id ? job_hours.hours_clockify_project_id : 'No ID Provided'}]</i>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                            <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Start</TableCell>
                                <TableCell align="right">End</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody >
                                {(job_hours && job_hours.hours_incurred) ? job_hours.hours_incurred.entries.map((entry) => (
                                    <TableRow
                                    key={entry.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                                    >
                                        <TableCell component="th" scope="row" style={{ fontSize: 16 }}>{entry.description}</TableCell>
                                        <TableCell align="right" style={{ fontSize: 16 }}>{new Date(entry.timeInterval.start).toLocaleString()}</TableCell>
                                        <TableCell align="right" style={{ fontSize: 16 }}>{new Date(entry.timeInterval.end).toLocaleString()}</TableCell>
                                    </TableRow>
                                )):
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}>
                                    <TableCell>
                                        <TableRow>
                                            <Typography variant="caption" component="h2" style={{ fontSize: 16, textAlign: 'left' }}>
                                                No hour entries found.
                                            </Typography> 
                                        </TableRow>
                                    </TableCell>  
                                </TableRow>
                                    
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='flex-end'>
                            <Grid item xs={'auto'}>
                                <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                    Estimated Total Hours
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                    {(job_hours && job_hours.hours_incurred) ? (job_hours.hours_incurred.estimated_hours).toFixed(1) : '0'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify='flex-end'>
                            <Grid item xs={'auto'}>
                                <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                    Estimated Total Cost
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                    ${(job_hours && job_hours.hours_incurred) ? (job_hours.hours_incurred.estimated_cost/100).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )

    const renderAdminAccessSection = () => (
        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                Access
            </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
                {job_security && job_security.is_hidden &&
                <div>
                    <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 16 }}>
                        Project Hidden
                    </Typography>
                    <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                        This project is hidden from the clients view. Any non-admin user will be able to view this project, even if they have been granted access. To change this, edit the project.
                    </Typography>
                </div>}
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="caption" component="h2" style={{ marginTop: 15, fontSize: 16 }}>
                            Users With Access
                        </Typography>
                        <List>
                        { (job_security && job_security.accessible_users) ? job_security.accessible_users.map((user) => (
                            <ListItem dense key={user.user_id}>
                                <ListItemText primary={`${user.user_personal.first_name} ${user.user_personal.last_name}`} secondary={user.user_contact.email.primary} />
                            </ListItem>
                        ))
                        : (
                        <ListItem dense key={'NONE'}>
                            <i className={classes.defaultText}>No User Access Defined...</i>
                        </ListItem>
                        )} 
                        </List>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )

    const renderMetaSection = () => (
        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
            <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                Metadata
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justify='flex-start' spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 14 }}>
                            Created
                        </Typography>
                        <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                            {new Date(job_metadata.created_at).toLocaleString()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 14 }}>
                            Last Modified
                        </Typography>
                        <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                            {new Date(job_metadata.modified_at).toLocaleString()}
                        </Typography>
                    </Grid>
                    {JOB_STATUS.filter(status => job_metadata[`${status.value.toLowerCase()}_at`] !== undefined).map(status => (
                        <Grid item xs={12} md={6} key={status.value}>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 14 }}>
                                {status.label}
                            </Typography>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                {new Date(job_metadata[`${status.value.toLowerCase()}_at`]).toLocaleString()}
                            </Typography>
                            {job_metadata[`${status.value.toLowerCase()}_reason`] !== undefined && <Typography variant="body1" component="h2" style={{ fontSize: 18, color: Colors.darkGreyText }}>
                                {job_metadata[`${status.value.toLowerCase()}_reason`]}
                            </Typography>}
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )

    const renderApprovalRequiredSection = () => {
        return (
            <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 24, paddingBottom: 24, border: '1px solid rgba(250, 141, 0,0.75)', borderRadius: 3 }}>
                    <Grid container justify='space-between' spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 16 }}>
                                Action Required
                            </Typography>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                Please review the project proposal below and approve or reject, once ready. No work will begin until we receive your approval.
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                        <Button
                            className={classes.rejectButton}
                            disabled={isApproving || isLoading}
                            onClick={() => setShowRejectionModal(true) }
                            
                        >
                            REJECT
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.actionButton}
                            disabled={isLoading}
                            onClick={() => setShowApprovalModal(true)}
                        >
                            { isApproving ?
                                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                : <span>APPROVE</span>
                            }
                        </Button>
                        
                        </Grid>
                    </Grid>
                    {job_metadata[`${job_details.job_status.toLowerCase()}_reason`] !== undefined && <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="caption" component="h2" style={{ marginTop: 5, fontSize: 14 }}>
                                Details
                            </Typography>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                {job_metadata[`${job_details.job_status.toLowerCase()}_reason`]}
                            </Typography>
                        </Grid>
                    </Grid>}
                </div>
            </div>
        )
    }

    const renderPaymentRequiredSection = () => {
        const job_payment_tracking = jobRef.current.job_details.job_payment_tracking;
        if (!job_payment_tracking.tracking_initial_payment || job_payment_tracking.tracking_initial_payment.invoice_paid) return undefined;

        return (<Grid container direction='row' justifyContent='flex-end'>
            <Grid item xs={'auto'} style={{ paddingRight: 16 }}>
                <Button variant="contained" className={classes.actionButton} onClick={() => handleShowNewTab(job_payment_tracking.tracking_initial_payment.invoice_url)}>
                    Pay Now
                </Button>
            </Grid>
        </Grid>)
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details, site_id } = siteData ? siteData : {};
    
    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justifyContent='space-between'>
                        <Grid item xs='auto'>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'}>
                                    <IconButton onClick={() => history.goBack()}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6" component="h3">
                                        Project Details
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs='auto'>
                            {job_details.job_status === JOB_STATUS_MAP.AWAITING_PAYMENT && renderPaymentRequiredSection()}
                            {isAdmin()&&
                            <Grid container direction='row'>
                                <Grid item xs='auto'>
                                    <Tooltip title='Send Approval Request'>
                                        <IconButton
                                            style={{ marginRight: 4}}
                                            disabled={isLoading || jobRef.current.job_details.job_status !== JOB_STATUS_MAP.DRAFT}
                                            onClick={() => setShowConfirmSendApprovalRequest(true)}
                                        >
                                            <Send />
                                        </IconButton>  
                                    </Tooltip>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Tooltip title='Update Status'>
                                        <IconButton
                                            style={{ marginRight: 4}}
                                            disabled={isLoading || FINAL_JOB_STATUSES.includes(jobRef.current.job_details.job_status)}
                                            onClick={() => setRequestStatusChange(true)}
                                        >
                                            <Update />
                                        </IconButton>  
                                    </Tooltip>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Tooltip title='Edit Project Details'>
                                        <IconButton
                                            style={{ marginRight: 4}}
                                            disabled={isLoading || FINAL_JOB_STATUSES.includes(jobRef.current.job_details.job_status)}
                                            onClick={() => history.push(`/admin/sites/${siteData.site_id}/jobs/${jobRef.current.job_id}/edit`)}
                                        >
                                        <Edit />
                                        </IconButton>  
                                    </Tooltip>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Tooltip title='Delete Project'>
                                        <IconButton
                                            style={{ marginRight: 16, color: '#f00'}}
                                            disabled={isLoading}
                                            onClick={() => setShowConfirmDelete(true)}
                                        >
                                            <Delete />
                                        </IconButton>  
                                    </Tooltip>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                    
                    
                    {job_details.job_status === 'PENDING_APPROVAL' && renderApprovalRequiredSection()}
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        <Paper style={{ marginBottom: 16 }} variant='outlined'>
                            {job_security && job_security.is_hidden &&
                            <div style={{ backgroundColor: Colors.warningBackground, marginBottom: 0, padding: 8, borderTopLeftRadius: 3, borderTopRightRadius: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.subtitleText} style={{ fontSize: 16, margin: 0, textAlign: 'center'  }}>This project is hidden.</div>
                                    </Grid>
                                </Grid>
                            </div>
                            }
                            <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 24 }}>
                                <Grid container justify='space-between' spacing={1}>
                                    <Grid item xs={'auto'}>
                                        <Typography variant="h5" component="h3">
                                            {job_details.job_name}
                                        </Typography>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            <i>Created On {new Date(job_metadata.created_at).toLocaleDateString()}</i>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title={JOB_STATUS.find(status => status.value === job_details.job_status).description}>
                                            <Chip
                                                style={{ marginTop: 10, cursor: 'pointer' }}
                                                label={JOB_STATUS.find(status => status.value === job_details.job_status).label}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginTop: 5, marginBottom: 18 }} />
                                <Grid container justify='flex-start' spacing={2} style={{ minHeight: '10vh' }}>
                                    
                                    <Grid item xs={12}>
                                        <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 16 }}>
                                            Project Description
                                        </Typography>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                            {renderDescription(job_details.job_description)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginTop: 5, marginBottom: 18 }} />
                                <Grid container spacing={2}>
                                    <Grid item xs={'auto'}>
                                        <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                            Latest Update Message
                                        </Typography>
                                        <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                                            {job_details.job_status_note ? job_details.job_status_note : <i>No message at this time. Check back later for an update!</i>}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                    <div style={{ paddingLeft: 16, paddingRight: 16}}>
                        {renderPricingSection()}
                        {(job_details.job_payment_structure === 'HOURLY' || job_details.job_payment_structure === 'PRO_BONO') && renderHoursSection()}
                    </div>
                    {isAdmin() &&
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            <Divider style={{ marginBottom: 8 }} />
                            {renderAdminHoursSection()}
                            {renderAdminAccessSection()}
                            {renderMetaSection()}
                        </div>
                    }
                    
                    <RejectJobModal open={showRejectionModal} onCancel={() => setShowRejectionModal(false)} onReject={handleRejectJob} />
                    <AcceptProjectModal
                        open={showApprovalModal}
                        onCancel={() => setShowApprovalModal(false)}
                        onAccept={handleApproveJob}
                        project={jobRef.current}
                    />
                    <ConfirmationModal key='delete-confirmation' open={showConfirmDelete} onClose={() => setShowConfirmDelete(false)} onOk={handleDeleteJob} okText={<span style={{ color: Colors.redColor }}>Delete</span>} confirmationDetails={`You are about to delete this project "${jobRef.current.job_details.job_name}", which cannot be undone. Please confirm this is what you want to do.`}/>
                    <ConfirmationModal key='send-approval-request-confirmation' open={showConfirmSendApprovalRequest} onClose={() => setShowConfirmSendApprovalRequest(false)} onOk={handleSendApprovalRequest} okText={'Send Request'} confirmationDetails={<div>You are about to send an approval request for this project. This action cannot be undone, and will set this project to a status of "Pending". <b>Any amount due at the start will be requested from the primary user of this account via an invoice.</b> Please confirm this is what you would like to do.</div>}/>
                    <EditJobStatusModal
                        open={requestedStatusChange}
                        site={siteData}
                        job={jobRef.current}
                        onSuccess={handleUpdateJobStatus}
                        onClose={() => setRequestStatusChange(false)} 
                        requestedStatus={requestedStatusChange}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ViewSiteJobComponent);