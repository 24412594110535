import React, { Component, useEffect } from "react";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import GenericNotFound from './components/error-pages/GenericNotFound';
import NotificationBar from "./components/error-pages/NotificationBar";
import ExternalRouter from './components/external/ExternalRouter';
import InternalRouter from "./components/internal/InternalRouter";
import Colors from "./constants/Colors";

const theme = createMuiTheme({
  palette: {
    primary: {
        main: Colors.darkColor
    },
  },
  typography: { 
      fontSize:20,
      fontFamily:'Nunito Sans, sans-serif'
  },
  icon: {
    fontSize: 24,
  },
  input : {
    textAlign: 'center'
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
  },
})

export default class MasterComponent extends Component {

  componentDidMount () {
    const clicky = document.createElement("script");
    const google = document.createElement("script");

    clicky.src = "//static.getclicky.com/js";
    clicky.async = true;
    clicky.dataset.id = '101422999'

    google.src = 'https://www.googletagmanager.com/gtag/js?id=G-J7V5P484KH'
    google.async = true;

    document.head.appendChild(clicky);
}

    render() {
        return (
          <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/account" render={routeProps => <InternalRouter {...routeProps} />} />
                    <Route path="/admin" render={routeProps => <InternalRouter {...routeProps} />} />
                    <Route path="/dashboard" render={routeProps => <InternalRouter {...routeProps} />} />
                    <Route path="/" render={routeProps => <ExternalRouter {...routeProps} />} />
                    
                    <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
                </Switch>
            </BrowserRouter>
            <NotificationBar />
          </ThemeProvider>
        );
    }
}
