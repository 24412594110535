import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, CircularProgress, Grid, IconButton, MenuItem, Paper, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardBackspace, Store } from "@material-ui/icons";
import { isEmpty, isEqual } from "lodash";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { createNewSite, updateSite } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { getSite } from "../../../actions/internal/SiteActions";
import { isValidEmail } from "../../../utilities/Validation";
import { getClockifyClients, getClockifyProjects } from "../../../clients/clockify/ClockifyApi";
import { COMPANY_TYPES } from "../../../constants/Common";
import UpdateSiteImageModal from "../modals/UpdateSiteImageModal";
import { SITE_STATUSES } from "../../../constants/Statuses";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    textField: {
        marginTop: 0
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
    },
    titleText: {
        fontSize:44,
        color: Colors.primaryColor,
        fontFamily:'Rock Salt, sans-serif',
        textAlign:'center',
        fontWeight:'bold',
        fontStyle:'italic',
        marginTop:15,
        [theme.breakpoints.up('sm')]: {
            textAlign:'left',
            fontSize:56
        },
    },
    subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
    },
    sectionText: {
    fontSize:24,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    marginTop:15,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:24
    },
    }
})

const AdminEditSiteComponent = ({ classes, history, users, match }) => {

    const selectablePrimaryUsers = useRef([]);
    const selectableClockifyProjects = useRef([])
    const selectableClockifyClients = useRef([])

    const [site, setSite] = useState();
    const [companyName, setCompanyName ] = useState('');
    const [companyStatus, setCompanyStatus ] = useState('');
    const [companyStructure, setCompanyStructure ] = useState('');
    const [companyAddressLine1, setCompanyAddressLine1 ] = useState('');
    const [companyAddressLine2, setCompanyAddressLine2 ] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyState, setCompanyState] = useState('');
    const [companyZipCode, setCompanyZipCode] = useState('');
    const [companyEin, setCompanyEin] = useState('');
    const [primaryUser, setPrimaryUser] = useState('');
    const [companyRecoveryEmail, setCompanyRecoveryEmail] = useState('');
    const [boxUrl, setBoxUrl] = useState('');
    const [clockifyProject, setClockifyProject] = useState('')
    const [clockifyClient, setClockifyClient] = useState('')

    const [showAddSiteImage, setShowAddSiteImage] = useState(false)

    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        loadSession()
    }, [])

    const loadSession = async () => {
        try {
            const siteId = match.params.site_id
            setIsLoading(true)
            const site = await getSite(siteId);
            if (!site) { throw 'No site found.'; return; }
            selectablePrimaryUsers.current = users.filter(user => site.site_security.accessible_user_ids.includes(user.user_id));
            selectableClockifyProjects.current = await getClockifyProjects();
            selectableClockifyClients.current = await getClockifyClients();

            setCompanyName(site.site_details.site_name)
            setCompanyStatus(site.site_details.site_status)
            setCompanyStructure(site.site_details.site_structure)
            setCompanyAddressLine1(site.site_details.site_address.address_line_1)
            setCompanyAddressLine2(site.site_details.site_address.address_line_2)
            setCompanyCity(site.site_details.site_address.city)
            setCompanyState(site.site_details.site_address.state)
            setCompanyZipCode(site.site_details.site_address.zipcode)
            setCompanyEin(site.site_details.site_ein)
            setPrimaryUser(site.site_security.primary_user_id)
            setCompanyRecoveryEmail(site.site_details.site_recovery_email)
            if (site.site_external_connections.clockify) {
                setClockifyClient(site.site_external_connections.clockify.client_id || '')
            } 
            if (site.site_external_connections.box) {
                setBoxUrl(site.site_external_connections.box.box_url || '')
            } 
            setSite(site)
        } catch (err) {
            NotificationBarActions.showNotificationBar('error', 'Could not load site to edit at this time. Please try again later.');
            console.error(err)
            history.goBack()
        } finally {
            setIsLoading(false)
        }
    }

    const isValidForm = () => {
        const foundErrors = [];
        if (!primaryUser) foundErrors.push('primaryUser')
        if (!companyName || isEmpty(companyName)) foundErrors.push('companyName');
        if (!companyStatus) foundErrors.push('companyStatus');
        if (!companyStructure || isEmpty(companyStructure)) foundErrors.push('companyStructure');
        if (!companyAddressLine1 || isEmpty(companyAddressLine1)) foundErrors.push('companyAddressLine1')
        if (!companyCity || isEmpty(companyCity)) foundErrors.push('companyCity')
        if (!companyState || isEmpty(companyState)) foundErrors.push('companyState')
        if (!companyZipCode || isEmpty(companyZipCode) || isNaN(companyZipCode) || Number(companyZipCode) < 10000 || Number(companyZipCode) > 99999 ) foundErrors.push('companyZipCode')
        if (!isEmpty(companyRecoveryEmail) && !isValidEmail(companyRecoveryEmail)) foundErrors.push('recoveryEmail');

        setErrors(foundErrors)
        return foundErrors.length === 0
    }
    
    const preparePayload = () => {
        const masks = []
        const maskProps = {}
        if (primaryUser !== site.site_security.primary_user_id) { masks.push('primary_user_id'); maskProps.primary_user_id = primaryUser }
        if (companyName !== site.site_details.site_name) { masks.push('site_name'); maskProps.site_name = companyName }
        if (companyStatus !== site.site_details.site_status) { masks.push('site_status'); maskProps.site_status = companyStatus }
        if (companyStructure !== site.site_details.site_structure) { masks.push('site_structure'); maskProps.site_structure = companyStructure; }
        if (companyAddressLine1 !== site.site_details.site_address.address_line_1) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, address_line_1: companyAddressLine1 } }
        if (companyAddressLine2 !== site.site_details.site_address.address_line_2) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, address_line_2: companyAddressLine2 } }
        if (companyCity !== site.site_details.site_address.city) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, city: companyCity } }
        if (companyState !== site.site_details.site_address.state) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, state: companyState } }
        if (companyZipCode !== site.site_details.site_address.zipcode) { masks.push('site_address'); maskProps.site_address = { ...maskProps.site_address, zipcode: companyZipCode } }
        if (companyRecoveryEmail !== site.site_details.site_recovery_email && (!isEmpty(companyRecoveryEmail) && !site.site_details.site_recovery_email)) { masks.push('site_recovery_email'); maskProps.site_recovery_email = companyRecoveryEmail }

        let externalMaskProps = site.site_external_connections

        if (!isEmpty(clockifyClient)
            && (
                !site.site_external_connections.clockify
                || !site.site_external_connections.clockify.clockify_client_id
                || site.site_external_connections.clockify.clockify_client_id !== clockifyProject
                )
         ) { externalMaskProps = { ...externalMaskProps, clockify: { client_id: clockifyClient } } }
         if (!isEmpty(boxUrl)
            && (
                !site.site_external_connections.box
                || !site.site_external_connections.box.box_url
                || site.site_external_connections.box.box_url !== boxUrl
                )
         ) { externalMaskProps = { ...externalMaskProps, box: { box_url: boxUrl } } }

         if (!isEqual(externalMaskProps, maskProps.site_external_connections)) {
            masks.push('site_external_connections')
            maskProps.site_external_connections = externalMaskProps
         }

         
        // merge existing site address with edits
        if (maskProps.site_address !== undefined) maskProps.site_address = { ...site.site_details.site_address, ...maskProps.site_address }
        return { masks, maskProps }
    }

    const handleUpdateSite = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);

            const sitePayload = preparePayload()
            if (sitePayload.masks.length > 0) {
                await updateSite(site.site_id, getUserId(), sitePayload.masks, sitePayload.maskProps)
                NotificationBarActions.showNotificationBar('success', 'Site has been updated');
            }
            history.goBack();
        } catch(err) {
            const resp = await err;
            console.error(resp);
            NotificationBarActions.showNotificationBar('error', 'Could not update the site at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Update Site Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Required Information
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5 }}>
                                        Provide the necessary infomation to create the new account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    select
                                    error={errors.includes('primaryUser')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Primary User"
                                    variant="outlined"
                                    value={primaryUser}
                                    onChange={(e) => setPrimaryUser(e.target.value)}
                                >
                                    {selectablePrimaryUsers.current.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {user.user_personal.first_name} {user.user_personal.last_name} <span style={{fontSize: 16, paddingLeft:5}}>({user.user_login_id})</span>
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    error={errors.includes('companyStatus')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Account Status"
                                    variant="outlined"
                                    value={companyStatus}
                                    onChange={(e) => setCompanyStatus(e.target.value)}
                                >
                                    {SITE_STATUSES.map((status) => (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Company Info</div>
                                </Grid>
                                <Grid item xs={10} md={8}>
                                    <TextField
                                    error={errors.includes('companyName')}
                                    fullWidth
                                    value={companyName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Company/Organization Name"
                                    variant="outlined"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} md={4} style={{ marginTop: 10 }}>
                                    {site && site.site_details && site.site_details.site_img_url ?
                                    <Avatar alt={companyName} src={site.site_details.site_img_url} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => setShowAddSiteImage(true)} />
                                    :
                                    <Avatar style={{ marginRight: 10, cursor: 'pointer'  }} onClick={() => setShowAddSiteImage(true)} ><Store /></Avatar>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    error={errors.includes('companyStructure')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Business Structure"
                                    variant="outlined"
                                    value={companyStructure}
                                    onChange={(e) => setCompanyStructure(e.target.value)}
                                >
                                    {COMPANY_TYPES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    error={errors.includes('companyEin')}
                                    fullWidth
                                    value={companyEin}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Employer ID Number (EIN)"
                                    variant="outlined"
                                    onChange={(e) => setCompanyEin(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Legal Address</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Please provide your legal business address. If you do not yet have one, please provide your current address. This information is securely saved, and will never be shared externally.</div>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    error={errors.includes('companyAddressLine1')}
                                    fullWidth
                                    value={companyAddressLine1}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Street Address"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine1(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    error={errors.includes('companyAddressLine2')}
                                    fullWidth
                                    value={companyAddressLine2}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Unit/Apt/Suite"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine2(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyCity')}
                                    fullWidth
                                    value={companyCity}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*City"
                                    variant="outlined"
                                    onChange={(e) => setCompanyCity(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyState')}
                                    fullWidth
                                    value={companyState}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*State"
                                    variant="outlined"
                                    onChange={(e) => setCompanyState(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    error={errors.includes('companyZipCode')}
                                    fullWidth
                                    value={companyZipCode}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Zip Code"
                                    variant="outlined"
                                    onChange={(e) => setCompanyZipCode(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Additional Info</div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={errors.includes('recoveryEmail')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="Recovery Email"
                                        value={companyRecoveryEmail}
                                        variant="outlined"
                                        onChange={(e) => setCompanyRecoveryEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        error={errors.includes('boxUrl')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="Box URL"
                                        value={boxUrl}
                                        variant="outlined"
                                        onChange={(e) => setBoxUrl(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        error={errors.includes('clockifyClient')}
                                        fullWidth
                                        className={classes.textField}
                                        label="Clockify Client"
                                        variant="outlined"
                                        value={clockifyClient}
                                        disabled={isLoading}
                                        onChange={(e) => setClockifyClient(e.target.value)}
                                    >
                                        {selectableClockifyClients.current.map((client) => (
                                            <MenuItem key={client.id} value={client.id}>
                                                {client.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                
                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <Button 
                                className={classes.buttonContainedStyle}
                                variant='contained'
                                disabled={isLoading}
                                onClick={handleUpdateSite} color="primary">
                                { isLoading ?
                                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                : <span>Update Site</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <UpdateSiteImageModal
                history={history}
                site={site}
                open={showAddSiteImage} onClose={() => setShowAddSiteImage(false)} />
        </div>
    )
}

export default withStyles(styles)(AdminEditSiteComponent);