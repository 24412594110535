import React from "react";
import { Button, Grid, Menu, MenuItem, Typography, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";

const styles = theme => ({
   root: {
    fontFamily:'Nunito Sans, sans-serif',
    marginTop: 20,
  },
 });

const AdminWelcomeCard = ({classes, history, primaryText, secondaryText}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item xs={12}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div className={ classes.root }>
          <Typography variant="h6" component="h2">
            {primaryText}
          </Typography>
          <Typography variant="caption" component="h2" style={{ marginTop: -5 }}>
            {secondaryText}
          </Typography>
        </div>
        <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" variant='outlined' onClick={handleClick} style={{ marginTop: 25}}>
            <Add /> Add 
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => history.push('/admin/users/add')}>New User</MenuItem>
            <MenuItem onClick={() => history.push('/admin/sites/add')}>New Site</MenuItem>
            <MenuItem onClick={() => history.push(`/admin/jobs/add`)}>New Project</MenuItem>
          </Menu>
        </div>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(AdminWelcomeCard);