import { cloneDeep, findIndex } from "lodash";
import { fetchAllSitesForUser, fetchSite, fetchSiteHoursReport, fetchSitePaymentReport, fetchSiteSubscription } from "../../clients/bettercallkyle/BckApi";
import SitesStore from "../../stores/internal/SitesStore";


export const getAllSitesForUser = async (user_id, force_refresh = false) => {
  if (!force_refresh && SitesStore.sites !== undefined) return cloneDeep({ sites: SitesStore.sites });
  if (user_id === undefined) throw "No user id provided"
  const response = await fetchAllSitesForUser(user_id);
  SitesStore.setSites(response.sites);
  return response;
}

export const getSite = async (site_id, force_refresh = false) => {
  let index = -1;
  if (!force_refresh && SitesStore.sites !== undefined) {
    index = findIndex(SitesStore.sites, { site_id });
    if (index > -1) return cloneDeep(SitesStore.sites[index])
  }
  const sites = SitesStore.sites === undefined ? [] : cloneDeep(SitesStore.sites);
  const site = await fetchSite(site_id);
  if (index > -1) sites.splice(index, 1)
  sites.push(site);
  SitesStore.setSites(sites);
  return site;
}

export const getSiteHoursReport = async (site_id, start = undefined, end = undefined, force_refresh = false) => {
  let key = `${site_id}&${start}&${end}`;
  if (!force_refresh && SitesStore.site_hour_reports[key] !== undefined) {
    return cloneDeep(SitesStore.site_hour_reports[key])
  }
  const report = await fetchSiteHoursReport(site_id, start, end);
  SitesStore.setSiteHourReport(key, report);
  return report;
}

export const getSitePaymentReport = async (site_id, force_refresh = false) => {
  try {
    let key = `${site_id}`;
    if (!force_refresh && SitesStore.site_invoice_reports[key] !== undefined) {
      return cloneDeep(SitesStore.site_invoice_reports[key])
    }
    const report = await fetchSitePaymentReport(site_id)
    SitesStore.setSiteInvoiceReport(key, report);
    return report;
  } catch (err) {
    const error = await err;
    throw error
  }
}

export const getSiteSubscription = async (user_id, site_id, force_refresh = false) => {
  let key = `${site_id}`;
  if (!force_refresh && SitesStore.site_subscriptions[key] !== undefined) {
    return cloneDeep(SitesStore.site_subscriptions[key])
  }
  const response = await fetchSiteSubscription(user_id, site_id);
  SitesStore.setSiteSubscriptionReport(key, response);
  return response;
}