import React, { Component } from "react";
import {Route, Switch} from 'react-router-dom';

import ExternalHeader from './ExternalHeader';

import '../../styles/external/external-styles.css';
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GenericNotFound from "../error-pages/GenericNotFound";
import NewCompanyForm from "./pages/NewCompanyForm";
import VerificationForm from "./pages/VerificationForm";
import RegistrationForm from "./pages/RegistrationForm";
import LoginForm from "./pages/LoginForm";
import ExternalHeaderV2 from "./ExternalHeaderV2";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";

export default class ExternalRouter extends Component {
    render() {

        return (
            <div>
                <ExternalHeaderV2 />
                <Switch>
                    <Route exact path="/" render={routeProps => <LoginForm {...routeProps} />} />
                    <Route exact path="/terms" render={routeProps => <TermsAndConditions {...routeProps} />} />
                    <Route exact path="/privacy" render={routeProps => <PrivacyPolicy {...routeProps} />} />
                    <Route exact path="/login" render={routeProps => <LoginForm {...routeProps} />} />
                    <Route exact path="/register" render={routeProps => <RegistrationForm {...routeProps} />} />
                    <Route exact path="/verify" render={routeProps => <VerificationForm {...routeProps} />} />
                    <Route exact path="/welcome" render={routeProps => <NewCompanyForm {...routeProps} />} />
                    <Route exact path="/reset-password" render={routeProps => <ForgotPasswordForm {...routeProps} />} />
                    <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
                </Switch>
            </div>
        );
    }
}
