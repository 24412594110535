import React from 'react';

import { Button, Grid, Popover } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupsIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PropTypes from 'prop-types';
import { getUserObject, isAdmin, signUserOut } from '../../actions/profile/UserActions';
import KyleLogoIcon from '../../assets/logo/bck-logo-white.png'; // relative path to image
import Colors from '../../constants/Colors';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontFamily:'Nunito Sans, sans-serif',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sandboxBanner: {
    fontSize:16,
    paddingTop: 3,
    paddingBottom: 3,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.warningBackground,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  outlinedButton: {
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.primaryColor,
  },
  box: {
    borderRadius: 10,
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: Colors.lightGreyColor
    }
  },
  homeText: {
    fontSize:24,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  optionText: {
    fontSize:20,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 5
  },
  iconText: {
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 5,
  },
});

class InternalHeader extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,appMenuAnchorEl:null,
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, mobileMoreAnchorEl: null, appMenuAnchorEl: null });
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleAppMenuOpen = event => {
    this.setState({ appMenuAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleAppMenuClose = () => {
    this.setState({ appMenuAnchorEl: null });
  };

  handleSignUserOut = () => {
    signUserOut();
    this.handleAppMenuClose();
    this.props.history.push('/');
  };

  getFullName = () => (
    getUserObject() && `${getUserObject().user_personal.first_name}`
  )

  handleDashboardClick = () =>  { this.props.history.push('/dashboard'); this.handleMenuClose(); }
  handleAccountClick = () =>  { this.props.history.push('/account/me'); this.handleMenuClose(); }
  handleLocationsClick = () => { this.props.history.push('/account/locations'); this.handleMenuClose(); }
  handleAdminClick = () => { this.props.history.push('/admin'); this.handleMenuClose(); }
  handleLinkClick = (link) => { this.handleAppMenuClose(); this.props.history.push(link); }

  render() {
    const { anchorEl, mobileMoreAnchorEl, appMenuAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isAppMenuOpen = Boolean(appMenuAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleDashboardClick}>Dashboard</MenuItem>
        <MenuItem onClick={this.handleAccountClick}>My Account</MenuItem>
        {isAdmin() && <MenuItem onClick={this.handleAdminClick}>Admin</MenuItem>}
        <Divider />
        <MenuItem onClick={this.handleSignUserOut}>Sign Out</MenuItem>
      </Menu>
    );
    const renderAppMenu = (
      <Popover
        anchorEl={appMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isAppMenuOpen}
        onClose={this.handleAppMenuClose}
      >
        <div style={{ width: 300, padding: 5 }} onClick={(e) => e.stopPropagation()}>
          <Grid container justify='center' spacing={1}>
            <Grid item xs={12}>
              <div className={`${classes.box}`} onClick={this.handleDashboardClick}>
                <div className={classes.homeText}><span role='img'>🚀</span> Mission Control</div>
              </div>
            </Grid>
          </Grid>
          <Grid container justify='center' spacing={1}>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/i')}>
                <div className={classes.iconText}><WidgetsIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Inventory</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/vendors')}>
                <div className={classes.iconText}><LocalShippingIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Vendors</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/orders')}>
                <div className={classes.iconText}><AssignmentIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Orders</div>
              </div>
            </Grid>
          </Grid>
          <Grid container justify='center' spacing={1}>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/customer')}>
                <div className={classes.iconText}><GroupsIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Customer</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/sales')}>
                <div className={classes.iconText}><ReceiptIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Sales</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.box} onClick={() => this.handleLinkClick('/dashboard/menu')}>
                <div className={classes.iconText}><MenuBookIcon style={{ fontSize: 36 }} /></div>
                <div className={classes.optionText}>Menu</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Popover>
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{backgroundColor: Colors.primaryColor}}>
          <Toolbar>
            <div>
                <div style={{width:100, height:60, paddingTop:16, float:'left', marginRight:50, cursor:'pointer'}} onClick={this.handleDashboardClick}>
                    <img className="header-logo" style={{width:'auto', height:45}} src={KyleLogoIcon} alt={"logo"}/>
                </div>
            </div>
            
            <div className={classes.grow} />

              {/*<IconButton
                aria-owns={isAppMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleAppMenuOpen}
                color="inherit"
              >
                <AppsIcon style={{ fontSize: 24 }}/>
              </IconButton>*/}
              <Button
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
                style={{ textTransform : 'none' }}
              >
                <AccountCircle style={{ fontSize: 24, marginRight: 4 }}/> {this.getFullName()}
              </Button>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderAppMenu}
      </div>
    );
  }
}

InternalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InternalHeader);