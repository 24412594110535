import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {AppBar, Button, Toolbar, Menu, MenuItem, IconButton, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';

import ExternalHeaderStore from '../../stores/external/ExternalHeaderStore';
import ExternalHeaderActions from '../../actions/external/ExternalHeaderActions';

import KyleLogoImg from '../../assets/logo/bck-logo-white.png' // relative path to image
import Colors from "../../constants/Colors";

const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        display:'none'
      },
    },
    list: {
        width: 250,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display:'none'
        },
    },
    menuTextButton: {
        textTransform:'none',
        fontSize:15,
        fontFamily:'Open Sans, sans-serif',
        color: '#000'
    },
    title: {
        display: 'block',
    },
    nav: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 3,
        marginTop: 10,
        width: 'auto',
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
    },
    button: {
        textTransform:'none',
        fontSize:15,
        fontFamily:'Open Sans, sans-serif',
        borderColor:'#fff',
        borderWidth: 1,
        color: '#fff'
    },
    textButton: {
        textTransform:'none',
        fontSize:15,
        fontFamily:'Open Sans, sans-serif',
        color: '#fff'
    },
    item: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif'
    },
    navItem: {
        minWidth: theme.spacing.unit * 1,
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:18,
        padding:5,
        color: '#FFFFFF',
        cursor: 'pointer',
        '&:hover': {
            color: '#FFAD0A'
        },
        textAlign:'center',
        height: '100%',
        position: 'relative',
        display: 'flex',
        float:'left',
        marginRight:20
    },
   
    navItemActive: {
        fontWeight:'bold',
        color: Colors.lightColor
   },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    mobileMenu: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    desktopMenu: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
});

class ExternalHeader extends Component {

    constructor(props) {
        super(props)
        this.externalHeaderStore = ExternalHeaderStore;
        this.externalHeaderActions = ExternalHeaderActions;
        this.state = {
            anchorEl: undefined,
        }
    }

    componentDidMount () {
        this.externalHeaderActions.updateLocation(this.props.history.location.pathname);
    }

    componentDidUpdate() {
        this.externalHeaderActions.updateLocation(this.props.history.location.pathname);
    }

    handleShowDrawer = (open) => {
        this.externalHeaderActions.handleShowDrawer(open);
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: undefined });
    };

    handleServicesOnClick = () => {
        window.location.href = 'https://consultwithkyle.com/#services';
    }

    handleContactOnClick = () => {
        window.location.href = 'https://consultwithkyle.com/contact';
    }

    handleMyAccountOnClick = (e) => {
        e.stopPropagation();
        if(this.props.history.location.pathname !== '/login') {
            this.props.history.push('/login');
        }
    }

    handleRegisterOnClick = (e) => {
        e.stopPropagation();
        if(this.props.history.location.pathname !== '/register') {
            this.props.history.push('/register');
        }
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;

        const renderMenu = (
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={anchorEl !== undefined}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.handleServicesOnClick} className={classes.menuTextButton}>SERVICES</MenuItem>
              <MenuItem onClick={this.handleContactOnClick} className={classes.menuTextButton}>CONTACT</MenuItem>
              <MenuItem onClick={this.handleMyAccountOnClick} className={classes.menuTextButton}>MY ACCOUNT</MenuItem>
            </Menu>
          );

        return (
            <div className={classes.root}>
                <AppBar className="css-transition" position="fixed" elevation={3} style={{backgroundColor: Colors.primaryColor, height: 70}}>
                    <Toolbar>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                        <div style={{width:100, height:60, paddingTop:16, marginRight:50, cursor:'pointer'}} onClick={this.handleHomeOnClick}>
                            <img className="header-logo" style={{width:'auto', height:45}} src={KyleLogoImg} alt={"logo"}/>
                        </div>
                        <div style={{height:60, paddingTop:16}} className={classes.desktopMenu}>
                            <Button
                                variant="text"
                                className={classes.textButton}
                                onClick={this.handleServicesOnClick}
                            >
                                <span style={{paddingLeft: 5, paddingRight: 5}}>SERVICES</span>
                            </Button>
                            <Button
                                variant="text"
                                className={classes.textButton}
                                onClick={this.handleContactOnClick}
                            >
                                <span style={{paddingLeft: 5, paddingRight: 5}}>CONTACT</span> 
                            </Button>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                onClick={this.handleMyAccountOnClick}
                                style={{marginLeft: 10 }}
                            >
                                <span style={{paddingLeft: 10, paddingRight: 10}}>MY ACCOUNT</span>
                            </Button>
                            
                        </div>
                        <div style={{height:60, paddingTop:16}} className={classes.mobileMenu}>
                            <IconButton
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            >
                                <MenuIcon style={{ fontSize: 24 }}/>
                            </IconButton>
                        </div>
                    </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                </div>
        );
    }
};

export default withStyles(styles)(withRouter(observer(ExternalHeader)));
