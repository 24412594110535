export const COMPANY_TYPES = [
  {
    value: 'SP',
    label: 'Sole Proprietorship',
  },
  {
    value: 'GP',
    label: 'General Partnership',
  },
  {
    value: 'LP',
    label: 'Limited Partnership',
  },
  {
    value: 'LLP',
    label: 'Limited Liability Partnership',
  },
  {
    value: 'LLC',
    label: 'Limited Liability Company (LLC)',
  },
  {
    value: 'INC',
    label: 'Corporation',
  },
  {
    value: 'CCORP',
    label: 'Corporation - C Corp',
  },
  {
    value: 'SCORP',
    label: 'Corporation - S Corp',
  },
  {
    value: 'BCORP',
    label: 'Corporation - B Corp',
  },
  {
    value: 'PC',
    label: 'Professional Corporation (P.C.)',
  },
  {
    value: 'NFP',
    label: 'Nonprofit',
  },
  {
    value: 'NONE',
    label: 'Not Yet Structured (TBD)',
  },
  {
    value: 'IDK',
    label: "I Don't Know",
  },
  {
    value: 'OTHER',
    label: "Unknown Business Structure",
  }
];