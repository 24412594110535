import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, Tooltip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, Delete, Edit, ExpandMore, HelpOutline, Payment, Send, Update } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite } from "../../../actions/internal/SiteActions";
import { getUserId, isAdmin } from "../../../actions/profile/UserActions";
import { deleteJob, getJob, sendProposalResponse, updateJobStatus } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { FINAL_JOB_STATUSES, JOB_STATUS, JOB_STATUS_MAP } from "../../../constants/Statuses";
import Styles from "../../../constants/Styles";
import ConfirmationModal from "../modals/ConfirmationModal";
import RejectJobModal from "../modals/RejectJobModal";
import AcceptProjectModal from '../modals/AcceptProjectModal';
import EditJobStatusModal from '../modals/EditJobStatusModal';

export const REQUEST_ACTIONS = {
    SUBMIT_APPROVAL: 'SUBMIT_APPROVAL',
    APPROVE_PROPOSAL: 'APPROVE_PROPOSAL',
    REJECT_PROPOSAL: 'REJECT_PROPOSAL'
  }

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    actionOutlinedButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        borderColor: Colors.redColor,
        fontWeight:'bold',
        color: Colors.redColor,
        height: 50,
        marginRight: 8
    },
    rejectButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color: Colors.redColor,
        height: 50,
        marginRight: 8
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const ACTION_REQUIRED_JOB_TITLES = {
    'PENDING_APPROVAL': 'This project requires approval to begin.'
}

const ReviewSiteJobComponent = ({ classes, history, location, match }) => {

    const jobRef = useRef([])
    const [ siteData, setSiteData ] = useState();
    const [ isLoading, setIsLoading ] = useState();
    const [ isApproving, setIsApproving ] = useState(false);
    const [ showRejectionModal, setShowRejectionModal ] = useState(false);
    const [ showApprovalModal, setShowApprovalModal ] = useState(false);

    const { job_details, job_quote, job_metadata, job_hours, job_security } = jobRef.current || {};

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        const job_id = params.job_id;
        let site = state && state.site ? state.site : undefined;
        const site_job = state && state.site_job ? state.site_job : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        loadData(site_id, job_id, site)
        if (site === undefined || site_job === undefined) loadData(site_id, job_id);
        else {
            jobRef.current = site_job;
            setSiteData(site);
        }
    }

    const loadData = async (site_id, job_id, site) => {
        setIsLoading(true);
        try {
            let loadedSite = site;
            if (!loadedSite) {
                loadedSite = await getSite(site_id)
            }
            if (loadedSite === undefined) {
                history.push('/dashboard');
                return;
            }
            const site_job = await getJob(site_id, job_id, getUserId(), isAdmin(), isAdmin())
            if (site_job === undefined || site_job.length === 0) { history.goBack(); return; }
            if (site_job.job_details.job_status !== JOB_STATUS_MAP.PENDING) {
                history.replace(`/dashboard/site/${loadedSite.site_id}/projects/${site_job.job_id}`);
            }
            jobRef.current = site_job;
            setSiteData(loadedSite);
            setIsLoading(false);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        }
    }

    const handleApproveJob = async () => {
        try {
            setIsApproving(true)
            const updatedJob = await sendProposalResponse(jobRef.current.job_id, getUserId(), {}, REQUEST_ACTIONS.APPROVE_PROPOSAL)
            jobRef.current = updatedJob;
            NotificationBarActions.showNotificationBar('success', 'This project has been approved!');
            setShowApprovalModal(false)
        } catch(err) {
            NotificationBarActions.showNotificationBar('error', 'A problem has occurred while approving this project. Please try again later.');
        } finally {
            setIsApproving(false)
        }
    }

    const handleRejectJob = async (reason) => {
        try {
            setShowRejectionModal(false)
            setIsApproving(true)
            const updatedJob = await sendProposalResponse(jobRef.current.job_id, getUserId(), { status_change_reason: reason }, REQUEST_ACTIONS.REJECT_PROPOSAL)
            jobRef.current = updatedJob;
            NotificationBarActions.showNotificationBar('info', 'This project has been rejected.');
        } catch(err) {
            NotificationBarActions.showNotificationBar('error', 'A problem has occurred while approving this project. Please try again later.');
        } finally {
            setIsApproving(false)
        }
    }

    const renderDescription = (description) => description.split('\n').reduce((acc, val)=> [...acc, <div>{val}</div>],[])

    const renderQuoteSection = ({quote_amount_due_at_start, quote_amount_due_at_complete, quote_amount, quote_items }) => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                        <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Units</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody >
                            {quote_items !== undefined && quote_items.map((item) => (
                                <TableRow
                                key={item.item_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                                >
                                    <TableCell component="th" scope="row" style={{ fontSize: 16 }}>{item.item_name}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>{item.item_units}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>${(item.item_unit_cost / 100).toFixed(2)}</TableCell>
                                    <TableCell align="right" style={{ fontSize: 16 }}>${(item.item_total / 100).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16, color: Colors.darkGreyText }}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right', color: Colors.darkGreyText }}>
                                ${(quote_amount/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Start
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount_due_at_start/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Completion
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount_due_at_complete/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 18 }}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18, marginRight: 14, textAlign: 'right' }}>
                                ${(quote_amount/100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderHourlySection = ({ hours_estimated_total }, site) => {
        let hourly_rate = -1;
        if (site && site.site_service_modules && site.site_service_modules.consulting) {
            hourly_rate = site.site_service_modules.consulting.general_consulting.pricing.default
        }

        return hours_estimated_total === -1 ?
        (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project will be billed by incurred hours. The incurred hours will be charged at the base rate of <b>${(hourly_rate/100).toFixed(2)}</b> per hour.
                    </Typography>
                </Grid>
            </Grid>
        )
        :
        (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Table sx={{ minWidth: 650, fontSize: 12, paddingLeft: 0, paddingRight: 0 }} size="small" aria-label="a dense table">
                        <TableHead sx={{paddingLeft: 0, paddingRight: 0, fontSize: 16 }}>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Units</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12}}
                            >
                                <TableCell component="th" scope="row" style={{ fontSize: 16 }}>Consulting Hour (Base Rate)</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>{hours_estimated_total}</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>${(hourly_rate / 100).toFixed(2)}</TableCell>
                                <TableCell align="right" style={{ fontSize: 16 }}>${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16, color: Colors.darkGreyText }}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right', color: Colors.darkGreyText }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Start
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                $0.00
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                Due At Completion
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 16, marginRight: 14, textAlign: 'right' }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={6}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container justify='flex-end'>
                        <Grid item xs={'auto'}>
                            <Typography variant="caption" component="h2" style={{ fontSize: 18 }}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1" component="h2" style={{ fontSize: 18, marginRight: 14, textAlign: 'right' }}>
                                ${((hourly_rate * hours_estimated_total)/ 100).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderNoCostSection = () => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project is <b>free</b>! 🎉
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const renderProBonoSection = ({ hours_estimated_total }) => {

        return (
            <Grid container justify='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" component="h2"  style={{ fontSize: 18  }}>
                        This project will incurr hours at no cost to you. { hours_estimated_total !== -1 ? `The estimated amount of hours for this project is ${hours_estimated_total}.` : ' There is no estimation on the amount of hours this project will take.' }
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details, site_id } = siteData ? siteData : {};
    
    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='space-between'>
                        <Grid item xs='auto'>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'} style={{ paddingLeft: 20 }}>
                                    <Typography variant="h6" component="h3">
                                        Project Proposal Review
                                    </Typography>
                                    <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                        Please review the project proposal below and choose an action below. If you have any questions, please let us know.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        <Paper style={{ marginBottom: 16 }} variant='outlined'>
                            {job_security && job_security.is_hidden &&
                            <div style={{ backgroundColor: Colors.warningBackground, marginBottom: 0, padding: 8, borderTopLeftRadius: 3, borderTopRightRadius: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={classes.subtitleText} style={{ fontSize: 16, margin: 0, textAlign: 'center'  }}>This project is hidden.</div>
                                    </Grid>
                                </Grid>
                            </div>
                            }
                            <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 24 }}>
                                <Grid container justify='space-between' spacing={1}>
                                    <Grid item xs={'auto'}>
                                        <Typography variant="h5" component="h3">
                                            {job_details.job_name}
                                        </Typography>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            <i>Created On {new Date(job_metadata.created_at).toLocaleDateString()}</i>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title={JOB_STATUS.find(status => status.value === job_details.job_status).description}>
                                            <Chip
                                                style={{ marginTop: 10, cursor: 'pointer' }}
                                                label={JOB_STATUS.find(status => status.value === job_details.job_status).label}
                                                variant="outlined"
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginTop: 5, marginBottom: 18 }} />
                                <Grid container justify='flex-start' spacing={2} style={{ minHeight: '10vh' }}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" component="h2" style={{ marginTop: -5, fontSize: 16 }}>
                                            Project Outline
                                        </Typography>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 18 }}>
                                            {renderDescription(job_details.job_description)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider style={{ marginTop: 5, marginBottom: 18 }} />
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <Typography variant="caption" component="h2" style={{ fontSize: 16 }}>
                                            Pricing Breakdown
                                        </Typography>
                                        {job_details.job_payment_structure === 'QUOTE' && renderQuoteSection(job_quote || {})}
                                        {job_details.job_payment_structure === 'HOURLY' && renderHourlySection(job_hours, siteData)}
                                        {job_details.job_payment_structure === 'NO_COST' && renderNoCostSection(job_quote)}
                                        {job_details.job_payment_structure === 'PRO_BONO' && renderProBonoSection(job_hours)}
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Grid container justify='flex-end' spacing={2}>
                            <Grid item xs={'auto'}>
                                <Button
                                    className={classes.rejectButton}
                                    disabled={isApproving || isLoading}
                                    onClick={() => setShowRejectionModal(true) }
                                    
                                >
                                    REJECT
                                </Button>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Button
                                    variant="contained"
                                    className={classes.actionButton}
                                    disabled={isLoading}
                                    onClick={() => setShowApprovalModal(true)}
                                >
                                    { isApproving ?
                                        <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                        : <span>APPROVE</span>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <RejectJobModal open={showRejectionModal} onCancel={() => setShowRejectionModal(false)} onReject={handleRejectJob} />
                    <AcceptProjectModal
                        open={showApprovalModal}
                        onCancel={() => setShowApprovalModal(false)}
                        onAccept={handleApproveJob}
                        project={jobRef.current}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ReviewSiteJobComponent);