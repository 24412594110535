import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography, withStyles } from "@material-ui/core"
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { getSiteHoursReport } from "../../../../actions/internal/SiteActions";
import Colors from "../../../../constants/Colors";
import { getCurrentMonthName, getCurrentYear } from "../../../../utilities/DateUtilities";
import ViewHoursModal from "../../modals/ViewHoursModal";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    height: '100%'
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    fontSize:18,
    fontFamily:'Nunito Sans, sans-serif',
    textTransform: 'none'
  },
 });

const CurrentSiteHoursCard = ({classes, history, site}) => {

  const hoursData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [ showHours, setShowHours ] = useState();
  
  useEffect(() => {
    loadData();
  }, [site])

  const handleCloseModal = () => {
    setShowHours(false)
  }

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      const hours = await getSiteHoursReport(site.site_id)
      hoursData.current = hours;
    } catch (ex) {
      setError('Could not load hours!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const renderContent = () => isLoading ? (
    <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
  ) : (
    <React.Fragment>
      {error ?
        <Typography color='error' variant="h5" component="h2">
          {error}
        </Typography> : 
        <Typography variant="h6" component="h2">
          {hoursData.current.report.current_hours.total_hours.toFixed(1)} Hours
        </Typography>}
      <Typography className={classes.pos} color="textSecondary">
        for {getCurrentMonthName()} {getCurrentYear()}
      </Typography>
    </React.Fragment> 
  );

  return (
    <Grid item md={3} sm={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Consulting Time
          </Typography>
          {renderContent()}
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setShowHours(true)} className={classes.button}>Details</Button>
          <Button
            size="small"
            onClick={() => history.push(`/dashboard/site/${site.site_id}/hours`, { site })}
            className={classes.button}
          >View</Button>
        </CardActions>
      </Card>
      <ViewHoursModal
        hours={hoursData.current ? hoursData.current.report.current_hours.time_entries : []} 
        onClose={handleCloseModal}
        open={showHours}
      />
    </Grid>
  )
}

export default withStyles(styles)(CurrentSiteHoursCard);