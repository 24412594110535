import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ExpandMore, KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite } from "../../../actions/internal/SiteActions";
import { getUserId, isAdmin } from "../../../actions/profile/UserActions";
import { getJobs } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { ACTION_REQUIRED_JOB_STATUSES, ACTIVE_JOB_STATUSES, INACTIVE_JOB_STATUES, JOB_STATUS } from "../../../constants/Statuses";
import Styles from "../../../constants/Styles";

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    actionButtonOutlined: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        borderColor: Colors.primaryColor,
        color: Colors.primaryColor,
        fontWeight:'bold',
        height: 40
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const ViewSiteJobsComponent = ({ classes, history, location, match }) => {

    const jobsRef = useRef([])
    const [ siteData, setSiteData ] = useState();
    const [ isLoading, setIsLoading ] = useState();

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const handleShowBookingPage = () => {
        const win = window.open('https://consultwithkyle.com/book', '_blank');
        win.focus();
    }

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        const site_jobs = state && state.site_jobs ? state.site_jobs : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined || site_jobs === undefined) loadData(site_id);
        else {
            jobsRef.current = sortJobs(site_jobs);
            setSiteData(site);
        }
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
          const site = await getSite(site_id);
          if (site === undefined) {
            history.push('/dashboard');
            return;
          }
          const site_jobs = await getJobs(site_id, getUserId());
          jobsRef.current = sortJobs(site_jobs);
          setSiteData(site);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    const sortJobs = (jobs) => {
        return ({
            DRAFT: jobs.filter(job => job.job_details.job_status === 'DRAFT'),
            ACTIVE: jobs.filter(job => ACTIVE_JOB_STATUSES.includes(job.job_details.job_status)),
            INACTIVE: jobs.filter(job => INACTIVE_JOB_STATUES.includes(job.job_details.job_status)),
            ACTION_REQUIRED: jobs.filter(job => ACTION_REQUIRED_JOB_STATUSES.includes(job.job_details.job_status)),
            COMPLETED: jobs.filter(job => job.job_details.job_status === 'COMPLETED')
        })
    }

    const renderAttentionSection = () => (
        <React.Fragment>
            <div style={{ padding: 16, marginTop: 16 }}>
                <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                    Pending Your Action
                </Typography>
                <Paper style={{ borderColor: 'rgba(250, 141, 0,0.75)', borderStyle: 'solid', borderWidth: 5 }} variant='outlined'>
                    <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Grid container justify='flex-start' spacing={2}>
                            <Grid item xs={12}>
                                <List className={classes.root}>
                                    {ACTION_REQUIRED && ACTION_REQUIRED.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!ACTION_REQUIRED || ACTION_REQUIRED.length === 0 && 
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2">
                                            No Projects At This Time...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </div>
        </React.Fragment>
        
    )

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details, site_id } = siteData ? siteData : {};
    const { ACTION_REQUIRED, ACTIVE, INACTIVE, DRAFT, COMPLETED } = jobsRef.current || {};

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='space-between' spacing={1}>
                        <Grid item xs='auto'>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'}>
                                    <IconButton onClick={() => history.goBack()}>
                                        <KeyboardBackspace />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6" component="h2">
                                        Projects
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {isAdmin() && <Grid item xs='auto'>
                            <Button
                            style={{ marginRight: 16}}
                                variant="contained"
                                className={classes.actionButton}
                                disabled={isLoading}
                                onClick={() => history.push(`/admin/jobs/add?site_id=${match.params.site_id}`)}
                            >
                               Add
                            </Button>   
                        </Grid>}
                    </Grid>
                    {ACTION_REQUIRED.length > 0 && renderAttentionSection()}
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                        <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                            Active Projects
                        </Typography>
                        <Paper style={{ marginBottom: 28 }} variant='outlined'>
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                <Grid container justify='flex-start' spacing={2}>
                                    <Grid item xs={12}>
                                        
                                        <List className={classes.root}>
                                            {ACTIVE && ACTIVE.map((job) => (
                                                <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                                    <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                                </ListItem>
                                            ))}
                                            {!ACTIVE || ACTIVE.length === 0 && 
                                                <Grid container style={{ margin: 18 }} justify='space-between'>
                                                    <Grid item xs="auto">
                                                        <Typography variant="body2" component="h2">
                                                            No Active Projects...
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Button
                                                            style={{ marginRight: 16}}
                                                            variant="outlined"
                                                            className={classes.actionButtonOutlined}
                                                            onClick={handleShowBookingPage}
                                                        >
                                                            Start A New Project
                                                        </Button> 
                                                    </Grid>
                                                </Grid>
                                            }
                                        </List>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <div style={{ marginLeft: '40%', width: '20%', height: 1, backgroundColor: Colors.darkGreyText, marginBottom: 14 }}></div>
                        <Typography variant='h6' component='h2' style={{ paddingLeft: 4, fontSize: 18, marginBottom: 16 }}>
                            Other Projects
                        </Typography>
                        <Accordion style={{ display: isAdmin() ? 'default' : 'none', marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Draft Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {DRAFT && DRAFT.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!DRAFT || DRAFT.length === 0 && 
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Drafts Projects...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Completed Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {COMPLETED && COMPLETED.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!COMPLETED || COMPLETED.length === 0 && 
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Completed Projects Yet...
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        {INACTIVE !== undefined && INACTIVE.length > 0 && <Accordion style={{ marginBottom: 8 }} variant='outlined'>
                            <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography variant='h6' component='h2' style={{fontSize: 18 }}>
                            Inactive Projects
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: { display: 'block' } }}>
                                <List style={{ flex: 1 }}>
                                    {INACTIVE && INACTIVE.map((job) => (
                                        <ListItem button dense onClick={() => history.push(`/dashboard/site/${site_id}/projects/${job.job_id}`)} key={job.job_id}>
                                            <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
                                        </ListItem>
                                    ))}
                                    {!INACTIVE || INACTIVE.length === 0 && 
                                        <React.Fragment>
                                        <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                                            No Inactive Projects
                                        </Typography>
                                        </React.Fragment>
                                    }
                                </List>
                            </AccordionDetails>
                        </Accordion> }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ViewSiteJobsComponent);