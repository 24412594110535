import NotificationBarStore from "../../stores/error-pages/NotificationBarStore";

const NotificationBarActions = {
    showNotificationBar(variant = 'info', message = 'Something happened') {
        NotificationBarStore.showBar(variant, message);
    },
    resetNotificationBar() {
        NotificationBarStore.setShowBar(false);
        NotificationBarStore.setMessage(undefined);
    }
}

export default NotificationBarActions;