import { Card, CardContent, CircularProgress, Grid, IconButton, Typography, Tooltip as MaterialTooltip, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowDownwardOutlined, ArrowDownwardSharp, ArrowUpwardOutlined, ArrowUpwardSharp, HourglassEmptyOutlined, InfoOutlined, KeyboardBackspace } from "@material-ui/icons";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite } from '../../../actions/internal/SiteActions';
import { getSiteWebsiteReport } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import Styles from "../../../constants/Styles";
import GenericInfoCard from "./cards/GenericInfoCard";
import WelcomeToBetaCard from "./cards/WelcomeToBetaCard";

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    uptimeContent: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
      footer: {
        marginBottom: 32,
        textAlign: 'center',
        fontSize: 16
      },
    container: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const fillerData = [
    {visitors: 0, visitors_last_week: 0, name: 'Sun'},
    {visitors: 0, visitors_last_week: 0, name: 'Mon'},
    {visitors: 0, visitors_last_week: 0, name: 'Tues'},
    {visitors: 0, visitors_last_week: 0, name: 'Wed'},
    {visitors: 0, visitors_last_week: 0, name: 'Thurs'},
    {visitors: 0, visitors_last_week: 0, name: 'Fri'},
    {visitors: 0, visitors_last_week: 0, name: 'Sat'},
   ]

const COLORS = [Colors.primaryColor, Colors.purpleThemeColor, '#FFBB28', '#FF8042'];
const ACTIONS_INFO = "Actions are interactions users take on a website. For example, a reply button or a link, when clicked, is an action.";
const AVG_TIME_INFO = "This metric shows the average amount of minutes a user visits your website.";
const UPTIME_INFO = "This is live status of your website that shows if, or if not, your site is accessible to the world wide web.";

const ViewSiteStatsComponent = ({ classes, history, location, match }) => {

    const websiteData = useRef({});

    const [ siteData, setSiteData ] = useState();
    const [ isLoading, setIsLoading ] = useState();

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        const site = state && state.site ? state.site : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        loadData(site_id)
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
            const site = await getSite(site_id);
            if (site === undefined) {
                history.push('/dashboard');
                return;
            }
            setSiteData(site);
            const website = await getSiteWebsiteReport(site.site_id)
            websiteData.current = website;
            // setHoursReport(report)
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the website statistics at this time.');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    const prepareData = () => {
        return websiteData.current.report.visitors.timeline.map((stat) => ({
          visitors: stat.count,
          visitors_last_week: stat.court_past,
          name: stat.date_string,
      })).reverse();
    }

    const preparePieData = () => {
        return Object.keys(websiteData.current.report.device_usage).map((key) => ({
          value: websiteData.current.report.device_usage[key],
          name: key,
      })).reverse();
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes < 10 ? '0' + String(minutes) : String(minutes)}:${seconds < 10 ? '0' + String(seconds) : String(seconds)}`
    }

    const renderPercentageSection = (current_number, past_number) => {
        const isNegative = current_number < past_number;
        const color = isNegative ? Colors.redColor : Colors.greenColor;
        let percentChange = current_number - past_number;
        if (isNegative) percentChange *= -1;
        percentChange = percentChange / past_number * 100;
        return renderPercentageIcon(percentChange, isNegative)
    }

    const renderPercentageIcon = (percentage, isNegative = false) => {
        const color = isNegative ? Colors.redColor : Colors.greenColor;
        return (
            <span style={{ color }}>{isNegative ? <ArrowDownwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> : <ArrowUpwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> } {(percentage).toFixed(1)}% </span>
        )
    }
       

    const renderChartCard = (title, chart, shrink = false) => (
        <Grid item xs={12} lg={shrink && 6}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography style={{ fontSize: 14, marginBottom: 28 }} color="textSecondary" gutterBottom>
                    {title}
                </Typography>
                    {chart}
                </CardContent>
            </Card>
        </Grid>
    )

    const renderVisitorChart = () => isLoading ? (
    <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
    ) : (
    <React.Fragment>
        <Grid item xs={11} className={classes.graphGrid}>
        <ResponsiveContainer width="100%" height={250}>
            <LineChart width={800} height={300} data={websiteData.current && websiteData.current.report.visitors ? prepareData() : fillerData}>
            <Line type="linear" dataKey="visitors" stroke={Colors.primaryColor} strokeWidth={4} />
            <Line type="linear" dataKey="visitors_last_week" stroke={Colors.purpleThemeColor} strokeWidth={2}/>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" style={{ fontSize: 14 }}/>
            <YAxis />
            <Tooltip formatter={(value, name, props) => { return [`${value}`, `${capitalize(name.replace('_',' ').replace('_',' '))}`] }} />
            </LineChart>
        </ResponsiveContainer>
        </Grid>
    </React.Fragment> 
    );

    const renderUptimeCard = (shrink = false) => {
        if (isLoading || !websiteData.current.report.uptime_report) return;
        let displayIcon;
        switch(websiteData.current.report.uptime_report.status) {
            case 'UP':
                displayIcon = <ArrowUpwardOutlined style={{ color: Colors.primaryColor, fontSize: 128 }} />
                break;
            case 'DOWN':
                displayIcon = <ArrowDownwardOutlined style={{ color: Colors.redColor, fontSize: 128 }} />
                break;
            default:
                displayIcon = <HourglassEmptyOutlined style={{ color: Colors.warningBackground, fontSize: 128 }} />
                break;
        }

        return (
        <Grid item xs={12} lg={shrink && 6}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Grid container justify='space-between'>
                        <Grid item xs={'auto'}>
                            <Typography style={{ fontSize: 14, marginBottom: 28 }} color="textSecondary" gutterBottom>
                                Uptime Guard
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <MaterialTooltip title={UPTIME_INFO}>
                                <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
                            </MaterialTooltip>
                        </Grid>
                    </Grid>
                    <Typography className={classes.uptimeContent} variant="h5" component="h2">
                        {displayIcon}
                    </Typography>
                    <Typography className={classes.uptimeContent} variant="h5" component="h2">
                        {websiteData.current.report.uptime_report.status}
                    </Typography>
                    {websiteData.current.report.uptime_report.status === 'UP' ?
                        <Typography className={classes.footer} color="textSecondary">
                            {Number(websiteData.current.report.uptime_report.custom_uptime_ratio).toFixed(1)}% for the past 30 days.
                        </Typography>
                        :
                        <Typography className={classes.footer} color="textSecondary">
                            Your website may be unavailable...
                        </Typography>
                    }
                </CardContent>
            </Card>
        </Grid>
        )

        return
    
    }

    const renderUsageChart = () => isLoading ? (
        <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
        ) : (
        <React.Fragment>
            <Grid item xs={11} className={classes.graphGrid}>
            <ResponsiveContainer width="100%" height={250}>
                <PieChart width={800} height={400}>
                    <Pie
                        data={preparePieData()}
                        // cx={120}
                        // cy={200}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={(props) => capitalize(props.name)}
                    >
                        {Object.keys(websiteData.current.report.device_usage).map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip formatter={(value, name, props) => { return [`${(value).toFixed(1)}%`, `${capitalize(name.replace('_',' ').replace('_',' '))}`] }} />
                </PieChart>
            </ResponsiveContainer>
            </Grid>
        </React.Fragment> 
        );

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    if (websiteData.current && (!websiteData.current.report || Object.keys(websiteData.current.report).length === 0)) {
        return (
        <div className={ classes.container }>
            <Grid container justify = 'center'>
                <Grid xs={11} md={8}>
                    <Paper style={{ padding: 32, marginTop: 64 }}>
                        <div style={{fontSize:32, textAlign: 'center', fontFamily:'Nunito Sans, sans-serif', color: Colors.defaultDarkText}}>Nothing To See Here... (yet)</div>
                        <div style={{fontSize:18, textAlign: 'center', fontFamily:'Nunito Sans, sans-serif', color: Colors.defaultDarkText}}>It looks like Activity has not been enabled for your account yet. Not to worry, just let us know that you are interested and we will be happy to help!</div>
                        <Grid container justify='center' style={{ marginTop: 28 }}>
                            <Grid xs={'auto'}>
                                <Button variant="outlined"  onClick={() => window.open('https://consultwithkyle.com/contact', '_blank')} style={{ textTransform: 'none', fontFamily:'Nunito Sans, sans-serif'}}>
                                    <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 16, color: Colors.defaultText}}>Contact Us</span>
                                </Button>
                            </Grid>
                            <Grid xs={'auto'}>
                                <Button variant="outlined"  onClick={() => {history.goBack()}} style={{ textTransform: 'none', fontFamily:'Nunito Sans, sans-serif', marginLeft: 10 }}>
                                    <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 16, color: Colors.defaultText}}>Go Back</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
          )
    } 

    return (
        <div className={ classes.container }>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='flex-start' spacing={1}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Activity Dashboard<span style={{ fontSize: 14, fontStyle: 'italic' }}> BETA</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: 28 }}>
                        <Grid item lg={3} md={4} xs={12} >
                            <Grid container spacing={1}>
                                <GenericInfoCard title={"Visitors"} content={websiteData.current.report.visitors.count} subcontent={renderPercentageSection(websiteData.current.report.visitors.count, websiteData.current.report.visitors.count_past)} footer="for the past 7 days"/>
                                <GenericInfoCard title={"Actions"} content={websiteData.current.report.actions.count} subcontent={renderPercentageSection(websiteData.current.report.actions.count, websiteData.current.report.actions.count_past)} footer="for the past 7 days" info={ACTIONS_INFO}/>
                                <GenericInfoCard title={"Average Visit Time"} content={formatTime(websiteData.current.report.time_average.count)} subcontent={renderPercentageSection(websiteData.current.report.time_average.count, websiteData.current.report.time_average.count_past)} footer="minutes per user" info={AVG_TIME_INFO}/>
                            </Grid>
                        </Grid>
                        <Grid item lg={9} md={8} xs={12} >
                            <Grid container spacing={1}>
                                {renderChartCard("Page Views", renderVisitorChart())}
                                {renderChartCard("Device Usage", renderUsageChart(), true)}
                                {renderUptimeCard(true)}
                                <WelcomeToBetaCard />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(ViewSiteStatsComponent);

/*

*/