import React, { useState } from "react";

import { Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography, withStyles } from "@material-ui/core";
import { useEffect, useRef } from "react";
import Colors from "../../../../constants/Colors";
import ViewInvoiceModal from "../../modals/ViewInvoiceModal";
import { getSitePaymentReport } from "../../../../actions/internal/SiteActions";

const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    height: '100%'
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    fontSize:18,
    fontFamily:'Nunito Sans, sans-serif',
    textTransform: 'none'
  },
 });

const CurrentSitePaymentsCard = ({classes, history, site}) => {

  const paymentData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [ showInvoices, setShowInvoices ] = useState(false);
  
  useEffect(() => {
    loadData();
  }, [])

  const handleCloseModal = () => {
    setShowInvoices(false);
  }

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      const payment = await getSitePaymentReport(site.site_id)
      paymentData.current = payment;
      if (!payment.report || !payment.report.open_invoices) {
        setError('No Data.')
      }
    } catch (ex) {
      setError('Could not load payment data!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const renderContent = () => isLoading ? (
    <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
  ) : (
    <React.Fragment>
      {error ?
        <Typography color='error' variant="h5" component="h2">
          {error}
        </Typography> : 
        <Typography variant="h6" component="h2">
          ${(paymentData.current.report && paymentData.current.report.open_invoices) ? (paymentData.current.report.open_invoices.invoice_amount_due/100).toFixed(2) : 'No Data.'}
        </Typography>}
        {error === undefined &&
          <Typography className={classes.pos} color="textSecondary">
            {paymentData.current.report.open_invoices.invoice_amount_due > 0 ? 'is currently due.' : "You're all caught up!"}
          </Typography>
        }
      
    </React.Fragment> 
  );

  return (
    <Grid item md={6} sm={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Open Invoices
          </Typography>
          {renderContent()}
        </CardContent>
        <CardActions>
         {paymentData.current && error === undefined && paymentData.current.report.open_invoices.invoice_amount_due > 0 && <Button className={classes.button} size="small" onClick={() => setShowInvoices(true)}>Pay</Button>}
          <Button className={classes.button} size="small" onClick={() => history.push(`/dashboard/site/${site.site_id}/invoices`, { site, stripe_invoice_report: paymentData.current })}>View</Button>
        </CardActions>
      </Card>
      <ViewInvoiceModal open={showInvoices} onClose={handleCloseModal} invoices={paymentData.current && error === undefined ? paymentData.current.report.open_invoices.invoices : []} />
    </Grid>
  )
}

export default withStyles(styles)(CurrentSitePaymentsCard);