import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Paper, Grid, Button, TextField, CircularProgress} from '@material-ui/core';
import Colors from '../../../constants/Colors';
import background from '../../../assets/backgrounds/brick-background.jpeg';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import { isValidEmail } from '../../../utilities/Validation';
import { logProfileIn } from '../../../clients/profile/ProfileClient';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { getCurrentUser, setUserFromCognito } from '../../../actions/profile/UserActions';
import { useEffect } from 'react';
import { SESSION_REDIRECT_KEY, getSessionProperty } from '../../../utilities/SessionUtilities';

const styles = theme => ({
 root: {
   width: '100%',
 },
 titleText: {
    fontSize:44,
    color: Colors.primaryColor,
    fontFamily:'Rock Salt, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    fontStyle:'italic',
    marginTop:30,
    [theme.breakpoints.up('sm')]: {
        textAlign:'center',
        fontSize:56
    },
  },
  subtitleText: {
    fontSize:16,
    textAlign: 'center',
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'center',
        fontSize:18
    },
  },
  textField: {
    marginTop: 5,
    marginBottom: 5
  },
  button: {
    marginTop: 50,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.primaryColor,
    fontWeight:'bold',
    color: Colors.lightColor,
    height: 'auto'
  },
  textButton: {
    marginBottom: 50,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    fontWeight:'bold',
    color: Colors.darkColor,
    height: 'auto'
  },
    imageStyle: {
      opacity: 0.6,
      height: '100%',
      width:'auto',
  },
  mobile: {
      [theme.breakpoints.up('md')]: {
          display: 'none'
      },
      backgroundColor: '#fff'
  },
  desktop: {
      [theme.breakpoints.down('sm')]: {
          display: 'none'
      },
      marginTop: 5
  },
});

const LoginForm = ({ classes, history }) => {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkAndRestoreSession()
  }, [])

  const checkAndRestoreSession = async () => {
    try {
      const loggedInUser = await getCurrentUser();
      if (loggedInUser !== undefined) {
        history.push('/dashboard');
      }
    } catch (err) { /* do nothing */ }
  }

  const isValidForm = () => {
    const foundErrors = [];
    let reportedError;
    if (!username || isEmpty(username) || !isValidEmail(username)) {
       foundErrors.push('username');
       reportedError = 'Please provide a valid user name.'
    }
    if (!password || isEmpty(password)) {
       foundErrors.push('password');
       if (!reportedError) reportedError = 'Please provide a password'
    }
    setErrors(foundErrors);
    setErrorMessage(reportedError)
    return foundErrors.length === 0;
  }

  const handleRegisterOnClick = (e) => {
    e.stopPropagation();
    history.push('/register');
  }

  const handleForgotPasswordOnClick = (e) => {
    e.stopPropagation();
    history.push('/reset-password');
  }
  
  const handleSignIn = async () => {
    if (!isValidForm()) return;
    setIsLoading(true)
    await logProfileIn(username, password)
    .then((result) => {
      setUserFromCognito(result);
      const redirect = getSessionProperty(SESSION_REDIRECT_KEY, true)
      if (redirect) history.push(redirect);
      else history.push('/dashboard')
    })
    .catch((err) => {
      if (err.name === 'NotAuthorizedException' || err.name === 'UserNotFoundException') {
        setErrors(['password'])
        setErrorMessage('The username and password combination is incorrect.')
      } else if(err.name === 'UserNotFoundException') {
        setErrors(['username'])
        setErrorMessage('This username is not registered. Create an account by clicking the button below.')
      } else if(err.name === 'UserNotConfirmedException') {
        history.push({
          pathname: '/verify',
          state: { user: { email: username, password }}
        });
      } else {
        NotificationBarActions.showNotificationBar('error', 'Unable to log in at this time. Please try again in a few minutes.');
        console.error('error', err.name, err.message)
      }
    }).finally(() => setIsLoading(false));
  }

  const renderLoginContent = () => (
    <>
      <Grid container justify='center'>
        <Grid item xs={11}>
          <div className={classes.titleText}>Hello!</div>
        </Grid>
        <Grid item xs={11}>
          <div className={classes.subtitleText}>{errorMessage ? <span style={{ color: Colors.redColor }}>{errorMessage}</span> : 'Sign in to continue to the Dashboard.'}</div>
        </Grid>
      </Grid>
      <Grid container justify='center'>
        <Grid item xs={11} md={10}>
            <TextField
              autoFocus
              error={errors.includes('username')}
              onKeyPress={(e) => {if (e.key === 'Enter') { handleSignIn();}}}
              fullWidth
              value={username}
              className={classes.textField}
              margin="normal"
              type="text"
              label="E-Mail"
              variant="outlined"
              onChange={(e) => setUsername(e.target.value)}
            />
        </Grid>
      </Grid>
      <Grid container justify='center'>
        <Grid item xs={11} md={10}>
            <TextField
              fullWidth
              error={errors.includes('password')}
              onKeyPress={(e) => {if (e.key === 'Enter') { handleSignIn();}}}
              value={password}
              className={classes.textField}
              margin="normal"
              label="Password"
              variant="outlined"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={'auto'}>
          <Button variant="contained" className={classes.button} onClick={handleSignIn} disabled={isLoading}>
            { isLoading ?
              <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
              : <span style={{paddingLeft: 30, paddingRight: 30, fontSize: 18}}>Sign In</span>
            }
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginTop: 10 }}>
        <Grid item xs={'auto'}>
          <Button variant="text" className={classes.textButton} onClick={handleForgotPasswordOnClick} disabled={isLoading}>
              <span style={{paddingLeft: 16, paddingRight: 16, fontSize: 16}}>Forgot Password</span>
          </Button>
        </Grid>
        <Grid item xs={'auto'}>
          <Button variant="text" className={classes.textButton} onClick={handleRegisterOnClick} disabled={isLoading}>
              <span style={{paddingLeft: 16, paddingRight: 16, fontSize: 16}}>Create An Account</span>
          </Button>
        </Grid>
      </Grid>
    </>
  )

  return (
      <div>
          <div id="background-img" className={classes.desktop} style={{ backgroundColor: Colors.defaultDarkText, position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%' }}>
              <img src={background} alt={"logo"} className={classes.imageStyle} />
          </div>
          <div className={classes.desktop}>
            <Grid container justify='center' style={{ height: '100vh' }}>
              <Grid item xs={12} sm={11} md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  <Paper style={{ width: '100%', position: 'relative'}} variant='outlined'>
                    {renderLoginContent()}
                  </Paper>
                </Grid>
            </Grid>
          </div>
          <div className={classes.mobile}>
            <Grid container justify='center' style={{ height: '100vh', backgroundColor: '#fff' }}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                      {renderLoginContent()}
                  </Grid>
              </Grid>
          </div>
          
      </div>
  );
}

export default withStyles(styles)(LoginForm);