import React from "react";
import { Route, Switch } from 'react-router-dom';

import { CircularProgress, Grid } from '@material-ui/core';

import { useEffect, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getUserId, isAdmin } from "../../../actions/profile/UserActions";
import { getAllSites, getAllUsers } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import ErrorPanel from "../../error-pages/ErrorPanel";
import GenericNotFound from "../../error-pages/GenericNotFound";
import AdminAddSiteComponent from "./AdminAddSiteComponent";
import AdminAddUserComponent from "./AdminAddUserComponent";
import AdminComponent from "./AdminComponent";
import AdminCreateSubscriptionComponent from "./AdminCreateSubscriptionComponent";
import AdminEditSubscriptionComponent from "./AdminEditSubscriptionComponent";
import AdminManageSiteComponent from "./AdminManageSiteComponent";
import AdminManageUserComponent from "./AdminManageUserComponent";
import AdminAddJobComponent from "./AdminAddJobComponent";
import AdminEditJobComponent from "./AdminEditJobComponent";
import AdminEditSiteComponent from "./AdminEditSiteComponent";
import AdminEditUserComponent from "./AdminEditUserComponent";

const AdminRouterV2 = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    const [sites, setSites] = useState()
    const [users, setUsers] = useState()

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        if (!isAdmin()) {
            NotificationBarActions.showNotificationBar('error', 'Unauthorized.');
            setIsLoading(false)
            setError(true)
            return;
        }

        try {
            const sites = await getAllSites(getUserId());
            const users = await getAllUsers(getUserId());

            setSites(sites)
            setUsers(users) 
        } catch(err) {
            console.error(err);
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }

        
        

    const loadingContent = (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>
    )

    const mainContent = !isLoading && !error ?
        <Switch>
            <Route exact path="/admin" render={routeProps => <AdminComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/users/add" render={routeProps => <AdminAddUserComponent {...routeProps} />} />
            <Route exact path="/admin/sites/add" render={routeProps => <AdminAddSiteComponent {...routeProps} users={users} />} />
            <Route exact path="/admin/sites/:site_id/edit" render={routeProps => <AdminEditSiteComponent {...routeProps} users={users} />} />
            
            <Route exact path="/admin/sites/:site_id" render={routeProps => <AdminManageSiteComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/jobs/add" render={routeProps => <AdminAddJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/sites/:site_id/jobs" render={routeProps => <AdminEditJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/sites/:site_id/jobs/:job_id/edit" render={routeProps => <AdminEditJobComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/users/:user_id" render={routeProps => <AdminManageUserComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/users/:user_id/edit" render={routeProps => <AdminEditUserComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/subscription/:site_id/create" render={routeProps => <AdminCreateSubscriptionComponent {...routeProps} sites={sites} users={users} />} />
            <Route exact path="/admin/subscription/:site_id/edit" render={routeProps => <AdminEditSubscriptionComponent {...routeProps} sites={sites} users={users} />} />
            <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
        :
        <ErrorPanel
            message={"Oh no! Something went wrong. Please check your internet connection and try again."}
        />;

    return (
        <>
            {isLoading ? loadingContent : mainContent}
        </>
    );
}

export default AdminRouterV2;