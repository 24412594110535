import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:20,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        [theme.breakpoints.down('sm')]: {
            fontSize:18,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const RejectJobModal = ({ classes, onCancel, onReject, open }) => {

    const [ reason, setReason ] = useState('');
    const [ errors, setErrors ] = useState([])

    const isValidForm = () => {
        const foundErrors = [];
        if (!reason || reason.trim() === '' ) foundErrors.push('reason');

        setErrors(foundErrors)
        return foundErrors.length === 0
      }

    const handleSubmitReject = () => {
        if (!isValidForm()) return;
        onReject(reason);
    }

    return (
            <Dialog
            aria-labelledby="rejection-modal"
            aria-describedby="Reject Proposal"
            open={open}
            onClose={onCancel}
            >
                <DialogTitle>
                    <Typography variant="subtitle1" component="h2">
                        Oh no! Are you sure?
                    </Typography>
                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                        We are sorry to see that this project proposal was not what you expected. If you could, please provide a little insight into why this decision was made. Any feedback will help us improve in the future.
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.textField}
                                error={errors.includes('reason')}
                                helperText={errors.includes('reason') ? 'Please provide a short reason of why you decided to reject this project. All feedback will stay confidential.' : "All feedback will stay confidential."}
                                margin="normal"
                                type="text"
                                multiline
                                value={reason}
                                rows={4}
                                label="* Rejection Reason"
                                variant="outlined"
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={handleSubmitReject} color="primary">
                        Reject
                    </Button>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
}

export default withStyles(styles)(RejectJobModal);