import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import { getUserId } from '../../../actions/profile/UserActions';
import { createNewSiteExpense } from '../../../clients/bettercallkyle/BckApi';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const AddNewExpenseModal = ({ classes, open, onClose, site }) => {

    const nameRef = useRef();
    const descriptionRef = useRef();
    const priceRef = useRef();

    const fileUploaderRef = useRef(null);

    const filePropsRef = useRef();
    
    const [fileName, setFileName] = useState();
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const handleClose = () => {
        onClose();
    }

    const handleAddDocumentRequest = (files) => {
        const file = files[0];
        const document_extention = file.name.substr(file.name.lastIndexOf('.'));
        const reader = new FileReader();
        setIsUploadingImage(true);
        reader.addEventListener("load", () => {
            let encoded_image = reader.result;
            encoded_image = encoded_image.substr(encoded_image.indexOf(',')+1);
            filePropsRef.current = {encoded_image, document_extention}
            setFileName(file.name)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleSave = async () => {
        if (!nameRef.current || !descriptionRef.current || isEmpty(nameRef.current) || isEmpty(descriptionRef.current) || isEmpty(priceRef.current)) {
            setError('Please provide valid information for all fields.')
            return;
        } 
        if (isNaN(priceRef.current)) {
            setError('Provide a valid price for this expense.')
            return;
        }
        const numericPrice = Math.floor(Number(priceRef.current) * 100);
        if (numericPrice < 0) {
            setError('The price cannot be negative.')
            return;
        }
        try {
            setIsLoading(true)
            const encoded_image = (filePropsRef.current && filePropsRef.current.encoded_image) || undefined
            const document_extention = (filePropsRef.current && filePropsRef.current.document_extention) || undefined
            const resp = await createNewSiteExpense(getUserId(), site.site_id, 
            { name: nameRef.current, description: descriptionRef.current, price: numericPrice },
            encoded_image, document_extention)
            NotificationBarActions.showNotificationBar('success', 'The expense has been added successfully.');
            handleClose();
        } catch(err) {
            console.error(err)
            NotificationBarActions.showNotificationBar('error', 'An error occurred while adding the expense.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            aria-describedby="add an expense to a client"
            open={open}
            onClose={handleClose}
            >
            <DialogTitle>
                Add Expense To Subscription
                <div className={classes.headerSubTextStyle}>This action is NOT recommended! This will add a one-time expense to the clients active subsctiption. If you would like to proceed, please provide the information and amount for the expense below.</div>
                {error !== undefined &&
                    <div className={classes.headerSubTextStyle} style={{ color: '#f90506' }}>{error}</div>}
            </DialogTitle>
            <DialogContent>
                {isLoading ?
                <Grid container justify="center" style={{padding: 100}}>
                    <Grid xs={'auto'} >
                        <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    </Grid>
                </Grid>
                :
                <Grid container justify="center">
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            id="name"
                            onChange={(e) => nameRef.current = e.target.value}
                            margin="normal"
                            label="Name"
                            inputProps={{
                                style: { textAlign: "left", fontWeight: 'bold' }
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            id="description"
                            onChange={(e) => descriptionRef.current = e.target.value}
                            margin="normal"
                            label="Description"
                            inputProps={{
                                style: { textAlign: "left", fontWeight: 'bold' }
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            id="price"
                            onChange={(e) => priceRef.current = e.target.value}
                            margin="normal"
                            label="Price (USD)"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            inputProps={{
                                style: { textAlign: "left", fontWeight: 'bold' }
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Button onClick={() => fileUploaderRef.current.click()}color="primary" variant='outlined'>
                            <span style={{ fontSize: 18, textTransform: 'none' }}>{fileName ? 'Reselect Document' : 'Select Document'}</span>
                            <input type='file' accept=".pdf,.png,.jpg,.txt,.jpeg,.svg" id='multi' onChange={(e) => handleAddDocumentRequest(e.target.files)} ref={fileUploaderRef} style={{ display: 'none' }} />
                        </Button>
                        {fileName && <div className={classes.h4Text} style={{ fontWeight: 'normal' }}>{fileName}</div>}
                    </Grid>
                </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button 
                className={classes.buttonStyle}
                disabled={isLoading}
                onClick={handleSave} color="primary">
                    Submit
                </Button>
            </DialogActions>
            </Dialog>
    )
}

export default withStyles(styles)(AddNewExpenseModal);