import React from 'react';

import { Avatar, Button, Grid, Popover, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupsIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PropTypes from 'prop-types';
import { isAdmin, signUserOut } from '../../actions/profile/UserActions';
import KyleLogoIcon from '../../assets/logo/bck-logo-white.png'; // relative path to image
import Colors from '../../constants/Colors';
import { Assessment, AssessmentOutlined, Assignment, AssignmentOutlined, BackspaceOutlined, Store, Storefront } from '@material-ui/icons';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontFamily:'Nunito Sans, sans-serif',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sandboxBanner: {
    fontSize:16,
    paddingTop: 3,
    paddingBottom: 3,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.warningBackground,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  outlinedButton: {
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.primaryColor,
  },
  box: {
    borderRadius: 10,
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: Colors.lightGreyColor
    }
  },
  homeText: {
    fontSize:24,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  optionText: {
    fontSize:20,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 5
  },
  iconText: {
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 5,
  },
  textButton: {
    textTransform: 'none'
  }
});

class InternalSiteHeader extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,appMenuAnchorEl:null,
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, mobileMoreAnchorEl: null, appMenuAnchorEl: null });
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleAppMenuOpen = event => {
    this.setState({ appMenuAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleAppMenuClose = () => {
    this.setState({ appMenuAnchorEl: null });
  };

  handleSignUserOut = () => {
    signUserOut();
    this.handleAppMenuClose();
    this.props.history.push('/');
  };

  handleDashboardClick = () =>  { this.props.history.push('/dashboard'); this.handleMenuClose(); }
  handleAccountClick = () =>  { this.props.history.push('/account/me'); this.handleMenuClose(); }
  handleLocationsClick = () => { this.props.history.push('/account/locations'); this.handleMenuClose(); }
  handleAdminClick = () => { this.props.history.push('/admin'); this.handleMenuClose(); }
  handleLinkClick = (link) => { this.handleAppMenuClose(); this.props.history.push(link); }

  render() {
    const { anchorEl, mobileMoreAnchorEl, appMenuAnchorEl } = this.state;
    const { classes, site, history } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isAppMenuOpen = Boolean(appMenuAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleDashboardClick}>Dashboard</MenuItem>
        <MenuItem onClick={this.handleAccountClick}>My Account</MenuItem>
        {isAdmin() && <MenuItem onClick={this.handleAdminClick}>Admin</MenuItem>}
        <Divider />
        <MenuItem onClick={this.handleSignUserOut}>Sign Out</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.sectionDesktop}>
        <AppBar position="fixed" style={{backgroundColor: '#fff', top: 76}}>
          <Toolbar>
            <Grid container justify='space-between' direction='row'>
              <Grid item xs='auto'>
                <Grid container>
                  {site && site.site_details && site.site_details.site_img_url ?
                    <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} />
                  :
                    <Avatar><Store /></Avatar>
                  }
                  <Typography variant="h6" color="inherit" component="div" style={{ marginLeft: 15, color: '#000', cursor: 'pointer' }}
                    onClick={() => this.handleLinkClick(`/dashboard/site/${site && site.site_id}`)}
                  >
                    {site && site.site_details.site_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs='auto'>
                <Grid container direction='row'>
                  <Grid item xs={'auto'}>
                      <Button
                          variant="text"
                          className={classes.textButton}
                          onClick={() => history.push(`/dashboard/site/${site.site_id}/activity`)}
                      >
                          <AssessmentOutlined /><span style={{paddingLeft: 5, paddingRight: 5, color: '#000'}}>Activity</span>
                      </Button>
                  </Grid>
                  <Grid item xs={'auto'}>
                      <Button
                          variant="text"
                          className={classes.textButton}
                          onClick={() => history.push(`/dashboard/site/${site.site_id}/projects`)}
                      >
                          <AssignmentOutlined /> <span style={{paddingLeft: 5, paddingRight: 5}}>Projects</span> 
                      </Button>
                  </Grid>
                  <Grid item xs={'auto'}>
                      <Button
                          variant="text"
                          className={classes.textButton}
                          onClick={() => history.push(`/dashboard/site/${site.site_id}/account`)}
                      >
                          <Storefront /> <span style={{paddingLeft: 5, paddingRight: 5}}>Business</span> 
                      </Button>
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
            
          </Toolbar>
        </AppBar>
        {renderMenu}
      </div>
    );
  }
}

InternalSiteHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InternalSiteHeader);