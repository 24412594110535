export const getMonthName = (monthIndex) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
}

export const getCurrentMonthName = (epoch = Date.now()) => {
    const d = new Date(epoch);
    return getMonthName(d.getMonth());
}

export const getCurrentYear = (epoch = Date.now()) => {
    const d = new Date(epoch);
    return d.getFullYear();
}
