import { Redirect } from "react-router";
import { Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardBackspace } from "@material-ui/icons";
import { find } from "lodash";
import React, { useRef } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import CurrentSitePaymentsCard from "../account/cards/CurrentSitePaymentsCard";
import AdminExternalConnectionsCard from "./cards/AdminExternalConnectionsCard";
import AdminSiteDetailsCard from "./cards/AdminSiteDetailsCard";
import AdminSiteSubscriptionCard from "./cards/AdminSiteSubscriptionCard";
import AdminSiteUsersDetailsCard from "./cards/AdminSiteUsersDetailsCard";
import WebsiteStatsCard from "../account/cards/WebsiteStatsCard";
import AdminJobsCard from "./cards/AdminJobsCard";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    }
})

const AdminManageSiteComponent = ({ classes, sites, users, match, history }) => {

    const siteRef = useRef(find(sites, {site_id: match.params.site_id}));

    if (siteRef.current === undefined) {
        NotificationBarActions.showNotificationBar('error', `Could not find Site ID: ${match.params.site_id}`);
        return <Redirect to='/admin' />
    } 

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                {siteRef.current ? siteRef.current.site_details.site_name : 'Unknown'}
                            </Typography>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                Manage Site Account
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <CurrentSitePaymentsCard site={siteRef.current} history={history} />
                    </Grid>
                    <Grid container spacing={2}>
                        {/* <CurrentSiteHoursCard site={siteRef.current} history={history} /> */}
                        <AdminSiteDetailsCard history={history} site={siteRef.current} users={users} />
                        <AdminSiteUsersDetailsCard history={history} site={siteRef.current} users={users} />
                        <AdminSiteSubscriptionCard history={history} site={siteRef.current} />
                        <WebsiteStatsCard history={history} site={siteRef.current} />
                        <AdminExternalConnectionsCard history={history} site={siteRef.current} />
                        <AdminJobsCard history={history} site={siteRef.current} />
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminManageSiteComponent);