import React from "react";
import { Card, CardContent, Grid, List, ListItem, ListItemText, Typography, withStyles } from "@material-ui/core";
import Colors from "../../../../constants/Colors";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminUserSitesDetailsCard = ({classes, history, user, sites}) => {

  return (
    <Grid item md={6} xs={12}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Accessible Sites
          </Typography>
          <Typography variant="h6" component="h2">
            User Sites
          </Typography>
          <List className={classes.root}>
            {sites && sites.filter((site) => site.site_security.accessible_user_ids.includes(user.user_id)).map((site) => (
              <ListItem button onClick={() => history.push(`/admin/sites/${site.site_id}`)} key={site.site_id}>
                <ListItemText primary={site.site_details.site_name} secondary={site.site_security.primary_user_id === user.user_id ? 'Primary Account Holder' : 'Basic Access'} />
              </ListItem>
            ))}
            {(!sites || sites.length === 0) && 
              <React.Fragment>
                <Typography variant="body1" component="h2">
                  No Accounts Open Yet...
                </Typography>
              </React.Fragment>
            }
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(AdminUserSitesDetailsCard);