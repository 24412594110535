import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography, withStyles } from "@material-ui/core";
import { AccessTimeOutlined, DeveloperBoardOutlined, EmailOutlined, LoyaltyOutlined, MoreVert } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { getSiteSubscription } from "../../../../actions/internal/SiteActions";
import { getUserId } from "../../../../actions/profile/UserActions";
import Colors from "../../../../constants/Colors";
import IconTextSection from "../../../common/IconTextSection";
import AddNewExpenseModal from "../../modals/AddNewExpenseModal";
import EditConsultingHoursModal from "../../modals/EditConsultingHoursModal";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminSiteSubscriptionCard = ({classes, history, site}) => {

  const subscriptionData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [ showAddExpense, setShowAddExpense ] = useState();
  const [ showEditConsulting, setShowEditConsulting ] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  useEffect(() => {
    loadData()
  }, [site])

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      if (site.site_external_connections.stripe === undefined) {
        setIsLoading(false);
        return;
      }
      const subscription = await getSiteSubscription(getUserId(), site.site_id)
      subscriptionData.current = subscription;
    } catch (ex) {
      setError('Could not load hours!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderConsultingContent = () => {
    const consulting = site.site_service_modules.consulting;
    const generalConsulting = consulting && consulting.general_consulting;
    const developmentConsulting = consulting && consulting.development_consulting;
    return (
      <React.Fragment>
          <IconTextSection style={{ marginTop: 16 }} icon={<AccessTimeOutlined />} primaryText={'General Consulting'} secondaryText={generalConsulting ? `$${(generalConsulting.pricing.default/100).toFixed(2)}/hr` : <i>No Hourly Price Set</i>} />
          <IconTextSection style={{ marginTop: 16 }} icon={<DeveloperBoardOutlined />} primaryText={'Development Consulting'} secondaryText={developmentConsulting ? `$${(developmentConsulting.pricing.default/100).toFixed(2)}/hr` : <i>No Hourly Price Set</i>} />
      </React.Fragment>
    )
  }

  const renderStripeAccountContent = () => (<React.Fragment>
      {!subscriptionData.current ?
        <Typography color='error' variant="body1" component="h2">
          No Stripe Account
        </Typography> : 
        <React.Fragment>
          <IconTextSection icon={<EmailOutlined />} primaryText={'Billing Email'} secondaryText={subscriptionData.current.stripe_customer.email ? subscriptionData.current.stripe_customer.email : <i>No Email</i>} />
        </React.Fragment>}
    </React.Fragment> 
  );

  const renderSubscriptionItems = () => !subscriptionData.current.stripe_subscription ? (
      <Typography color='error' variant="body1" component="h2" style={{ textAlign: 'center', border: '1px solid #fc0000', marginTop: 10, padding: 16, borderRadius: 2}}>
        No Stripe Subscription
      </Typography>
  ) : (
    <div style={{marginTop: 16 }}>
      <IconTextSection icon={<LoyaltyOutlined />} primaryText={'Subscription Items'} />
      <List>
        {subscriptionData.current.stripe_subscription.items.data.map((item) => (
          <ListItem dense key={item.id}>
            <ListItemText primary={item.product ? item.product.name : 'No Product Found...'} secondary={item.price.nickname ? item.price.nickname : 'No Price Name'}/>
            <ListItemSecondaryAction>
              <Typography variant="caption" component="h2">
                ${(item.price.unit_amount * item.quantity / 100).toFixed(2)}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div> 
  );

  const renderMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      {!subscriptionData.current || !subscriptionData.current.stripe_subscription ?
       <MenuItem onClick={() => { history.push(`/admin/subscription/${site.site_id}/create`); handleCloseMenu(); }}>Add Subscription</MenuItem>
       :
       <MenuItem onClick={() => { history.push(`/admin/subscription/${site.site_id}/edit`); handleCloseMenu(); }}>Edit Subscription</MenuItem>
      }
      <MenuItem onClick={() => { setShowEditConsulting(true); handleCloseMenu() }}>Edit Consulting Details</MenuItem>
      <MenuItem onClick={() => { setShowAddExpense(true); handleCloseMenu(); }}><i>Add Expense (Deprecated)</i></MenuItem>
    </Menu>
  )

  return (
    <Grid item xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardHeader
          action={
              <IconButton aria-label="settings" onClick={handleMoreClick}>
                  <MoreVert />
                </IconButton>
          }
          title="Subscription Overview"
        />
        <CardContent>
          {isLoading && <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />}
          {!isLoading && renderStripeAccountContent()}
          {!isLoading && subscriptionData.current && renderSubscriptionItems()}
          {!isLoading && renderConsultingContent()}
          
        </CardContent>
      </Card>
      {renderMenu()}
      <AddNewExpenseModal open={showAddExpense} onClose={() => setShowAddExpense(false)} site={site} />
      <EditConsultingHoursModal open={showEditConsulting} onClose={() => setShowEditConsulting(false)} site={site} />
    </Grid>
  )
}

export default withStyles(styles)(AdminSiteSubscriptionCard);