import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getSite } from '../../../actions/internal/SiteActions';
import Colors from "../../../constants/Colors";
import Styles from "../../../constants/Styles";
import ActiveJobsCard from './cards/ActiveJobsCard';
import CurrentSiteHoursCard from "./cards/CurrentSiteHoursCard";
import CurrentSitePaymentsCard from "./cards/CurrentSitePaymentsCard";
import HelpfulLinksCard from "./cards/HelpfulLinksCard";
import SiteDetailsCard from "./cards/SiteDetailsCard";
import WebsiteStatsCard from "./cards/WebsiteStatsCard";
import InternalSiteHeader from '../InternalSiteHeader';
import SiteDetailsCenterViewComponent from './views/SiteDetailsCenterViewComponent';

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
      },
    root: {
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
})

const WebsiteViewComponent = ({ classes, history, location, match }) => {

    const [ siteData, setSiteData ] = useState();
    const [ isLoading, setIsLoading ] = useState();

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined) loadData(site_id);
        else setSiteData(site);
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
          const site = await getSite(site_id);
          if (site === undefined) {
            history.push('/dashboard');
            return;
          }
          setSiteData(site);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details } = siteData ? siteData : {};
    return (
        <>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} sm={10} md={9}>
                        <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                            <Grid container justify='flex-start' spacing={2}>
                                <SiteDetailsCard site={siteData} history={history} />
                                {/* <CurrentSiteHoursCard site={siteData} history={history} /> */}
                                <CurrentSitePaymentsCard site={siteData} history={history} />
                                <SiteDetailsCenterViewComponent site={siteData} history={history} />
                                <HelpfulLinksCard site={siteData} history={history} />
                            </Grid>
                        </div>
                        
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default withStyles(styles)(WebsiteViewComponent);