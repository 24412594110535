import { observable, action, decorate } from "mobx";

class SitesStore {
    sites = undefined;
    site_hour_reports = {}
    site_invoice_reports = {}
    site_subscriptions = {}

    initialize() {
        this.sites = undefined;
        this.site_hour_reports = {};
        this.site_invoice_reports = {};
        this.site_subscriptions = {};
    }

    setSites(sites) {
        this.sites = sites;
    }

    setSiteHourReport(lookup_key, hour_report) {
        this.site_hour_reports[lookup_key] = hour_report;
    }

    setSiteInvoiceReport(lookup_key, invoice_report) {
        this.site_invoice_reports[lookup_key] = invoice_report;
    }

    setSiteSubscriptionReport(lookup_key, subscription) {
        this.site_subscriptions[lookup_key] = subscription;
    }
};

decorate(SitesStore, {
    sites: observable,
    site_hour_reports: observable,
    site_invoice_reports: observable,
    site_subscriptions: observable,

    setSites: action,
    setSiteHourReport: action,
    setSiteInvoiceReport: action,
    setSiteSubscriptionReport: action,
});

export default new SitesStore();
