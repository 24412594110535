import React from "react";

const IconTextSection = ({ icon, primaryText, secondaryText, style = {}, primaryTextStyle = {}, secondaryTextStyle = {}}) => (
 
  <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 5,
      ...style
  }}>
    <div style={{ paddingTop: 3}}>
      {icon}
    </div>
    <div style={{ marginLeft: 18, fontFamily:'Nunito Sans, sans-serif', maxWidth: '100%'}}>
      <div style={{ fontSize: 24, ...primaryTextStyle }}>{primaryText}</div>
      <div style={{ ...secondaryTextStyle }}>{secondaryText}</div>
    </div>
  
  </div>
)

export default IconTextSection;