import React from "react";

import { Card, CardContent, Grid, Link, Typography, withStyles } from "@material-ui/core";
import Colors from "../../../../constants/Colors";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  linkStyling: {
    marginTop: 5,
    marginBottom: 5,
    cursor: 'pointer'
  }
 });

const HelpfulLinksCard = ({ classes, history, site }) => {

  const handleShowNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Convenience 
          </Typography>
          <Typography variant="h6" component="h2">
            Helpful Links
          </Typography>
          { site && site.site_external_connections && site.site_external_connections.box &&
            <div className={classes.linkStyling}>
              <Link onClick={() => handleShowNewTab(site.site_external_connections.box.box_url)}>Go To My Box</Link>
            </div>
          }
          <div className={classes.linkStyling}>
            <Link onClick={() => history.push(`/dashboard/site/${site.site_id}/invoices`, { site })}>Business Invoices</Link>
          </div>
          <div className={classes.linkStyling}>
            <Link onClick={() => handleShowNewTab('https://consultwithkyle.com/book')}>Book An Appointment</Link>
          </div>
          <div className={classes.linkStyling}>
            <Link onClick={() => handleShowNewTab('https://app.consultwithkyle.com/terms')}>Terms & Conditions</Link>
          </div>
          <div className={classes.linkStyling}>
            <Link onClick={() => handleShowNewTab('https://app.consultwithkyle.com/privacy')}>Privacy Policy</Link>
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(HelpfulLinksCard);