import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import Colors from '../../constants/Colors';

const styles = theme => ({
      outlinedButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.primaryColor,
        height: 35,
      },
})

class ErrorPanel extends Component {


    handleRefreshPage = () => {
        window.location.reload();
    }
    
    render() {
        const { message } = this.props;
        return (
            <Grid container justify='center'>
                <Grid item xs={11} sm={9} md={8} lg={7} style={{ borderWidth: 1, borderRadius: 10, borderColor: Colors.primaryColor, borderStyle: 'solid', marginTop: 150, padding: 20}}>
                    <Grid container justify='center'>
                        <Grid item xs={10}>
                            <div style={{ fontFamily:'Nunito Sans, sans-serif', color: Colors.defaultText, fontSize: 24, textAlign: 'center', width: '100%'}}>{message}</div>
                        </Grid>
                        <Grid item xs={'auto'} style={{ marginTop: 35}}>
                            <Button variant="outlined" className={this.props.classes.outlinedButton}  onClick={this.handleRefreshPage}>
                                <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 16, color: Colors.defaultText}}>Reload Page</span>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
};

export default withStyles(styles)(ErrorPanel);
