import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';

import '../../styles/internal/internal-styles.css';

import { CircularProgress, Grid } from '@material-ui/core';

import { getCurrentUser, signUserOut } from "../../actions/profile/UserActions";
import Colors from "../../constants/Colors";
import ErrorPanel from "../error-pages/ErrorPanel";
import GenericNotFound from "../error-pages/GenericNotFound";
import UserAccountComponent from "./account/UserAccountComponent";
import ViewSiteInvoicesComponent from "./account/ViewSiteInvoicesComponent";
import WebsiteViewComponent from "./account/WebsiteViewComponent";
import AdminRouterV2 from "./admin/AdminRouterV2";
import DashboardComponent from "./dashboard/DashboardComponent";
import InternalHeader from "./InternalHeader";
import ViewSiteHoursComponent from "./account/ViewSiteHoursComponent";
import ViewSiteStatsComponent from "./account/ViewSiteStatsComponent";
import ViewSiteJobsComponent from "./account/ViewSiteJobsComponent";
import ViewSiteJobComponent from "./account/ViewSiteJobComponent";
import InternalSiteHeader from "./InternalSiteHeader";
import { getSite } from "../../actions/internal/SiteActions";
import SiteAccountDetails from "./account/views/SiteAccountDetails";
import NotificationBarActions from "../../actions/error-pages/NotificationBarActions";
import ReviewSiteJobComponent from "./account/ReviewSiteJobComponent";

const InternalSiteRouter = ({ history, match, location }) => {

    const [ site, setSite ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined) loadData(site_id);
        else {
            setSite(site);
            setIsLoading(false);
        }
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
          const site = await getSite(site_id);
          if (site === undefined) {
            history.push('/dashboard');
            return;
          }
          setSite(site);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    const loadingContent = (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>
    )

    const mainContent = !isLoading && !error ?
        <Switch>
            <Redirect from="/dashboard/site/:site_id/statistics" to="/dashboard/site/:site_id/activity" />
            <Redirect from="/dashboard/site/:site_id/jobs" to="/dashboard/site/:site_id/projects" />
            <Redirect from="/dashboard/site/:site_id/jobs/:job_id" to="/dashboard/site/:site_id/projects/:job_id" />

            <Route exact path="/dashboard/site/:site_id" render={routeProps => <WebsiteViewComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/invoices" render={routeProps => <ViewSiteInvoicesComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/hours" render={routeProps => <ViewSiteHoursComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/activity" render={routeProps => <ViewSiteStatsComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects" render={routeProps => <ViewSiteJobsComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/account" render={routeProps => <SiteAccountDetails {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/jobs/:job_id" render={routeProps => <ViewSiteJobComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects/:job_id" render={routeProps => <ViewSiteJobComponent {...routeProps} />} />
            <Route exact path="/dashboard/site/:site_id/projects/:job_id/review" render={routeProps => <ReviewSiteJobComponent {...routeProps} />} />
            <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
        :
        <ErrorPanel
            message={"Oh no! Something went wrong. Please check your internet connection and try again."}
        />;
    return (
        <React.Fragment>
            <InternalSiteHeader history={history} site={site} />
            {isLoading ? loadingContent : mainContent}
        </React.Fragment>
    );
}

export default InternalSiteRouter;
