import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Button, Grid, Popover, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupsIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PropTypes from 'prop-types';
import { getUserObject, isAdmin, signUserOut } from '../../actions/profile/UserActions';
import KyleLogoIcon from '../../assets/logo/bck-logo-white.png'; // relative path to image
import Colors from '../../constants/Colors';
import { getSite } from '../../actions/internal/SiteActions';
import { TEST_MODE } from '../../constants/System';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    fontFamily:'Nunito Sans, sans-serif',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sandboxBanner: {
    fontSize:16,
    paddingTop: 3,
    paddingBottom: 3,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.warningBackground,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  outlinedButton: {
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.primaryColor,
  },
  box: {
    borderRadius: 10,
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: Colors.lightGreyColor
    }
  },
  homeText: {
    fontSize:24,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  optionText: {
    fontSize:20,
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 5
  },
  iconText: {
    fontFamily:'Nunito Sans, sans-serif',
    color: Colors.defaultText,
    width: '100%',
    textAlign: 'center',
    paddingTop: 5,
  },
});

const InternalHeaderV2 = ({ classes, history, location }) => {

  const [ anchorEl, setAnchorEl ] = useState();
  const [ isLoadingSite, setIsLoadingSite ] = useState();
  const [ siteInFocus, setSiteInFocus ] = useState();
  // const history = useHistory();

  useEffect(() => {
    const siteId = getSiteIdFromUrl(location.pathname)
    loadSite(siteId)
  }, [location])

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  };

  const handleMenuClose = () => {
    setAnchorEl(null)
  };

  const handleSignUserOut = () => {
    signUserOut();
    handleMenuClose();
    history.push('/');
  };

  const getFirstName = () => (
    getUserObject() && `${getUserObject().user_personal.first_name}`
  )

  const handleDashboardClick = () =>  { history.push('/dashboard'); handleMenuClose(); }
  const handleAccountClick = () =>  { history.push('/account/me'); handleMenuClose(); }
  const handleLocationsClick = () => { history.push('/account/locations'); handleMenuClose(); }
  const handleAdminClick = () => { history.push('/admin'); handleMenuClose(); }
  const handleLinkClick = (link) => { handleMenuClose(); history.push(link); }

  const loadSite = async (siteId) => {
    if (!siteId && siteInFocus) {
      setSiteInFocus(undefined)
      return
    } else if (!siteId) return;
    try {
      setIsLoadingSite(true)
      const site = await getSite(siteId)
      setSiteInFocus(site)
    } catch(err) {
      console.error(err)
    } finally {
      setIsLoadingSite(false)
    }
  }

  const getSiteIdFromUrl = (url) => {
    const lookFor = 'site/'
    const startIndex = url.indexOf(lookFor)
    if (startIndex < 0) return;
    let siteId = url.slice(startIndex + lookFor.length)
    let end = siteId.indexOf('/')
    if (end < 0) return siteId
    siteId = siteId.slice(0, siteId.indexOf('/'))
    return siteId;
  }

  const renderMenu = () => {
    return (<Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={anchorEl}
      onClose={handleMenuClose}
    >
      <div style={{ minWidth: '60vw' }} className={classes.sectionMobile}/>
      <Typography variant="h6" component="h2" style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 16 }} className={classes.sectionMobile}>
        {getFirstName()}
      </Typography>
      <Typography variant="h6" component="h2" style={{ paddingTop: 0, marginTop: -10, paddingLeft: 16, paddingRight: 16, fontSize: 20 }} className={classes.sectionMobile}>
        {siteInFocus && siteInFocus.site_details.site_name}
      </Typography>
      <Divider className={classes.sectionMobile} style={{ marginTop: 10, marginBottom: 10 }} />
      <MenuItem onClick={handleDashboardClick}>Dashboard</MenuItem>
      <MenuItem onClick={handleAccountClick}>My Account</MenuItem>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      { siteInFocus && <>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/activity`)} className={classes.sectionMobile}>Activity</MenuItem>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/projects`)} className={classes.sectionMobile}>Projects</MenuItem>
        <MenuItem onClick={() => handleLinkClick(`/dashboard/site/${siteInFocus.site_id}/account`)} className={classes.sectionMobile}>Business</MenuItem>
        <Divider className={classes.sectionMobile} style={{ marginTop: 10, marginBottom: 10 }}/>
      </>
      }
      
      {isAdmin() && <MenuItem onClick={handleAdminClick}>Admin</MenuItem>}
      <MenuItem onClick={handleSignUserOut}>Log Out</MenuItem>
    </Menu>)
  }


  return (
    <div className={classes.root}>
        <AppBar position="fixed" style={{backgroundColor: Colors.primaryColor}}>
          { TEST_MODE && <div style={{ position: 'fixed', top: 0, left: '50%', backgroundColor: '#f00', padding: 8, borderBottomLeftRadius: 8, borderBottomRightRadius: 8, fontSize: 12 }}>TEST MODE</div> }
          <Toolbar>
            <Grid container justify='space-between'>
              <Grid item='auto'>
                <div style={{width:100, height:60, paddingTop:16, float:'left', marginRight:50, cursor:'pointer'}} onClick={handleDashboardClick}>
                    <img className="header-logo" style={{width:'auto', height:45}} src={KyleLogoIcon} alt={"logo"}/>
                </div>
              </Grid>
              <Grid item='auto' style={{ marginTop: 16 }}>
                <div className={classes.sectionDesktop}>
                  <Button
                    aria-owns={anchorEl ? 'material-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    style={{ textTransform : 'none' }}
                  >
                    <AccountCircle style={{ fontSize: 24, marginRight: 4 }}/> {getFirstName()}
                  </Button>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                  >
                    <MenuIcon style={{ fontSize: 24, color: '#fff' }}/>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            
          </Toolbar>
        </AppBar>
        {renderMenu()}
      </div>
  )
}

InternalHeaderV2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(InternalHeaderV2));