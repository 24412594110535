import React, { useState } from "react";
import { Button, CircularProgress, Grid, IconButton, MenuItem, Paper, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardBackspace } from "@material-ui/icons";
import { isEmpty } from "lodash";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { createNewSite } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { COMPANY_TYPES } from "../../../constants/Common";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    textField: {
        marginTop: 0
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
    },
    titleText: {
        fontSize:44,
        color: Colors.primaryColor,
        fontFamily:'Rock Salt, sans-serif',
        textAlign:'center',
        fontWeight:'bold',
        fontStyle:'italic',
        marginTop:15,
        [theme.breakpoints.up('sm')]: {
            textAlign:'left',
            fontSize:56
        },
    },
    subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
    },
    sectionText: {
    fontSize:24,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    marginTop:15,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:24
    },
    }
})

const AdminAddSiteComponent = ({ classes, history, users }) => {

    const [companyName, setCompanyName ] = useState();
    const [companyStructure, setCompanyStructure ] = useState();
    const [companyAddressLine1, setCompanyAddressLine1 ] = useState();
    const [companyAddressLine2, setCompanyAddressLine2 ] = useState();
    const [companyCity, setCompanyCity] = useState();
    const [companyState, setCompanyState] = useState();
    const [companyZipCode, setCompanyZipCode] = useState();
    const [companyEin, setCompanyEin] = useState();
    const [primaryUser, setPrimaryUser] = useState();

    const [clockifyProject, setClockifyProject] = useState();
    const [isNewClockifyProject, setIsNewClockifyProject] = useState(false)

    const [stripeCustomer, setStripeCustomer] = useState();
    const [isNewStripeCustomer, setIsNewStripeCustomer] = useState(false)

    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const isValidForm = () => {
        const foundErrors = [];
        if (!primaryUser) foundErrors.push('primaryUser')
        if (!companyName || isEmpty(companyName)) foundErrors.push('companyName');
        if (!companyStructure || isEmpty(companyStructure)) foundErrors.push('companyStructure');
        if (!companyAddressLine1 || isEmpty(companyAddressLine1)) foundErrors.push('companyAddressLine1')
        if (!companyCity || isEmpty(companyCity)) foundErrors.push('companyCity')
        if (!companyState || isEmpty(companyState)) foundErrors.push('companyState')
        if (!companyZipCode || isEmpty(companyZipCode) || isNaN(companyZipCode) || Number(companyZipCode) < 10000 || Number(companyZipCode) > 99999 ) foundErrors.push('companyZipCode')
        if (!isNewClockifyProject && (!clockifyProject || isEmpty(clockifyProject))) foundErrors.push('clockifyProject');
        if (!isNewStripeCustomer && (!stripeCustomer || isEmpty(stripeCustomer))) foundErrors.push('stripeCustomer');

        setErrors(foundErrors)
        return foundErrors.length === 0
      }

    const handleCreateSite = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);

            const sitePayload = {
                site_name: companyName,
                site_structure: companyStructure,
                site_ein: companyEin,
                site_address: {
                  address_line_1: companyAddressLine1,
                  address_line_2: companyAddressLine2,
                  city: companyCity,
                  state: companyState,
                  zipcode: companyZipCode
                }
              }

            await createNewSite(
                getUserId(), primaryUser, sitePayload,
                isNewStripeCustomer ? undefined : stripeCustomer,
                isNewClockifyProject ? undefined : clockifyProject);
            NotificationBarActions.showNotificationBar('success', 'Site has been created');
            history.goBack();
        } catch(err) {
            const resp = await err;
            console.error(resp);
            NotificationBarActions.showNotificationBar('error', 'Could not create the site at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Add Site
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        <div style={{padding: 16}}>
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" component="h2">
                                        Required Information
                                    </Typography>
                                    <Typography variant="caption" component="h2" style={{ marginTop: -5 }}>
                                        Provide the necessary infomation to create the new account.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    select
                                    error={errors.includes('primaryUser')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Primary User"
                                    variant="outlined"
                                    onChange={(e) => setPrimaryUser(e.target.value)}
                                >
                                    {users.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {user.user_personal.first_name} {user.user_personal.last_name} <span style={{fontSize: 16, paddingLeft:5}}>({user.user_login_id})</span>
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Company Info</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    error={errors.includes('companyName')}
                                    fullWidth
                                    value={companyName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Company/Organization Name"
                                    variant="outlined"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    select
                                    error={errors.includes('companyStructure')}
                                    fullWidth
                                    className={classes.textField}
                                    label="*Business Structure"
                                    variant="outlined"
                                    onChange={(e) => setCompanyStructure(e.target.value)}
                                >
                                    {COMPANY_TYPES.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <TextField
                                    error={errors.includes('companyEin')}
                                    fullWidth
                                    value={companyEin}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Employer ID Number (EIN)"
                                    variant="outlined"
                                    onChange={(e) => setCompanyEin(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Legal Address</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Please provide your legal business address. If you do not yet have one, please provide your current address. This information is securely saved, and will never be shared externally.</div>
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    error={errors.includes('companyAddressLine1')}
                                    fullWidth
                                    value={companyAddressLine1}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Street Address"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine1(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    error={errors.includes('companyAddressLine2')}
                                    fullWidth
                                    value={companyAddressLine2}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="Unit/Apt/Suite"
                                    variant="outlined"
                                    onChange={(e) => setCompanyAddressLine2(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    autoFocus
                                    error={errors.includes('companyCity')}
                                    fullWidth
                                    value={companyCity}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*City"
                                    variant="outlined"
                                    onChange={(e) => setCompanyCity(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    autoFocus
                                    error={errors.includes('companyState')}
                                    fullWidth
                                    value={companyState}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*State"
                                    variant="outlined"
                                    onChange={(e) => setCompanyState(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <TextField
                                    autoFocus
                                    error={errors.includes('companyZipCode')}
                                    fullWidth
                                    value={companyZipCode}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Zip Code"
                                    variant="outlined"
                                    onChange={(e) => setCompanyZipCode(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Additional Info</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        error={errors.includes('clockifyProject')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="*Clockify Project ID"
                                        disabled={isNewClockifyProject}
                                        variant="outlined"
                                        onChange={(e) => setClockifyProject(e.target.value)}
                                    />
                                    <Switch onChange={(e) => setIsNewClockifyProject(e.target.checked)}/> <span style={{fontFamily:'Nunito Sans, sans-serif'}}>Create New Clockify Project</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        error={errors.includes('stripeCustomer')}
                                        fullWidth
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        label="*Stripe Customer ID"
                                        disabled={isNewStripeCustomer}
                                        variant="outlined"
                                        onChange={(e) => setStripeCustomer(e.target.value)}
                                    />
                                    <Switch onChange={(e) => setIsNewStripeCustomer(e.target.checked)}/> <span style={{fontFamily:'Nunito Sans, sans-serif'}}>Create New Stripe Customer Account</span>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                        <Button 
                    className={classes.buttonContainedStyle}
                    variant='contained'
                    onClick={handleCreateSite} color="primary">
                    { isLoading ?
                      <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                      : <span>Create Site</span>
                    }
                </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminAddSiteComponent);