import React, { useRef } from "react";
import { Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardBackspace } from "@material-ui/icons";
import { find } from "lodash";
import { Redirect } from "react-router";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import AdminUserDetailsCard from "./cards/AdminUserDetailsCard";
import AdminUserSitesDetailsCard from "./cards/AdminUserSitesDetailsCard";

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    }
})

const AdminManageUserComponent = ({ classes, sites, users, match, history }) => {

    const userRef = useRef(find(users, {user_id: match.params.user_id}));

    if (userRef.current === undefined) {
        NotificationBarActions.showNotificationBar('error', `Could not find User ID: ${match.params.user_id}`);
        return <Redirect to='/admin' />
    } 

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                {userRef.current ? `${userRef.current.user_personal.first_name} ${userRef.current.user_personal.last_name}` : 'Unknown User'}
                            </Typography>
                            <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                                Manage User Account
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <AdminUserDetailsCard history={history} user={userRef.current} />
                        <AdminUserSitesDetailsCard history={history} user={userRef.current} sites={sites} />
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminManageUserComponent);