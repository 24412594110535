import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Paper, Grid, Button, TextField, CircularProgress} from '@material-ui/core';
import Colors from '../../../constants/Colors';
import background from '../../../assets/backgrounds/brick-background.jpeg';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { isValidEmail } from '../../../utilities/Validation';
import { createNewUser, findUser } from '../../../clients/bettercallkyle/BckApi';
import { registerProfile } from '../../../clients/profile/ProfileClient';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';

const styles = theme => ({
 root: {
   width: '100%',
 },
 paperStyle: {
    width: '100%',
    position: 'relative'
},
 titleText: {
    fontSize:44,
    color: Colors.primaryColor,
    fontFamily:'Rock Salt, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    fontStyle:'italic',
    marginTop:15,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:56
    },
  },
  subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
  },
  textField: {
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit,
    textTransform:'none',
    fontSize:16,
    fontFamily:'Nunito Sans, sans-serif',
    backgroundColor: Colors.primaryColor,
    fontWeight:'bold',
    color: Colors.lightColor,
    height: 50
  },
  imageStyle: {
    opacity: 0.6,
    height: '100%',
    width:'auto',
},
textLink: {
  textDecoration: 'underline',
  color: Colors.primaryColor,
  cursor: 'pointer'
},
textButton: {
  marginBottom: 50,
  textTransform:'none',
  fontSize:16,
  fontFamily:'Nunito Sans, sans-serif',
  fontWeight:'bold',
  color: Colors.darkColor,
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
},
mobile: {
  [theme.breakpoints.up('md')]: {
      display: 'none'
  },
  backgroundColor: '#fff'
},
desktop: {
  [theme.breakpoints.down('sm')]: {
      display: 'none'
  },
  marginTop: 5
},
});

const RegistrationForm = ({ classes, history }) => {

  const [ firstName, setFirstName ] = useState();
  const [ lastName, setLastName ] = useState();
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState();
  const [ confirmPassword, setConfirmPassword ] = useState();

  const [ errors, setErrors ] = useState([]);
  const [ isLoading, setIsLoading ] = useState();

  const handleLoginOnClick = (e) => {
    e.stopPropagation();
    history.push('/login');
  }
  
  const handleShowTermsAndConditions = (event) => {
    var win = window.open('/terms', '_blank');
    win.focus();
  }
  
  const handleShowPrivacyPolicy = (event) => {
    var win = window.open('/privacy', '_blank');
    win.focus();
  }

  const isValidForm = () => {
    const foundErrors = [];
    if (!firstName || isEmpty(firstName)) foundErrors.push('firstName');
    if (!lastName || isEmpty(lastName)) foundErrors.push('lastName');
    if (!email || isEmpty(email) || !isValidEmail(email)) foundErrors.push('email');
    const capitalVal = (/[A-Z]/.test(password));
    const numberVal = (/\d/.test(password));
    if (!password || password.length < 8 || !capitalVal || !numberVal) foundErrors.push('password');
    if (!foundErrors.includes('password') && password !== confirmPassword) foundErrors.push('confirmPassword');
    setErrors(foundErrors);
    return foundErrors.length === 0; 
  }

  const handleRegister = async () => {

    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      let user = await findUser(email).catch((err) => { return; });
      if (!user || user.code === 'no-user-exists') {
        user = await createNewUser(firstName, lastName, email);
      }
      await registerProfile(email, password, firstName, lastName, user.user_id);
      history.push({
        pathname: '/verify',
        state: { user: { email, password }}
      });
    } catch (err) {
      if(err.code === 'UsernameExistsException') {
        setErrors(['email'])
        NotificationBarActions.showNotificationBar('error', 'This email has already been registered. Please try logging in.');
      } else {
        console.error(err);
        NotificationBarActions.showNotificationBar('error', 'Oh no! Something went wrong when creating the account. Please try again.');
      }
    } finally {
      setIsLoading(false)
    }
  }

  const renderRegistrationSection = () => (
    <div style={{ padding: 16}}>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12}>
            <div className={classes.titleText}>Welcome!</div>
            <div className={classes.subtitleText}>Simply provide some information below and let's get started.</div>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                error={errors.includes('firstName')}
                fullWidth
                value={firstName}
                className={classes.textField}
                disabled={isLoading}
                margin="normal"
                type="text"
                label="*First Name"
                variant="outlined"
                onChange={(e) => setFirstName(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                error={errors.includes('lastName')}
                value={lastName}
                className={classes.textField}
                disabled={isLoading}
                margin="normal"
                type="text"
                label="*Last Name"
                variant="outlined"
                onChange={(e) => setLastName(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                error={errors.includes('email')}
                value={email}
                className={classes.textField}
                disabled={isLoading}
                margin="normal"
                type="email"
                label="*E-Mail"
                variant="outlined"
                onChange={(e) => setEmail(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                error={errors.includes('password')}
                value={password}
                className={classes.textField}
                disabled={isLoading}
                margin="normal"
                type="password"
                label="*Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
              />
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                error={errors.includes('confirmPassword')}
                value={confirmPassword}
                className={classes.textField}
                disabled={isLoading}
                margin="normal"
                type="password"
                label="*Confirm Password"
                variant="outlined"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
          </Grid>
        </Grid>
        <Grid container style={{marginTop: 30}} justify='center' >
          <Grid item xs={12} sm={12}>
            <div className={classes.subtitleText}>By clicking 'I Agree & Continue' below you are accepting the <span
            className={classes.textLink}
            onClick={handleShowTermsAndConditions}
            >Terms and Conditions</span> and the <span
            className={classes.textLink}
            onClick={handleShowPrivacyPolicy}
            >Privacy Policy</span> of Better Call Kyle, LLC.</div>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={'auto'}>
            <Button variant="text" className={classes.textButton} onClick={handleLoginOnClick} disabled={isLoading} style={{ marginTop: 14 }}>
                <span style={{ fontSize: 16 }}>Sign In To Existing Account</span>
            </Button>
          </Grid>
          <Grid item xs={'auto'}>
            <Button variant="contained" className={classes.button} onClick={handleRegister} disabled={isLoading}>
              { isLoading ?
                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                : <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 18}}>I Agree & Continue</span>
              }
            </Button>
          </Grid>
        </Grid>
      </div>
  )

  return (
    <div>
      <div id="background-img" className={classes.desktop} style={{ backgroundColor: Colors.defaultDarkText, position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%' }}>
          <img src={background} alt={"logo"} className={classes.imageStyle} />
      </div>
      <div className={classes.desktop}>
        <Grid container justify='center' style={{ minHeight: '100vh' }}>
          <Grid item xs={12} sm={11} md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
              <Paper style={{ width: '100%', position: 'relative', marginTop: '100px'}} variant='outlined'>
                {renderRegistrationSection()}
              </Paper>
            </Grid>
        </Grid>
      </div>
      <div className={classes.mobile}>
        <Grid container justify='center' style={{ minHeight: '100vh', backgroundColor: '#fff', paddingTop: '70px', paddingBottom: '50px' }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  {renderRegistrationSection()}
              </Grid>
          </Grid>
      </div>
    </div>
  )
}

export default withStyles(styles)(RegistrationForm);