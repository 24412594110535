import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';

import '../../styles/internal/internal-styles.css';

import { CircularProgress, Grid } from '@material-ui/core';

import NotificationBarActions from "../../actions/error-pages/NotificationBarActions";
import { getCurrentUser, signUserOut } from "../../actions/profile/UserActions";
import Colors from "../../constants/Colors";
import ErrorPanel from "../error-pages/ErrorPanel";
import GenericNotFound from "../error-pages/GenericNotFound";
import UserAccountComponent from "./account/UserAccountComponent";
import AdminRouterV2 from "./admin/AdminRouterV2";
import DashboardComponent from "./dashboard/DashboardComponent";
import InternalHeaderV2 from "./InternalHeaderV2";
import InternalSiteRouter from "./InternalSiteRouter";
import { SESSION_REDIRECT_KEY, setSessionProperty } from "../../utilities/SessionUtilities";

const InternalRouter = ({ history, location, match }) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        loadSession();
    }, [])

    const loadSession = async () => {
        setIsLoading(true)
        try {
            const user = await getCurrentUser();
            if (!user) { 
                setSessionProperty(SESSION_REDIRECT_KEY, location.pathname)
                history.push('/login');
                return;
            }
        } catch(ex) {
            if (ex.code === 'UserNotFoundException') {
                signUserOut();
                history.push('/login');
                return;
            }
            const error = await ex;
            console.error(error);
            setError('Something went wrong!');
            NotificationBarActions.showNotificationBar('error', 'Oh no! A problem occurred, please try again later.');
            history.push('/login');
        } finally {
            setIsLoading(false)
        }
        
    }

    const loadingContent = (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>
    )

    const mainContent = !isLoading && !error ?
        <Switch>
            <Route path="/admin" render={routeProps => <AdminRouterV2 {...routeProps} />} />
            <Route exact path="/account/me" render={routeProps => <UserAccountComponent {...routeProps} />} /> {/* DEPRECATED ROUTE */}
            <Route exact path="/account" render={routeProps => <UserAccountComponent {...routeProps} />} />
            <Route exact path="/dashboard" render={routeProps => <DashboardComponent {...routeProps} />} />
            <Route path="/dashboard/site/:site_id" render={routeProps => <InternalSiteRouter {...routeProps} />} />
            <Route path="*" render={routeProps => <GenericNotFound {...routeProps} />} />
        </Switch>
        :
        <ErrorPanel
            message={"Oh no! Something went wrong. Please check your internet connection and try again."}
        />;
    return (
        <React.Fragment>
            <InternalHeaderV2 />
            {isLoading ? loadingContent : mainContent}
        </React.Fragment>
    );
}

export default InternalRouter;
