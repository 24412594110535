import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import { getUserId } from '../../../actions/profile/UserActions';
import { uploadSiteImage } from '../../../clients/bettercallkyle/BckApi';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left',
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const UpdateSiteImageModal = ({ classes, open, onClose, site }) => {

    const nameRef = useRef();
    const directoryRef = useRef();

    const fileUploaderRef = useRef(null);

    const filePropsRef = useRef();
    
    const [fileName, setFileName] = useState();
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const handleClose = () => {
        onClose();
    }

    const handleAddDocumentRequest = (files) => {
        const file = files[0];
        const document_extention = file.name.substr(file.name.lastIndexOf('.'));
        const reader = new FileReader();
        setIsUploadingImage(true);
        reader.addEventListener("load", () => {
            let encoded_image = reader.result;
            encoded_image = encoded_image.substr(encoded_image.indexOf(',')+1);
            filePropsRef.current = {encoded_image, document_extention}
            setFileName(file.name)
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleSave = async () => {
        if (!filePropsRef.current) {
            setError('Please provide valid information for all fields.')
            return;
        } 
        try {
            setIsLoading(true)
            const doc = {
                directory: 'site_img',
                type: filePropsRef.current.document_extention,
                encoded: filePropsRef.current.encoded_image
            }
            const resp = await uploadSiteImage(site.site_id, getUserId(), doc)
            NotificationBarActions.showNotificationBar('success', "The site's image has been updated successfully!");
            handleClose();
        } catch(err) {
            console.error(err)
            NotificationBarActions.showNotificationBar('error', 'An error occurred while setting the site image.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            aria-describedby="add an expense to a client"
            open={open}
            onClose={handleClose}
            >
            <DialogTitle>
                Upload Logo
                <div className={classes.headerSubTextStyle}>Provide a business logo to display. The suggested dimensions are 500x500 pixels.</div>
                {error !== undefined &&
                    <div className={classes.headerSubTextStyle} style={{ color: '#f90506' }}>{error}</div>}
            </DialogTitle>
            <DialogContent>
                {isLoading ?
                <Grid container justify="center" style={{padding: 100}}>
                    <Grid xs={'auto'} >
                        <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    </Grid>
                </Grid>
                :
                <Grid container justify="center">
                    <Grid item xs={11}>
                        <Button onClick={() => fileUploaderRef.current.click()}color="primary" variant='outlined'>
                            <span style={{ fontSize: 18, textTransform: 'none' }}>{fileName ? 'Reselect Document' : 'Select Image'}</span>
                            <input type='file' accept=".png,.jpg,.jpeg,.svg" id='multi' onChange={(e) => handleAddDocumentRequest(e.target.files)} ref={fileUploaderRef} style={{ display: 'none' }} />
                        </Button>
                        {fileName && <div className={classes.h4Text} style={{ fontWeight: 'normal' }}>{fileName}</div>}
                    </Grid>
                </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Button 
                className={classes.buttonStyle}
                disabled={isLoading}
                onClick={handleSave} color="primary">
                    Set Image
                </Button>
            </DialogActions>
            </Dialog>
    )
}

export default withStyles(styles)(UpdateSiteImageModal);