import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { getUserObject } from "../../../../actions/profile/UserActions";


const styles = theme => ({
   root: {
    fontFamily:'Nunito Sans, sans-serif',
    marginTop: 20
  },
 });

const WelcomeCard = ({classes, history}) => {

  const renderGreeting = () => {
    const today = new Date()
    if (today.getHours() < 6 ) return 'Evening'
    else if (today.getHours() >= 6 && today.getHours() < 12) return 'Morning'
    else if (today.getHours() >= 12 && today.getHours() < 18) return 'Afternoon'
    else if (today.getHours() >= 18) return 'Evening'
  }

  return (
    <Grid item xs={12}>
      <div className={ classes.root }>
        <Typography variant="h6" component="h2">
          Good {renderGreeting()}!
        </Typography>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(WelcomeCard);