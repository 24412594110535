import React from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      },
      resize: { fontSize: 14, lineHeight: 1.8 }
}

const EditExternalConnectionsModal = ({ classes, site, onSave, onClose, open, isLoading }) => {
    
    const jsonRef = useRef(JSON.stringify(site.site_external_connections));

    const handleAttemptSave = () => {
        try {
            const obj = JSON.parse(jsonRef.current);
            onSave(obj);
        } catch(ex) {
            console.error(ex);
            NotificationBarActions.showNotificationBar('error', 'Improper JSON, cannot save yet.');
        }
    }

    return (
        <Dialog
        fullWidth
        aria-labelledby="view-invoice"
        aria-describedby="view invoices"
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        >
            <DialogTitle>
                Edit External Connections
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={12}
                            fullWidth
                            className={classes.textField}
                            defaultValue={jsonRef.current}
                            label="External Connection JSON"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.resize,
                                },
                            }}
                            onChange={(e)=> jsonRef.current=e.target.value}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={handleAttemptSave} color="primary">
                    { isLoading ?
                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    : 'Save'
                    }
                </Button>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(EditExternalConnectionsModal);