import React, { useState } from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, withStyles } from "@material-ui/core"
import { Check, Edit } from "@material-ui/icons";
import { capitalize } from "lodash";
import NotificationBarActions from "../../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../../actions/profile/UserActions";
import { updateSite } from "../../../../clients/bettercallkyle/BckApi";
import Colors from "../../../../constants/Colors";
import IconTextSection from "../../../common/IconTextSection";
import EditExternalConnectionsModal from "../../modals/EditExternalConnectionsModal";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminExternalConnectionsCard = ({classes, history, site}) => {

  const [ showEditModal, setShowEditModal ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const convertJsonToHtml = (obj) => {
    return (
      <div>
        {Object.keys(obj).map((key) => (<div><b>{key}</b>: "{obj[key]}"</div>))}
      </div>
    )
  }

  const handleSave = async (obj) => {
    try {
      setIsLoading(true)
      const updatedSite = await updateSite(site.site_id, getUserId(), ['site_external_connections'], { site_external_connections: obj });
      setShowEditModal(false)
      NotificationBarActions.showNotificationBar('success', 'Updated external connections');
    } catch (err) {
      console.error(err)
      NotificationBarActions.showNotificationBar('error', 'Could not update external connections due to an error.');
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
       <CardHeader
        action={
          <IconButton aria-label="settings" onClick={() => setShowEditModal(true)}>
            <Edit />
          </IconButton>
        }
        title="External Connections"
      />
        <CardContent>
          {
            Object.keys(site.site_external_connections).map((key) => (
              <IconTextSection icon={<Check />} primaryText={capitalize(key)} secondaryText={convertJsonToHtml(site.site_external_connections[key])} />
            ))
          }
        </CardContent>
      </Card>
      <EditExternalConnectionsModal open={showEditModal} onClose={() => setShowEditModal(false)} site={site} onSave={handleSave} isLoading={isLoading} />
    </Grid>
  )
}

export default withStyles(styles)(AdminExternalConnectionsCard);