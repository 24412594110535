import Colors from "./Colors";

const DEFAULT_FONT = 'Nunito Sans, sans-serif';

export default {
  paper: {
    width: '100%',
    height: '100%',
  },
  innerPaperPadding: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 28,
  },
  h1Text: {
    fontSize: 48,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h2Text: {
    fontSize: 36,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h3Text: {
    fontSize: 28,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h4Text: {
    fontSize: 20,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  }
};
