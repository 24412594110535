import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Tooltip as MaterialToolTip, Typography, withStyles, List, ListItem, ListItemText, ListItemSecondaryAction, CardActions } from "@material-ui/core";
import { ArrowDownwardSharp, ArrowUpwardSharp, Cached, Dashboard, FeedbackOutlined, HourglassEmpty, Info, Input, PauseCircleFilledOutlined, ScheduleOutlined, ThumbUpAltOutlined } from "@material-ui/icons";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getJobs, getSiteWebsiteReport } from "../../../../clients/bettercallkyle/BckApi";
import { ACTION_REQUIRED_JOB_STATUSES, CURRENT_JOB_STATUSES, JOB_STATUS } from "../../../../constants/Statuses";
import Colors from "../../../../constants/Colors";
import { JOB_STATUS_MAP } from "../../../../constants/Statuses";
import { getUserId } from "../../../../actions/profile/UserActions";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   statusText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif'
  },
   root: {
    minWidth: 275,
    height: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  graphGrid: {
    display: 'default',
    [theme.breakpoints.down('sm')]: {
        display:'none'
    },
  },
  clickableGrid: {
    '&:hover': {
      backgroundColor:  'rgba(25,25,25,0.2)',
      borderRadius: 5,
      cursor: 'pointer'
    },
  },
  statFont: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign:'center',
    },
  },
  statValueFont: {
    textAlign: 'left',
    fontSize: 48,
    [theme.breakpoints.down('sm')]: {
      textAlign:'center',
      fontSize: 36
    },
  },
 });

 const fillerData = [
  {visitors: 0, visitors_last_week: 0, name: 'Sun'},
  {visitors: 0, visitors_last_week: 0, name: 'Mon'},
  {visitors: 0, visitors_last_week: 0, name: 'Tues'},
  {visitors: 0, visitors_last_week: 0, name: 'Wed'},
  {visitors: 0, visitors_last_week: 0, name: 'Thurs'},
  {visitors: 0, visitors_last_week: 0, name: 'Fri'},
  {visitors: 0, visitors_last_week: 0, name: 'Sat'},
 ]


const SiteDetailsCenterViewComponent = ({classes, history, site}) => {

  const websiteData = useRef();
  const jobsRef = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  
  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      const website = await getSiteWebsiteReport(site.site_id)
      const jobs = await getJobs(site.site_id, getUserId()).then((j) => j.sort((a, b) => b.job_metadata.created_at - a.job_metadata.created_at))
      websiteData.current = website;
      jobsRef.current = jobs;
    } catch (ex) {
      setError('Could not load website data!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const prepareData = () => {
    return websiteData.current.report.visitors.timeline.map((stat) => ({
      visitors: stat.count,
      visitors_last_week: stat.visitor_court_past,
      name: stat.date_string,
  })).reverse();
  }

// export const ACTION_REQUIRED_JOB_STATUSES = ['PENDING_APPROVAL', 'REQUESTED_CLIENT_FEEDBACK', 'REQUESTED_CLIENT_ITEMS'];
// export const ACTIVE_JOB_STATUSES = ['APPROVED', 'IN_PROGRESS', JOB_STATUS_MAP.QUEUED];

  const renderStatusIcon = (status) => {
    switch(JOB_STATUS_MAP[status.value]) {
      case JOB_STATUS_MAP.APPROVED:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><ThumbUpAltOutlined style={{ color: Colors.primaryColor, cursor: 'pointer' }} /></MaterialToolTip>
      case JOB_STATUS_MAP.IN_PROGRESS:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><Cached style={{ color: Colors.primaryColor, cursor: 'pointer' }} /></MaterialToolTip>
      case JOB_STATUS_MAP.PENDING_APPROVAL:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><HourglassEmpty style={{ color: Colors.blueColor, cursor: 'pointer' }} /></MaterialToolTip>
      case JOB_STATUS_MAP.REQUESTED_CLIENT_FEEDBACK:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><FeedbackOutlined style={{ color: Colors.warningBackground, cursor: 'pointer' }} /></MaterialToolTip>
      case JOB_STATUS_MAP.REQUESTED_CLIENT_ITEMS:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><Input style={{ color: Colors.warningBackground, cursor: 'pointer' }} /></MaterialToolTip>
      case JOB_STATUS_MAP.QUEUED:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><ScheduleOutlined style={{ color: Colors.primaryColor, cursor: 'pointer' }} /></MaterialToolTip>
      default:
        return <MaterialToolTip title={`${status.label}: ${status.description}`}><Info style={{ color: Colors.blueColor, cursor: 'pointer' }} /></MaterialToolTip>
    }
  }

  const renderPercentageSection = (current_number, past_number) => {
    const isNegative = current_number < past_number;
    const color = isNegative ? Colors.redColor : Colors.greenColor;
    let percentChange = current_number - past_number;
    if (isNegative) percentChange *= -1;
    percentChange = percentChange / past_number * 100;
    return (
        <span style={{ color }}>{isNegative ? <ArrowDownwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> : <ArrowUpwardSharp style={{ marginBottom: -4, fontSize: 18 }} /> } {(percentChange).toFixed(1)}% </span>
    )
  }

  const renderList = () => {
    const jobs = jobsRef.current // jobsRef.current && jobsRef.current.filter(job => ACTIVE_JOB_STATUSES.includes(job.job_details.job_status));
    const relevantProjects = jobsRef.current && jobsRef.current.filter(job => CURRENT_JOB_STATUSES.includes(job.job_details.job_status));
    return (
      <React.Fragment>
      <List dense>
          {relevantProjects && relevantProjects.slice(0,2).map((job) => (
            <ListItem button dense onClick={() => history.push(`/dashboard/site/${site.site_id}/jobs/${job.job_id}`)} key={job.job_id} style={{ paddingLeft: 0 }}>
              <ListItemText primary={job.job_details.job_name} />
              <ListItemSecondaryAction>
                {renderStatusIcon(JOB_STATUS.find(status => status.value === job.job_details.job_status))}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {(!relevantProjects || relevantProjects.length === 0) &&
            <React.Fragment>
              <Typography variant="body1" component="h2" style={{ fontSize: 16 }}>
                {error ? <span style={{ color: Colors.redColor }}>{error}</span> : 'No active projects right now...'}
              </Typography>
            </React.Fragment>
          }
          {(relevantProjects && relevantProjects.length > 2) &&
            <React.Fragment>
              <Typography variant="body1" component="h2" style={{ fontSize: 16, paddingLeft: 4, marginTop: 8 }}>
                <i>{relevantProjects.length} active projects total...</i>
              </Typography>
            </React.Fragment>
          }
        </List>
        </React.Fragment>
    )
  }

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Grid container justify='center'>
          <div style={{marginTop: 16 }}>
            <CircularProgress thickness={5} />
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
            <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Activity
              </Typography>
              <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0, marginBottom: 16 }}>
                {websiteData.current && websiteData.current.report.visitors ? 'Visitors' : 'Not Enabled'}
              </Typography>
              {websiteData.current === undefined || !websiteData.current.report.visitors ?
                <Typography variant="caption" component="h2" style={{ marginTop: -5, marginBottom: 10 }}>
                  Website Statistics is not yet available on your account. Please reach out to us if you would like this feature enabled.
                </Typography>
                :
                <React.Fragment>
                  <Typography variant="body1" component="h2" className={classes.statValueFont} >
                    {websiteData.current.report.visitors.count}
                  </Typography>
                  <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 10, marginBottom: -30 }}>
                  {renderPercentageSection(websiteData.current.report.visitors.count, websiteData.current.report.visitors.count_past)}
                  </Typography>
                  <Typography variant="caption" component="h2" className={classes.statFont} style={{ marginTop: 28, marginBottom: 13 }}>
                  in the past 7 days.
                  </Typography>
                </React.Fragment>
              }
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => history.push(`/dashboard/site/${site.site_id}/activity`, { site })}
                >View</Button>
              </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}} variant='outlined'>
                <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Projects
                </Typography>
                <Typography variant="h6" component="h2" style={{ marginTop: -10, paddingTop: 0 }}>
                  Current Work
                </Typography>
                {isLoading ? <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                  :
                  <div>
                    {renderList()}
                  </div>
                }
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => history.push(`/dashboard/site/${site.site_id}/projects`, { site })}
                  >View</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        
    </Grid>
  )
}

export default withStyles(styles)(SiteDetailsCenterViewComponent);