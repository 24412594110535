import React from "react";
import { Card, CardContent, CardHeader, Grid, IconButton, withStyles } from "@material-ui/core"
import { AccountBalance, AccountBox, AlternateEmail, Business, Edit, LocationOn } from "@material-ui/icons";
import { find } from "lodash";
import Colors from "../../../../constants/Colors";
import IconTextSection from "../../../common/IconTextSection";
import { SITE_STATUSES } from "../../../../constants/Statuses";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminSiteDetailsCard = ({classes, site, users, history}) => {

  const getPrimaryUserName = () => {
    const primary = find(users, { user_id: site.site_security.primary_user_id })
    if (!primary) return 'Unknown User';
    else return `${primary.user_personal.first_name} ${primary.user_personal.last_name}`
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
       <CardHeader
        action={
          <IconButton aria-label="settings" onClick={() => history.push(`/admin/sites/${site.site_id}/edit`)}>
            <Edit />
          </IconButton>
        }
        title="Site Details"
      />
        <CardContent>
          <IconTextSection icon={<AccountBox />} primaryText={getPrimaryUserName()} secondaryText={'Account Primary'} />
          <IconTextSection icon={<Business />} primaryText={site.site_details.site_structure} secondaryText={<span>EIN: {site.site_details.site_ein ? site.site_details.site_ein : <i>Not Provided</i>}</span>} />
          <IconTextSection icon={<LocationOn />} primaryText={'Address'} secondaryText={<React.Fragment><div>{site.site_details.site_address.address_line_1} {site.site_details.site_address.address_line_2}</div><div>{site.site_details.site_address.city}, {site.site_details.site_address.state} {site.site_details.site_address.zipcode}</div></React.Fragment>} />
          <IconTextSection icon={<AlternateEmail />} primaryText={'Recovery Email'} secondaryText={<span>{site.site_details.site_recovery_email ? site.site_details.site_recovery_email : <i>Not Provided</i>}</span>} />
          <IconTextSection icon={<AccountBalance />} primaryText={'Account Status'} secondaryText={<span>{site.site_details.site_status ? SITE_STATUSES.find(s => s.value === site.site_details.site_status).label  : <i>Not Available</i>}</span>} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(AdminSiteDetailsCard);