import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Typography, withStyles } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { getJobs } from "../../../../clients/bettercallkyle/BckApi";
import Colors from "../../../../constants/Colors";
import { ACTIVE_JOB_STATUSES, JOB_STATUS } from "../../../../constants/Statuses";
import { getUserId } from "../../../../actions/profile/UserActions";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminJobsCard = ({classes, site, history}) => {

  const jobsRef = useRef([])

  const [ isLoading, setIsLoading ] = useState(true);
  const [ error, setError ] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    loadData();
  }, [site])

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const loadData = async () => {
    try {
      if (site === undefined || site.site_id === undefined) throw 'No Site ID';
      const jobs = await getJobs(site.site_id, getUserId()).then((j) => j.sort((a, b) => b.job_metadata.created_at - a.job_metadata.created_at))
      jobsRef.current = jobs;
    } catch (ex) {
      setError('Could not load jobs!');
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  const renderMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={() => history.push(`/admin/jobs/add?site_id=${site.site_id}`)}>Add Project</MenuItem>
      <MenuItem onClick={() => { history.push(`/dashboard/site/${site.site_id}/jobs`, { site }) }}>View All</MenuItem>
    </Menu>
  )

  const renderList = () => {
    const jobs = jobsRef.current && jobsRef.current.filter((job) => ACTIVE_JOB_STATUSES.includes(job.job_details.job_status));
    return (
      <List className={classes.root}>
          {jobs && jobs.slice(0,2).map((job) => (
            <ListItem button onClick={() => history.push(`/dashboard/site/${site.site_id}/jobs/${job.job_id}`)} key={job.job_id}>
              <ListItemText primary={job.job_details.job_name} secondary={JOB_STATUS.find(status => status.value === job.job_details.job_status).label} />
            </ListItem>
          ))}
          {!jobs || jobs.length === 0 && 
            <React.Fragment>
              <Typography variant="body1" component="h2">
                {error ? <span style={{ color: Colors.redColor }}>{error}</span> : 'No Active Jobs...'}
              </Typography>
            </React.Fragment>
          }
        </List>
    )
  }

  return (
    <Grid item md={6} xs={12}>
       <Card className={classes.root} variant="outlined">
       <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleMoreClick}>
            <MoreVert />
          </IconButton>
        }
        title="Active Projects"
      />
        <CardContent>
          {isLoading ? <CircularProgress style={{ color: Colors.darkColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            :
            renderList()
          }
        </CardContent>
      </Card>
      {renderMenu()}
    </Grid>
  )
}

export default withStyles(styles)(AdminJobsCard);