import React from "react";

import { Card, CardContent, Grid, Typography, withStyles } from "@material-ui/core";
import Colors from "../../../../constants/Colors";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const WelcomeToBetaCard = ({ classes, history, site }) => {


  return (
    <Grid item xs={12} lg={6}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Announcement
          </Typography>
          <Typography variant="h6" component="h2">
            Welcome To Your Activity!
          </Typography>
          <Typography variant="caption" component="h2" style={{ lineHeight: 1.4, marginBottom: 10}}>
            This page has been introduced to connect you directly to the pulse of your website. From here you are able to monitor your traffic, how visitors are interacting with your site, and much more.
          </Typography>
          <Typography variant="caption" component="h2" style={{ lineHeight: 1.4, marginBottom: 10}}>
            Thanks for being a BCK Client and let us know if you have any feedback!
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(WelcomeToBetaCard);