import React, { Component } from 'react';
import mainLogo from '../../assets/logo/large-K-icon.png'
import { Grid, Paper, Button} from '@material-ui/core';
import Colors from '../../constants/Colors';


class GenericNotFound extends Component {

  render() {
      return (
        <div style={{height:'auto', position: 'relative', marginTop: 150 }}>
            <Grid container justify='center'>
              <Grid item xs={'auto'}>
                <img src={mainLogo} alt={'logo'} className="center" style={{maxHeight:100, display:'block', width:'auto' }}/>
              </Grid>
            </Grid>
            <Grid container justify = 'center' style={{ marginTop: 28 }}>
              <Grid xs={11} md={8}>
                <Paper style={{ padding: 32 }}>
                  <div style={{fontSize:32, textAlign: 'center', fontFamily:'Nunito Sans, sans-serif', color: Colors.defaultDarkText}}>404 Error</div>
                  <div style={{fontSize:24, textAlign: 'center', fontFamily:'Nunito Sans, sans-serif', color: Colors.defaultDarkText}}>Oh bugger! This page was not found.</div>
                  <Grid container justify='center' style={{ marginTop: 28 }}>
                    <Grid xs={'auto'}>
                        <Button variant="outlined"  onClick={this.props.history.goBack} style={{ textTransform: 'none', fontFamily:'Nunito Sans, sans-serif'}}>
                            <span style={{paddingLeft: 10, paddingRight: 10, fontSize: 16, color: Colors.defaultText}}>Go Back</span>
                        </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
            </Grid>
        </div>
      );
  }
};

export default GenericNotFound;
