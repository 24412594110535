export const JOB_STATUS_MAP = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  QUEUED: 'QUEUED',
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING: 'AWAITING',
  COMPLETED: 'COMPLETED',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  REJECTED:  'REJECTED',

  // Deprecated Values
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REQUESTED_CLIENT_FEEDBACK: 'REQUESTED_CLIENT_FEEDBACK',
  REQUESTED_CLIENT_ITEMS: 'REQUESTED_CLIENT_ITEMS'
}

export const JOB_STATUS = [
{
  value: 'DRAFT',
  label: 'Draft',
  description: 'The project still needs to be submitted to the client for approval, or a higher status needs to be applied to begin this project.'
},
{
  value: 'PENDING',
  label: 'Pending',
  description: 'This project nearly ready! We are either waiting on you to approve it, or for an initial payment to be received. If you have any questions please contact us.'
},
{
  value: 'AWAITING_PAYMENT',
  label: 'Awaiting Payment',
  description: 'This project currently has an open invoice attached to it. Please review the invoice and let us know if you have any questions.'
},
{
  value: 'PENDING',
  label: 'Pending',
  description: 'This project nearly ready! We are either waiting on you to approve it, or for an initial payment to be received. If you have any questions please contact us.'
},
{
  value: 'QUEUED',
  label: 'Queued',
  description: 'Woohoo! This project is ready to go. Work will begin soon.'
},
{
  value: 'IN_PROGRESS',
  label: 'In Progress',
  description: 'Your project is being worked on! If you have any questions please feel free to reach out ot us.'
},
{
  value: 'AWAITING',
  label: 'Awaiting',
  description: 'Before this project continues, it looks like we need something from you... If you have any questions please reach out to us.'
},
{
  value: 'COMPLETED',
  label: "Completed",
  description: 'Yay! This project has been completed!'
},
{
  value: 'PAUSED',
  label: 'Paused',
  description: 'This project has been paused... If you have a question, please reach out to us!'
},
{
  value: 'STOPPED',
  label: 'Stopped',
  description: 'This project has been stopped... If you have a question, please reach out to us!'
},
{
  value: 'REJECTED',
  label: 'Rejected',
  description: 'This project was not approved to start.'
},

// Deprecated statuses
{
  value: 'PENDING_APPROVAL',
  label: 'Waiting For Approval (DEPRECATED)',
  description: 'The project proposal is waiting to be approved or rejected by you.'
},
{
  value: 'APPROVED',
  label: 'Approved For Start (DEPRECATED)',
  description: 'The project has been approved and will be started soon.'
},
{
  value: 'REQUESTED_CLIENT_FEEDBACK',
  label: 'Feedback Requested (DEPRECATED)',
  description: 'This project may require some information from you in order to continue.'
},
{
  value: 'REQUESTED_CLIENT_ITEMS' ,
  label: 'Content or Material Requested (DEPRECATED)',
  description: 'This project may require items, such as images or content, in order to continue progress.'
}
];

export const ACTION_REQUIRED_JOB_STATUSES = [JOB_STATUS_MAP.PENDING, JOB_STATUS_MAP.AWAITING, JOB_STATUS_MAP.AWAITING_PAYMENT, 'PENDING_APPROVAL', 'REQUESTED_CLIENT_FEEDBACK', 'REQUESTED_CLIENT_ITEMS'];
export const ACTIVE_JOB_STATUSES = [JOB_STATUS_MAP.QUEUED, JOB_STATUS_MAP.IN_PROGRESS, JOB_STATUS_MAP.APPROVED];
export const INACTIVE_JOB_STATUES = [JOB_STATUS_MAP.PAUSED, JOB_STATUS_MAP.STOPPED, JOB_STATUS_MAP.REJECTED];
export const FINAL_JOB_STATUSES = [ JOB_STATUS_MAP.COMPLETED, JOB_STATUS_MAP.REJECTED, JOB_STATUS_MAP.STOPPED ]
export const CURRENT_JOB_STATUSES = [...ACTIVE_JOB_STATUSES, ...ACTION_REQUIRED_JOB_STATUSES]


export const SITE_STATUSES = [{
  value: 'INCOMING',
  label: 'Incoming',
  description: 'This business is currently in the process of starting a project or subscription.'
},
{
  value: 'ACTIVE',
  label: 'Active',
  description: 'This business is in good standing.'
},
{
  value: 'INACTIVE',
  label: 'Inactive',
  description: 'This business is not currently working with BCK.'
},
{
  value: 'BAD_STANDING',
  label: 'Bad Standing',
  description: 'This business is delinquent in payment or has an outstanding account issue.'
}]