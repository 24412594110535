import React from "react";
import { AppBar, Avatar, Button, Card, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tab, Tabs, Typography, withStyles, Box, CircularProgress, useMediaQuery } from "@material-ui/core"
import { AccountBox, Pages, Store } from "@material-ui/icons";
import Colors from "../../../../constants/Colors";
import NotificationBarActions from "../../../../actions/error-pages/NotificationBarActions";
import { getAllJobs } from "../../../../clients/bettercallkyle/BckApi";
import { getUserId } from "../../../../actions/profile/UserActions";
import AdminJobsViewTabPanel from "../AdminJobsViewTabPanel";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const AdminSitesAndUsersDetailsCard = ({classes, history, sites = [], users = []}) => {
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allJobs, setAllJobs] = React.useState()


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ value, index, children }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )

  const handleGetProjectInformation = async () => {
    try {
      setIsLoading(true)
      const allJobs = await getAllJobs(getUserId());
      if (allJobs === undefined) throw 'Bad response object from API.'
      setAllJobs(allJobs.jobs)
    } catch (err) {
      const error = await err;
      NotificationBarActions.showNotificationBar('error', `An error occurred while retrieving projects. Details: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid item sm={12} xs={12}>
       <Card className={classes.root} variant="outlined">
        <AppBar position="static" style={{ paddingTop: 10, backgroundColor: '#fff', color: '#000'}}>
          <Tabs value={value} onChange={handleChange} TabIndicatorProps={{style: {background: Colors.primaryColor }}}>
            <Tab label="Sites" />
            <Tab label="Users" />
            <Tab label="Projects" onClick={handleGetProjectInformation}/>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <List className={classes.root}>
            {sites && sites.sort((a, b) => a.site_details.site_name.localeCompare(b.site_details.site_name)).map((site) => (
              <ListItem key={site.site_id} button={matches} onClick={() => matches && history.push(`/admin/sites/${site.site_id}`, { site })}>
                <ListItemAvatar>
                  {site && site.site_details && site.site_details.site_img_url ?
                    <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} />
                  :
                    <Avatar><Store /></Avatar>
                  }
                </ListItemAvatar>
                <ListItemText primary={site.site_details.site_name} />
                <ListItemSecondaryAction>
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <Button edge="end" aria-label="delete" variant='outlined' onClick={() => history.push(`/admin/sites/${site.site_id}`, { site })}>
                      Manage
                    </Button>
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {(!sites || sites.length === 0) && 
              <React.Fragment>
                <Typography variant="body1" component="h2">
                  No Accounts Open Yet...
                </Typography>
              </React.Fragment>
            }
          </List>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List className={classes.root}>
              {users && users.map((user) => (
                <ListItem key={user.user_id} button={matches} onClick={() => matches && history.push(`/admin/users/${user.user_id}`, { user })}>
                  <ListItemText primary={`${user.user_personal.first_name} ${user.user_personal.last_name}`} secondary={user.user_login_id} />
                  <ListItemSecondaryAction>
                    <Box display={{ xs: 'none', sm: 'block' }}>
                      <Button edge="end" aria-label="delete" variant='outlined' onClick={() => history.push(`/admin/users/${user.user_id}`, { user })}>
                        Manage
                      </Button>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {(!users || users.length === 0) && 
                <React.Fragment>
                  <Typography variant="body1" component="h2">
                    No Accounts Open Yet...
                  </Typography>
                </React.Fragment>
              }
            </List>
        </TabPanel>
        <TabPanel value={value} index={2}>
          { isLoading ?
            <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            : <AdminJobsViewTabPanel jobs={allJobs} sites={sites} users={users} />
          }
        </TabPanel>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(AdminSitesAndUsersDetailsCard);