import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Paper, IconButton, Typography, List, ListItem, ListItemText, Link, ListItemSecondaryAction, Button } from '@material-ui/core';
import Colors from "../../../../constants/Colors";
import { AccountBox, KeyboardBackspace, LocationOn, Store, Web } from "@material-ui/icons";
import Styles from "../../../../constants/Styles";
import { useEffect } from "react";
import { useState } from "react";
import { getCurrentMonthName, getCurrentYear } from "../../../../utilities/DateUtilities";
import { getSite, getSitePaymentReport } from "../../../../actions/internal/SiteActions";
import NotificationBarActions from "../../../../actions/error-pages/NotificationBarActions";
import IconTextSection from "../../../common/IconTextSection";

const styles = theme => ({
    paper: Styles.paper,
    innerPaper: Styles.innerPaperPadding,
    initials: {
        fontSize: 48,
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    titleText : {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 36
    },
    defaultText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.defaultText,
        fontSize: 18
    },
    defaultSubText: {
        fontFamily:'Nunito Sans, sans-serif',
        color: Colors.greyText,
        fontSize: 18,
        textTransform: 'italics'
    },
    name: {
        fontSize: 36,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    sectionTitle: {
        fontSize: 28,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
        marginLeft: 28,
        marginBottom: 18,
        paddingTop: 18
    },
    link: {
        fontSize: 16,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.link,
    },
    subSectionTitle: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.defaultDarkText,
    },
    subSectionText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: Colors.darkGreyText,
    },
    websiteText: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: '#aaa'
    },
    address: {
        fontSize: 18,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'left',
        color: '#444'
    },
    email: {
        fontSize: 20,
        fontFamily:'Nunito Sans, sans-serif',
        width: '100%',
        textAlign: 'center',
        color: Colors.defaultDarkText
    },
    actionButton: {
        textTransform:'none',
        fontSize:16,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight:'bold',
        color: Colors.lightColor,
        height: 50
    },
    button: {
        textTransform:'none',
        fontFamily:'Nunito Sans, sans-serif',
        fontSize:16,
        color:'#FCFCFC',
        borderColor: '#FCFCFC',
        '&:hover': {
          backgroundColor: "#656766",
        },
        width:'70%',
        marginLeft:'15%',
        height:40,
        marginBottom:10
    },
    container: {
      paddingLeft: 16,
      paddingRight: 16,
      marginTop: 100,
      marginBottom: 50,
      [theme.breakpoints.up('md')]: {
          marginTop: 150,
      }
    },
    containedButton: {
      textTransform:'none',
      fontFamily:'Nunito Sans, sans-serif',
      fontSize:20,
      color: Colors.primaryColor,
      borderColor: Colors.primaryColor,
      '&:hover': {
        backgroundColor: "#ccc",
      }
    },
})

const SiteAccountDetails = ({ classes, history, location, match }) => {

    const [ siteData, setSiteData ] = useState();
    const [ stripeReport, setStripeReport ] = useState();
    const [ isLoading, setIsLoading ] = useState();

    useEffect(() => {
        prepareSiteSession();
    }, [])

    const prepareSiteSession = () => {
        const { params } = match;
        const { state } = location;
        const site_id = params.site_id;
        let site = state && state.site ? state.site : undefined;
        const stripe_invoice_report = state && state.stripe_invoice_report ? state.stripe_invoice_report : undefined;
        if (site_id === undefined) { history.push('/dashboard'); return; }
        if (site !== undefined && site.site_id !== site_id) site = undefined;
        if (site === undefined || stripe_invoice_report === undefined) loadData(site_id);
        else {
          setSiteData(site);
          setStripeReport(stripe_invoice_report);
        }
    }

    const showTab = (url) => {
        const win = window.open(url, '_blank');
        win.focus();
    }

    const loadData = async (site_id) => {
        setIsLoading(true);
        try {
          const site = await getSite(site_id);
          if (site === undefined) {
            history.push('/dashboard');
            NotificationBarActions.showNotificationBar('error', 'Uh oh! The business information could not be found. Please try again.');
            return;
          }
          // const stripe_invoice_report = await getSitePaymentReport(site_id);
          // setStripeReport(stripe_invoice_report);
          setSiteData(site);
        } catch (ex) {
          NotificationBarActions.showNotificationBar('error', 'Could not load the business data at this time!');
          history.push('/dashboard');
          console.error(ex);
        } finally {
          setIsLoading(false);
        }
    }

    if (siteData === undefined || isLoading) {
        return (
        <Grid container style={{ marginTop: 150 }} justify='center'>
            <Grid item xs={'auto'}>
                <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
            </Grid>
        </Grid>)
    } 

    const { site_details } = siteData ? siteData : {};
    const dueInvoices = stripeReport && stripeReport.report.paid_invoices ? stripeReport.report.open_invoices.invoices : [];
    const invoices = stripeReport && stripeReport.report.paid_invoices ? stripeReport.report.paid_invoices.invoices : [];
    const upcomingInvoice = stripeReport && stripeReport.report.upcoming_invoice ? stripeReport.report.upcoming_invoice : undefined;
    const upcomingExpenses = upcomingInvoice && upcomingInvoice.lines ? upcomingInvoice.lines.data.filter((item) => !item.price.active) : [];

    const renderWebsite = () => {
      if (siteData && siteData.site_service_modules && siteData.site_service_modules.website && siteData.site_service_modules.website.domain )
      return <a style={{ color: Colors.primaryColor }} href={`https://${siteData.site_service_modules.website.domain.url}`} target='_blank'>{siteData.site_service_modules.website.domain.url}</a>
      else return <i>Not Available</i>
    }

    const renderSiteDetailsCard = () => (
      <Paper variant="outlined" style={{ padding: 16 }}>
        <Typography variant="h6" component="h2" style={{ fontSize: 24 }}>
          Account Information
        </Typography>
        <IconTextSection
            icon={<Store />}
            primaryText={`${site_details.site_name}${`, ${site_details.site_structure}` || ''}`}
            primaryTextStyle={{ fontSize: 18  }}
          />
        <IconTextSection
            icon={<Web />}
            primaryText={renderWebsite()}
            primaryTextStyle={{ fontSize: 18  }}
          />
        <IconTextSection
          icon={<LocationOn />}
          primaryText={ site_details.site_address && site_details.site_address.address_line_1 ? <span>{site_details.site_address.address_line_1} {site_details.site_address.address_line_2}</span> : <i>Not Provided</i> }
          primaryTextStyle={{ fontSize: 18  }}
          secondaryText={ site_details.site_address && site_details.site_address.address_line_1 ? <span>{site_details.site_address.city} {site_details.site_address.state}, {site_details.site_address.zipcode}</span> : '' }
        />
      </Paper>
    )

    const renderBillingDetailsCard = () => (
      <Paper variant="outlined" style={{ padding: 16 }}>
        <Typography variant="h6" component="h2" style={{ fontSize: 24 }}>
          Billing
        </Typography>
        <div style={{ marginTop: 24, marginBottom: 16}}>
          <Button
              className={classes.containedButton}
              variant="outlined"
              onClick={() => showTab(`/dashboard/site/${siteData.site_id}/invoices`)}
          >
              View Invoices
          </Button>
        </div>
        <Typography variant="h6" component="h2" style={{ fontSize: 14, color: Colors.darkGreyText }}>
          <i>For any changes to your billing information, please contact us directly.</i>
        </Typography>
      </Paper>
    )

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} sm={9} md={8}>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Grid container justify='flex-start' spacing={1}>
                                <Grid item xs={'auto'}>
                                    <IconButton onClick={() => history.goBack()}>
                                        <KeyboardBackspace />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6" component="h2">
                                        Business Account
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9} md={8}>
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginTop: 16 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {renderSiteDetailsCard()}
                        </Grid>
                        <Grid item xs={12}>
                          {renderBillingDetailsCard()}
                        </Grid>
                      </Grid>
                        
                        
                    </div>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(SiteAccountDetails);