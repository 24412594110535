import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import NotificationBarActions from '../../../actions/error-pages/NotificationBarActions';
import Colors from '../../../constants/Colors';
import Styles from '../../../constants/Styles';
import { JOB_STATUS, JOB_STATUS_MAP } from '../../../constants/Statuses';
import { updateJobStatus } from '../../../clients/bettercallkyle/BckApi';
import { getUserId } from '../../../actions/profile/UserActions';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:18,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        paddingLeft: 25,
        paddingRight: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:16,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      },
      resize: { fontSize: 14, lineHeight: 1.8 }
}

const EditJobStatusModal = ({ classes, site, job, onSuccess, onClose, open, requestedStatus }) => {
    
    const [isLoading, setIsLoading] = useState();
    const [notificationType, setNotificationType] = useState('NONE')
    const [quoteApplicationType, setQuoteApplicationType] = useState('NONE');
    const [status, setStatus] = useState(job.job_details.job_status)
    const [statusChangeReason, setStatusChangeReason] = useState(job.job_metadata[`${job.job_details.job_status.toLowerCase()}_reason`]);
    const [statusNote, setStatusNote] = useState(job.job_details.job_status_note);
    const [errors, setErrors] = useState([]);

    const isValidForm = () => {
        const foundErrors = [];
        if (status === JOB_STATUS_MAP.REJECTED && (statusChangeReason === undefined || statusChangeReason.trim() === '')) foundErrors.push('rejectionReason');
        setErrors(foundErrors)
        return foundErrors.length === 0
    }

    const handleAttemptSave = async () => {
        try {
            if (!isValidForm()) return;
            setIsLoading(true)
            const reason = statusChangeReason;
            const updatedJob = await updateJobStatus(getUserId(), site.site_id, job.job_id, status,
                reason === '' ? undefined : reason,
                statusNote === '' ? undefined : statusNote, { notification: notificationType, payment: quoteApplicationType });
                NotificationBarActions.showNotificationBar('success', `This project's status has been updated to ${JOB_STATUS.find(s => s.value === status).label}.`);
            onSuccess(updatedJob);
        } catch(ex) {
            const err = await ex;
            console.error(err);
            NotificationBarActions.showNotificationBar('error', `Could not update the project status at this time. Details: ${err ? err.message : 'No error details.'}`);
        } finally {
            setIsLoading(false)
        }
    }

    const getProperStatuses = () => {
        const status = job.job_details.job_status;
        const mainStatuses = [
            JOB_STATUS_MAP.IN_PROGRESS,
            JOB_STATUS_MAP.PAUSED,
            JOB_STATUS_MAP.AWAITING,
            JOB_STATUS_MAP.REJECTED,
            JOB_STATUS_MAP.STOPPED,
            JOB_STATUS_MAP.COMPLETED
        ]
        
        if (status === JOB_STATUS_MAP.DRAFT) {
            return [...mainStatuses, JOB_STATUS_MAP.DRAFT, JOB_STATUS_MAP.QUEUED]
        } else if (status === JOB_STATUS_MAP.PENDING_APPROVAL) {
            return [...mainStatuses, JOB_STATUS_MAP.PENDING_APPROVAL, JOB_STATUS_MAP.QUEUED];
        } else if (status === JOB_STATUS_MAP.APPROVED) {
            return [...mainStatuses, JOB_STATUS_MAP.APPROVED, JOB_STATUS_MAP.QUEUED];
        } else if (status === JOB_STATUS_MAP.QUEUED) {
            return [...mainStatuses, JOB_STATUS_MAP.QUEUED];
        } else if (status === JOB_STATUS_MAP.AWAITING_PAYMENT) {
            return [...mainStatuses, JOB_STATUS_MAP.QUEUED, JOB_STATUS_MAP.AWAITING_PAYMENT];
        } else if (status === JOB_STATUS_MAP.PENDING) {
            return [...mainStatuses, JOB_STATUS_MAP.PENDING, JOB_STATUS_MAP.AWAITING_PAYMENT];
        }
        return mainStatuses;
    }

    const renderRadioLabel = (title, description) => (
        <div className={classes.radioText}><b>{title}: </b><span style={{ fontSize: 16 }}>{description}</span></div>
    )

    const renderCompletedSection = () => {
        const hasStripeAccount = !(site.site_external_connections.stripe === undefined || site.site_external_connections.stripe.customer_id === undefined);
        
        return (<Grid item xs={12} style={{ marginTop: 16 }}>
            <Typography variant="caption" component="h2">
                <b>Apply Quote Approval</b>
            </Typography>
            <RadioGroup name="quoteApplication" value={quoteApplicationType} onChange={(e) => setQuoteApplicationType(e.target.value)}>
                <FormControlLabel disabled={!hasStripeAccount || (job.job_details.job_payment_structure !== 'QUOTE' && job.job_details.job_payment_structure !== 'HOURLY') } value="APPLY_QUOTE" control={<Radio />} label={renderRadioLabel("Apply Charges", "An invoice will be prepared and sent to the customer with the final amount.")} />
                <FormControlLabel disabled={!hasStripeAccount} value="DO_NOT_CHARGE" control={<Radio />} label={renderRadioLabel("Do Not Charge", "The client will not be charged the final amount")} />
            </RadioGroup>
        </Grid>)
    }

    const updateStatus = (event) => {
        if (event.target.value !== status) {
            const hasStripeAccount = !(site.site_external_connections.stripe === undefined || site.site_external_connections.stripe.customer_id === undefined);

            if (event.target.value === JOB_STATUS_MAP.COMPLETED) {
                setQuoteApplicationType(hasStripeAccount && job.job_details.job_payment_structure === 'QUOTE' ? 'APPLY_QUOTE' : 'DO_NOT_CHARGE')
            } else if (quoteApplicationType !== 'NONE') {
                setQuoteApplicationType('NONE')
            }
            setStatus(event.target.value);
            setStatusChangeReason('');
            setStatusNote('');
        }
    }

    return (
        <Dialog
        fullWidth
        aria-labelledby="view-invoice"
        aria-describedby="view invoices"
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        >
            <DialogTitle>
                <Typography variant="subtitle1" component="h2">
                    Update Project Status
                </Typography>
                <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                    Once saved, this change can not be undone.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} md={6}>
                    <TextField
                        style={{ marginTop: 16 }}
                        select
                        fullWidth
                        className={classes.textField}
                        label="*Status"
                        variant="outlined"
                        value={status}
                        onChange={updateStatus}
                    >
                        {JOB_STATUS.filter(status => getProperStatuses().includes(status.value)).map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                            {status.label}
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Typography variant="caption" component="h2">
                        <b>Details</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        error={errors.includes('rejectionReason')}
                        margin="normal"
                        type="text"
                        multiline
                        value={statusChangeReason}
                        rows={2}
                        label={status === JOB_STATUS_MAP.REJECTED ? "* Rejection Reason" : "Admin Note"}
                        helperText={status === JOB_STATUS_MAP.REJECTED ? "REQUIRED: Provide the reason for the rejection of this project. If this project has no disagreements on it, consider setting status as 'Stopped'." : "This note will be saved in the metadata for this status to answer the question 'Why was this project placed into this status?'"}
                        variant="outlined"
                        onChange={(e) => setStatusChangeReason(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        margin="normal"
                        type="text"
                        multiline
                        value={statusNote}
                        rows={3}
                        label="Update For Client"
                        helperText={"These details are shown to the user to provide clairity into why a project is set at a particular status and what needs to be done, or will be done to progress this project."}
                        variant="outlined"
                        onChange={(e) => setStatusNote(e.target.value)}
                    />
                </Grid>
                {status === JOB_STATUS_MAP.COMPLETED && renderCompletedSection()}
                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Typography variant="caption" component="h2">
                        <b>Notification Type</b>
                    </Typography>
                    <RadioGroup name="notificationType" value={job.job_details.job_status === status ? 'NONE' : notificationType} onChange={(e) => setNotificationType(e.target.value)}>
                        <FormControlLabel disabled={job.job_details.job_status === status} value="SEND_NOTIFICATION" control={<Radio />} label={renderRadioLabel("Send Notification", "A notification about starting this job will be sent to the client.")} />
                        {status === JOB_STATUS_MAP.PENDING_APPROVAL && <FormControlLabel value="SEND_APPROVAL_REQUEST"  disabled={job.job_details.job_status === status} control={<Radio />} label={renderRadioLabel("Request Approval", "The client will recieve an approval request for this job.")} />}
                        <FormControlLabel value="NONE" disabled={job.job_details.job_status === status} control={<Radio />} label={renderRadioLabel("Do Not Notify", "The client will not recieve any notification.")} />
                    </RadioGroup>
                    {job.job_details.job_status === status && <div className={classes.detailsText} style={{ textAlign: 'left' }}>No notification can be sent when the status has not changed.</div>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button 
                    className={classes.buttonStyle}
                    disabled={isLoading}
                    onClick={handleAttemptSave} color="primary">
                    { isLoading ?
                    <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                    : 'Update Status'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(EditJobStatusModal);