import { Button, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Radio, RadioGroup, Switch, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, Close, KeyboardBackspace, Warning } from "@material-ui/icons";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import NotificationBarActions from "../../../actions/error-pages/NotificationBarActions";
import { getUserId } from "../../../actions/profile/UserActions";
import { createJob } from "../../../clients/bettercallkyle/BckApi";
import Colors from "../../../constants/Colors";
import { JOB_STATUS, JOB_STATUS_MAP } from "../../../constants/Statuses";
import { generateShortUIDString } from '../../../utilities/StringFormatUtilities';

const styles = theme => ({
    root: {
        marginTop: 80,
        paddingLeft: 16,
        paddingRight: 16
    },
    textField: {
        marginTop: 0
    },
    buttonContainedStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        backgroundColor: Colors.primaryColor,
        fontWeight: 'bold'
    },
    buttonTextStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color: '#000',
        fontWeight: 'bold'
    },
    titleText: {
        fontSize:44,
        color: Colors.primaryColor,
        fontFamily:'Rock Salt, sans-serif',
        textAlign:'center',
        fontWeight:'bold',
        fontStyle:'italic',
        marginTop:15,
        [theme.breakpoints.up('sm')]: {
            textAlign:'left',
            fontSize:56
        },
    },
    subtitleText: {
    fontSize:18,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    marginTop: 5,
    marginBottom:20,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:20
    },
    },
    sectionText: {
    fontSize:24,
    color: Colors.defaultDarkText,
    fontFamily:'Nunito Sans, sans-serif',
    textAlign:'center',
    fontWeight:'bold',
    marginTop:15,
    [theme.breakpoints.up('sm')]: {
        textAlign:'left',
        fontSize:24
    },
    },
    radioText: {
        fontSize:18,
        color: Colors.defaultDarkText,
        fontFamily:'Nunito Sans, sans-serif'
    }
})

const AdminAddJobComponent = ({ classes, history, sites, users, location }) => {

    const primaryUserRef = useRef();
    const isSiteClockifyEnabled = useRef(false);

    const [ jobSite, setJobSite ] = useState('');
    const [ jobName, setJobName ] = useState();
    const [ jobDescription, setJobDescription ] = useState();

    const [ startJobPrice, setStartJobPrice ] = useState('0.00');
    const [ endJobPrice, setEndJobPrice ] = useState('0.00');
    const [ expectedTotalJobPrice, setExpectedTotalJobPrice ] = useState(0);

    const [ jobStatus, setJobStatus ] = useState('DRAFT');
    const [ clientNotification, setClientNotification ] = useState('NONE');
    const [ paymentStructure, setPaymentStructure ] = useState('QUOTE');
    const [ isSeperateInvoice, setIsSeperateInvoice ] = useState(true);
    const [ isHidden, setIsHidden ] = useState(false);
    const [ isMandatedFirstPayment, setIsMandatedFirstPayment ] = useState(false);

    const [ quoteItems, setQuoteItems ] = useState([]);
    const [ quoteItemDescription, setQuoteItemDescription] = useState();
    const [ quoteItemUnits, setQuoteItemUnits] = useState();
    const [ quoteItemCostPerUnit, setQuoteItemCostPerUnit] = useState();

    const [ hoursEstimation, setHoursEstimation ] = useState();

    const [errors, setErrors] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            setIsLoading(true)
            const urlParams = new URLSearchParams(location.search)
            const site_id = urlParams.get('site_id')
            if (site_id) setJobSiteId(site_id)

        } catch (err) {
            NotificationBarActions.showNotificationBar('error', 'Could not load project data at this time.');
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const setJobSiteId = (jobSiteId) => {
        const site = sites.find((site) => site.site_id === jobSiteId);
        const user = getPrimaryUser(site);
        primaryUserRef.current = user;
        isSiteClockifyEnabled.current = !!getClockifyId(site);
        if (!isSiteClockifyEnabled.current) {
            setPaymentStructure('NO_COST')
        } else {
            setPaymentStructure('QUOTE')
        }
        setJobSite(jobSiteId);
        setQuoteItems([])
        setStartJobPrice('0.00')
        setEndJobPrice('0.00')
    }

    const handleSetJobStatus = (status) => {
        if (status !== 'DRAFT' && status !== 'PENDING_APPROVAL') {
            setStartJobPrice(0)
        }
        setJobStatus(status)
    }

    const getPrimaryUser = (site) => {
        if (site === undefined) return undefined;
        return users.find((user) => user.user_id === site.site_security.primary_user_id);
    }

    const getClockifyId = (site) => {
        if (site === undefined || site.site_external_connections === undefined || site.site_external_connections.clockify === undefined) return undefined;
        return site.site_external_connections.clockify.client_id
    }

    const isValidForm = () => {
        const foundErrors = [];
        if (!jobSite) foundErrors.push('jobSite')
        if (!jobName || isEmpty(jobName)) foundErrors.push('jobName');
        if (!jobDescription || isEmpty(jobDescription)) foundErrors.push('jobDescription');
        switch(paymentStructure) {
            case 'QUOTE':
                if (startJobPrice === undefined || startJobPrice === '' || isNaN(startJobPrice) || Number(startJobPrice) < 0 ) foundErrors.push('startJobPrice')
                if (endJobPrice === undefined || endJobPrice === '' || isNaN(endJobPrice) || Number(endJobPrice) < 0 ) foundErrors.push('endJobPrice')
                if ((!foundErrors.includes('startJobPrice') && !foundErrors.includes('endJobPrice')) && (jobStatus === 'DRAFT' || jobStatus === 'PENDING_APPROVAL') && (endJobPrice*100) + (startJobPrice*100) !== expectedTotalJobPrice) foundErrors.push('totalJobPrice');
                if ((!foundErrors.includes('startJobPrice') && !foundErrors.includes('endJobPrice')) && (jobStatus !== 'DRAFT' && jobStatus !== 'PENDING_APPROVAL') && (endJobPrice*100) !== expectedTotalJobPrice) { 
                    foundErrors.push('totalJobPrice');
                    foundErrors.push('endJobPrice');
                }
                if (!quoteItems || quoteItems.length === 0) {
                    NotificationBarActions.showNotificationBar('error', 'You need to include at least one quote item before creating this quote. Otherwise, please select "Hourly" or "No Cost".')
                    foundErrors.push('quoteItemDescription')
                }
                break;
            case 'HOURLY':
            case 'PRO_BONO':
                if (hoursEstimation && (isNaN(hoursEstimation) || Number(hoursEstimation) <= 0 )) foundErrors.push('hoursEstimation')
                break;
            case 'NO_COST':
                break;
        }

        setErrors(foundErrors)
        return foundErrors.length === 0
      }

    const isValidQuoteItem = () => {
        const foundErrors = [];
        if (!quoteItemDescription || isEmpty(quoteItemDescription)) foundErrors.push('quoteItemDescription');
        if (!quoteItemUnits || isEmpty(quoteItemUnits) || isNaN(quoteItemUnits) || Number(quoteItemUnits) < 0 ) foundErrors.push('quoteItemUnits')
        if (!quoteItemCostPerUnit || isEmpty(quoteItemCostPerUnit) || isNaN(quoteItemCostPerUnit) || Number(quoteItemCostPerUnit) < 0 ) foundErrors.push('quoteItemCostPerUnit')

        setErrors(foundErrors)
        return foundErrors.length === 0
    }

    const renderRadioLabel = (title, description) => (
        <div className={classes.radioText}><b>{title}: </b><span style={{ fontSize: 16 }}>{description}</span></div>
    )

    const calculateQuickTotal = () => {
        if (!quoteItemUnits || isEmpty(quoteItemUnits) || isNaN(quoteItemUnits) || Number(quoteItemUnits) < 0 ) return '0.00'
        if (!quoteItemCostPerUnit || isEmpty(quoteItemCostPerUnit) || isNaN(quoteItemCostPerUnit) || Number(quoteItemCostPerUnit) < 0 ) return '0.00'
        return (Number(quoteItemUnits) * Number(quoteItemCostPerUnit)).toFixed(2)
    }

    const handleCreateProject = async () => {
        if(!isValidForm()) return;
        try {
            setIsLoading(true);

            let paymentTrackingType = 'STRIPE_INVOICE'
            if (paymentStructure !== 'HOURLY' && paymentStructure !== 'QUOTE') {
                paymentTrackingType = 'NO_TRACKING'
            } else if (!isSeperateInvoice) {
                paymentTrackingType = 'STRIPE_SUBSCRIPTION'
            }

            const jobPayload = {
                job_site_id: jobSite,
                job_details: {
                    job_name: jobName,
                    job_description: jobDescription,
                    job_status: jobStatus,
                    job_payment_structure: paymentStructure,
                    job_payment_tracking_type: paymentTrackingType,
                    job_first_payment_is_mandated: isMandatedFirstPayment
                },
                job_security: {
                    accessible_user_ids: [primaryUserRef.current.user_id],
                    is_hidden: isHidden
                }
              }

              switch (paymentStructure) {
                case 'QUOTE':
                    const verifiedStartJobPrice = (jobStatus === 'DRAFT' || jobStatus === 'PENDING_APPROVAL') ? startJobPrice : 0
                    jobPayload.job_quote = {
                        quote_amount: Math.floor(Number(verifiedStartJobPrice) * 100) + Math.floor(Number(endJobPrice) * 100),
                        quote_amount_due_at_complete: Math.floor(Number(endJobPrice) * 100),
                        quote_amount_due_at_start: Math.floor(Number(verifiedStartJobPrice) * 100),
                        quote_items: quoteItems
                    }
                    break;
                case 'HOURLY':
                case 'PRO_BONO':
                    jobPayload.job_hours = {
                        hours_estimated_total: hoursEstimation ? Number(hoursEstimation) : -1
                    }
                    break;
                case 'NO_COST':
                    break;
              }

            const job = await createJob(getUserId(), jobSite, jobPayload, { notification: clientNotification })
            NotificationBarActions.showNotificationBar('success', 'The project has been created');
            history.replace(`/dashboard/site/${jobSite}/projects/${job.job_id}`)
        } catch(err) {
            const resp = await err;
            console.error(resp);
            NotificationBarActions.showNotificationBar('error', 'Could not create the project at this time.')
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddQuoteItem = () => {
        if (!isValidQuoteItem()) return;
        const quoteItemTotal = 100 * Number(quoteItemCostPerUnit) * Number(quoteItemUnits) 
        const quoteItem = {
            item_id: generateShortUIDString(),
            item_name: quoteItemDescription,
            item_unit_cost: Number((100 * Number(quoteItemCostPerUnit))),
            item_units: Number(quoteItemUnits),
            item_discount: 0,
            item_total: Number(quoteItemTotal)
        }
        setQuoteItems([...quoteItems, quoteItem])
        setQuoteItemDescription('')
        setQuoteItemUnits('')
        setQuoteItemCostPerUnit('')
        setExpectedTotalJobPrice(quoteItemTotal + expectedTotalJobPrice)
    }

    const handleRemoveQuoteItem = (item_id) => {
        const removedItem = quoteItems.find(qi => qi.item_id === item_id)
        const filteredQuoteItems = quoteItems.filter(qi => qi.item_id !== item_id)
        setQuoteItems(filteredQuoteItems)
        setExpectedTotalJobPrice(expectedTotalJobPrice - removedItem.item_total)
    }

    const renderHourlySection = () => {
        return (
            <>
                <Grid item xs={12}>
                    <div className={classes.sectionText}>Hourly Estimation</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Provide a best estimation for the amount of hours that will be incurred by this project.</div>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errors.includes('hoursEstimation')}
                        helperText={errors.includes('hoursEstimation') && 'Please enter a valid estimation. Must be either blank or greater than 0.'}
                        fullWidth
                        className={classes.textField}
                        margin="normal"
                        onChange={(e) => setHoursEstimation(e.target.value)}
                        type="number"
                        label="Total Number of Hours"
                        defaultValue={'0'}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}><b>NOTE: </b>A Clockify project will be created and named {!isEmpty(jobName) ? `"${jobName}",` : ' the same as this project,'} once this ptoejct is saved other than a draft. Please use this project in Clockify when documenting hours.</div>
                </Grid>
            </>
        )
    }

    const renderQuoteSection = () => {
        return (
            <>
                <Grid item xs={12}>
                    <div className={classes.sectionText}>Project Quote</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>{paymentStructure === 'QUOTE' ? `Please provide the agreed upon pricing for this job to share with the client.` : 'No quote will be sent to the client.'}</div>
                </Grid>
                {renderQuoteItemSection()}
                <Grid item xs={12}>
                    <div className={classes.sectionText} style={{ fontSize: 18, marginTop: 0 }}>Quote Pricing</div>
                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 16 }}>Provide the timing and amounts for payments for this quote. The prices must add up to the total quote amount of <b>${(expectedTotalJobPrice / 100).toFixed(2)}</b>.</div>
                    { errors.includes('totalJobPrice') && <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0, color: Colors.redColor }}>The start and end project price MUST add up to the total expected project price.</div> }
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                    error={errors.includes('startJobPrice')}
                    fullWidth
                    className={classes.textField}
                    helperText={(jobStatus !== 'DRAFT' && jobStatus !== 'PENDING_APPROVAL') && 'This project must be a draft or sent to the user for approval to include an amount due at start.'}
                    margin="normal"
                    onChange={(e) => setStartJobPrice(e.target.value)}
                    type="number"
                    label="*Start Project Price"
                    disabled={paymentStructure !== 'QUOTE' || (jobStatus !== 'DRAFT' && jobStatus !== 'PENDING_APPROVAL') }
                    defaultValue={'0.00'}
                    variant="outlined"
                    InputProps={{
                        startAdornment:
                    <span onClick={()=>0}>$</span>
                    }}
                />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                    error={errors.includes('endJobPrice')}
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    onChange={(e) => setEndJobPrice(e.target.value)}
                    disabled={paymentStructure !== 'QUOTE'}
                    type="number"
                    label="*End Project Price"
                    defaultValue={'0.00'}
                    variant="outlined"
                    InputProps={{
                        startAdornment:
                    <span onClick={()=>0}>$</span>
                    }}
                />
                </Grid>
            </>
        )
    }

    const renderQuoteItemSection = () => (
        <>
            <Grid item xs={12}>
                <div className={classes.sectionText} style={{ fontSize: 18}}>Quote Items</div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 24 }}>
                <List>
                    {quoteItems.map((item) => (
                    <ListItem dense key={item.item_id} style={{ marginLeft: 0, paddingLeft: 0}}>
                        <ListItemText primary={`${item.item_name}: ${item.item_units} units  x  $${(item.item_unit_cost/100).toFixed(2)} = ${(item.item_total/100).toFixed(2)}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveQuoteItem(item.item_id)}>
                                <Close />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    ))}
                </List>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemDescription')}
                            helperText={errors.includes('quoteItemDescription') && 'Please enter a quote item description.'}
                            label="Description"
                            variant="outlined"
                            value={quoteItemDescription}
                            onChange={(e) => setQuoteItemDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemUnits')}
                            helperText={errors.includes('quoteItemUnits') && 'Please enter a valid amount of units.'}
                            label="Units"
                            type="number"
                            variant="outlined"
                            value={quoteItemUnits}
                            onChange={(e) => setQuoteItemUnits(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            error={errors.includes('quoteItemCostPerUnit')}
                            helperText={errors.includes('quoteItemCostPerUnit') && 'Please enter a valid cost per unit.' || `Total Cost: $${calculateQuickTotal()}`}
                            label="Cost per Unit"
                            variant="outlined"
                            type="number"
                            value={quoteItemCostPerUnit}
                            onChange={(e) => setQuoteItemCostPerUnit(e.target.value)}
                            InputProps={{
                                startAdornment:
                            <span onClick={()=>0}>$</span>
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton variant='contained' style={{ marginTop: 5}} onClick={handleAddQuoteItem}>
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>
                
            </Grid>
        </>
    )

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item lg={8} md={9} sm={12}>
                    <Grid container justify='flex-start' spacing={1} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <IconButton onClick={() => history.goBack()}>
                                <KeyboardBackspace />
                            </IconButton>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Typography variant="h6" component="h2">
                                Create Draft Project
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper>
                        {!isEmpty(jobSite) && !isSiteClockifyEnabled.current &&
                        <div style={{ backgroundColor: Colors.warningBackground, marginTop: 20, marginBottom: 0, padding: 16, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={'auto'}>
                                    <Warning />
                                </Grid>
                                <Grid item xs={10} sm={'auto'}>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, margin: 0, textAlign: 'left'  }}>This client is not configured for Clockify and therefore cannot start projects.</div>
                                    <Button 
                                    className={classes.buttonTextStyle}
                                    variant='text'
                                    onClick={() => history.push(`/admin/sites/${jobSite}/edit`)} color="primary">
                                    Fix Issue
                                </Button>
                                </Grid>
                            </Grid>
                        </div>
                        }
                        
                        <div style={{padding: 16}}>
                            <Grid container spacing={1} justify='center'>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Project Info</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>This section includes the common details of the job. Please provide enough detail in the description for the client to be aware of its expectations and limitations.</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    autoFocus
                                    error={errors.includes('jobName')}
                                    fullWidth
                                    value={jobName}
                                    className={classes.textField}
                                    margin="normal"
                                    type="text"
                                    label="*Project Name"
                                    variant="outlined"
                                    onChange={(e) => setJobName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        error={errors.includes('jobSite')}
                                        fullWidth
                                        className={classes.textField}
                                        helperText={primaryUserRef.current ? `${primaryUserRef.current.user_personal.first_name} ${primaryUserRef.current.user_personal.last_name} will be able to access this task.`: "The selected site's primary user will be able to access this task."}
                                        label="*Project Site"
                                        variant="outlined"
                                        value={jobSite}
                                        onChange={(e) => {
                                            setJobSiteId(e.target.value)
                                        }}
                                    >
                                        {sites.map((site) => (
                                        <MenuItem key={site.site_id} value={site.site_id}>
                                            {site.site_details.site_name}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errors.includes('jobDescription')}
                                        fullWidth
                                        value={jobDescription}
                                        className={classes.textField}
                                        margin="normal"
                                        type="text"
                                        multiline
                                        rows={6}
                                        label="*Detailed Description"
                                        variant="outlined"
                                        onChange={(e) => setJobDescription(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Payment Structure</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Select the method in which this project work will be compensated.</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioGroup name="paymentStructure" value={paymentStructure} onChange={(e) => setPaymentStructure(e.target.value)}>
                                        <FormControlLabel value="QUOTE" control={<Radio />} disabled={!isSiteClockifyEnabled.current} label={renderRadioLabel("Quote", "A scope of work and itemized expenses will be used.")} />
                                        <FormControlLabel value="HOURLY" control={<Radio />} disabled={!isSiteClockifyEnabled.current} label={renderRadioLabel("Hourly", "Hours will be tracked and billed seperately.")} />
                                        <FormControlLabel value="PRO_BONO" control={<Radio />} disabled={!isSiteClockifyEnabled.current} label={renderRadioLabel("Pro Bono", "Work will be tracked hourly, but will not be billed.")} />
                                        <FormControlLabel value="WEBMASTER_UPDATE" control={<Radio />} disabled={!isSiteClockifyEnabled.current} label={renderRadioLabel("Webmaster Update", "This work is included in the clients webmaster subscription.")} />
                                        <FormControlLabel value="NO_COST" control={<Radio />} disabled={!isSiteClockifyEnabled.current} label={renderRadioLabel("No Cost", "No direct cost will incurr with this project.")} />
                                    </RadioGroup>
                                </Grid>
                                { paymentStructure === 'QUOTE' && renderQuoteSection() }
                                { (paymentStructure === 'HOURLY' || paymentStructure === 'PRO_BONO') && renderHourlySection() }
                                <Grid item xs={12}>
                                    <div className={classes.sectionText}>Additional Options</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Establish the payment expectations and visibility for this project.</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Switch checked={isHidden} onChange={(e) => {
                                        setIsHidden(e.target.checked)
                                        if (e.target.checked === true) setClientNotification('NONE')
                                    }}/> } label={renderRadioLabel("Hide From Client", "Do not make this project visible to the client.")} />
                                </Grid>
                                <Grid item xs={12} style={{ display: 'none' }}>
                                    <div className={classes.sectionText}>Client Notification</div>
                                    <div className={classes.subtitleText} style={{ fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>Choose if and how the client is to be notified of this project.</div>
                                    {jobStatus === 'DRAFT' && <div className={classes.subtitleText} style={{ fontSize: 16, color: Colors.warningBackground, marginBottom: 0 }}>Notification can not be sent when project is a draft.</div>}
                                </Grid>
                                <Grid item xs={12} style={{ display: 'none' }}>
                                    <RadioGroup name="clientNotification" value={clientNotification} onChange={(e) => setClientNotification(e.target.value)}>
                                        <FormControlLabel disabled={jobStatus === JOB_STATUS_MAP.DRAFT} value="NONE" control={<Radio />} label={renderRadioLabel("Do Not Notify", "The client will not recieve any notification.")} />
                                        <FormControlLabel disabled={jobStatus === JOB_STATUS_MAP.DRAFT || jobStatus === JOB_STATUS_MAP.PENDING || isHidden} value="SEND_START_NOTIFICATION" control={<Radio />} label={renderRadioLabel("Send Notification", "A notification about starting this job will be sent to the client.")} />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                    <Grid container justify='flex-end' style={{ marginTop: 10, marginBottom: 10 }}>
                        <Grid item xs={'auto'}>
                            <Button 
                                className={classes.buttonContainedStyle}
                                variant='contained'
                                disabled={isLoading || !isSiteClockifyEnabled.current}
                                onClick={handleCreateProject} color="primary">
                                { isLoading ?
                                <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={5} />
                                : <span>Create Project</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default withStyles(styles)(AdminAddJobComponent);