import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = {
  backgroundStyle : {
    position:'fixed',
    top:0,
    left:0,
    height:'100%',
    width:'100vw',
    backgroundColor:'#373536'
  },
  termsTitleStyle: {
    fontSize:38,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTitleImportantStyle: {
    fontSize:24,
    marginBottom:10,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  subSectionTitleImportantStyle: {
    fontSize:20,
    marginBottom:10,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  subSectionTitleStyle: {
    fontSize:20,
    marginBottom:10,
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTextImportantStyle: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838',
    paddingTop: 5
  },
  sectionTextImportantIndent1Style: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838',
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:0
  },
  sectionTextImportantIndent2Style: {
    fontSize:18,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838',
    paddingTop: 3,
    paddingLeft:40
  },
  sectionTitleStyle: {
    fontSize:18,
    marginBottom:10,
    fontWeight:'bold',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
  sectionTextStyle: {
    fontSize:15,
    fontWeight:'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontFamily:'Nunito Sans, sans-serif',
    color:'#383838'
  },
}

export default class TermsAndConditions extends Component {


    render() {
        return (
            <div style={{backgroundColor: '#DADADA'}}>
                <Grid container justify="center">
                    <Grid item xs={11} sm={10} md={9}>
                        <Paper style={{ position: 'relative', marginTop: 100, paddingLeft: 50, paddingRight: 50, paddingBottom: 10, marginBottom: 50}}>
                            <div style={{padding:20, marginTop:5}}>
                                <span style={styles.termsTitleStyle}>Privacy Policy</span>
                                <div style={styles.sectionTextImportantStyle}>Effective date: July 15, 2020</div>
                            </div>
                            <div style={{padding:20, marginTop:5}}>
                                <div style={styles.sectionTextImportantStyle}>Better Call Kyle LLC ("us", "we", or "our") operates the https://consultwithkyle.com website (the "Service").</div>
                            </div>
                            <div style={{padding:5, paddingLeft: 20, marginTop:5}}>
                                <div style={styles.sectionTextImportantStyle}>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</div>
                            </div>
                            <div style={{padding:5, paddingLeft: 20, marginTop:5}}>
                                <div style={styles.sectionTextImportantStyle}>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://consultwithkyle.com/terms.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Information Collection and Use</div>
                                <div style={styles.sectionTextImportantStyle}>We collect several different types of information for various purposes to provide and improve our Service to you.</div>
                            </div>

                            <div style={{padding:20, marginTop:0}}>
                                <div style={styles.subSectionTitleImportantStyle}>Types of Data Collected</div>
                            </div>

                            <div style={{padding:20, marginTop:0}}>
                                <div style={styles.subSectionTitleStyle}>Personal Data</div>
                                <div style={styles.sectionTextImportantStyle}>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Email address</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- First name and last name</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Phone number</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Address, State, Province, ZIP/Postal code, City</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Cookies and Usage Data</div>
                            </div>

                            <div style={{padding:20, marginTop:0}}>
                                <div style={styles.subSectionTitleStyle}>Business Data</div>
                                <div style={styles.sectionTextImportantStyle}>If you create a business with the Service, we may ask you to provide us with certain information that can be used to identify, contact, or be able to confirm its existence ("Business Data"). Business identifiable information may include, but is not limited to:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Email address</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Website</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Phone number</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Address, State, Province, ZIP/Postal code, City</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Cookies and Usage Data</div>
                            </div>

                            <div style={{padding:20, marginTop:0}}>
                                <div style={styles.subSectionTitleStyle}>Internal Business Data</div>
                                <div style={styles.sectionTextImportantStyle}>The Service allows you to participate in interactive features when you choose to do so. These interactive features allow you to input internal or proprietary information regarding business or employee items or actions (“Internal Business Data”). Participation in these tools are completely optional, and not mandatory in order to access the Service. Internal Business Data information may include, but is not limited to:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Inventory Item Name, Amount, Price, Usage</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Recipe Ingredients, Directions, Usage</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Business Sales</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- Inventory Waste</div>
                            </div>


                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.subSectionTitleImportantStyle}>Usage Data</div>
                                <div style={styles.sectionTextImportantStyle}>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</div>
                            </div>


                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.subSectionTitleImportantStyle}>Tracking & Cookies Data</div>
                                <div style={styles.sectionTextImportantStyle}>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</div>
                                <div style={styles.sectionTextImportantStyle}>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</div>
                                <div style={styles.sectionTextImportantStyle}>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</div>
                                <div style={styles.sectionTextImportantStyle}>Examples of Cookies we use:</div>
                                <div style={styles.sectionTextImportantIndent2Style}><b>Session Cookies.</b> We use Session Cookies to operate our Service.</div>
                                <div style={styles.sectionTextImportantIndent2Style}><b>Preference Cookies.</b> We use Preference Cookies to remember your preferences and various settings.</div>
                                <div style={styles.sectionTextImportantIndent2Style}><b>Security Cookies</b> We use Security Cookies for security purposes.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Use of Data</div>
                                <div style={styles.sectionTextImportantStyle}>Better Call Kyle, LLC uses the collected data for various purposes:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To provide and maintain the Service</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To notify you about changes to our Service</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To allow you to participate in interactive features of our Service when you choose to do so</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To provide customer care and support</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To provide analysis or valuable information so that we can improve the Service</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To monitor the usage of the Service</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To detect, prevent and address technical issues</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Transfer of Data</div>
                                <div style={styles.sectionTextImportantStyle}>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</div>
                                <div style={styles.sectionTextImportantStyle}>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</div>
                                <div style={styles.sectionTextImportantStyle}>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</div>
                                <div style={styles.sectionTextImportantStyle}>Better Call Kyle, LLC will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Disclosure of Data</div>
                                <div style={styles.subSectionTitleStyle}>Legal Requirements</div>
                                <div style={styles.sectionTextImportantStyle}>Better Call Kyle, LLC may disclose your Personal Data or Business Data in the good faith belief that such action is necessary to:</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To comply with a legal obligation</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To protect and defend the rights or property of Better Call Kyle LLC</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To prevent or investigate possible wrongdoing in connection with the Service</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To protect the personal safety of users of the Service or the public</div>
                                <div style={styles.sectionTextImportantIndent2Style}>- To protect against legal liability</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Security of Data</div>
                                <div style={styles.sectionTextImportantStyle}>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</div>
                            </div>


                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Service Providers</div>
                                <div style={styles.sectionTextImportantStyle}>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</div>
                                <div style={styles.sectionTextImportantStyle}>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</div>
                                <div style={styles.subSectionTitleStyle}>Analytics</div>
                                <div style={styles.sectionTextImportantStyle}>We may use third-party Service Providers to monitor and analyze the use of our Service.</div>
                                <div style={styles.sectionTextImportantIndent2Style}><b>Google Analytics</b></div>
                                <div style={styles.sectionTextImportantIndent2Style}>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</div>
                                <div style={styles.sectionTextImportantIndent2Style}>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</div>
                                <div style={styles.sectionTextImportantIndent2Style}>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy?hl=en</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Links to Other Sites</div>
                                <div style={styles.sectionTextImportantStyle}>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</div>
                                <div style={styles.sectionTextImportantStyle}>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Children's Privacy</div>
                                <div style={styles.sectionTextImportantStyle}>Our Service does not address anyone under the age of 18 ("Children").</div>
                                <div style={styles.sectionTextImportantStyle}>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Changes to This Privacy Policy</div>
                                <div style={styles.sectionTextImportantStyle}>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
                                <div style={styles.sectionTextImportantStyle}>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</div>
                                <div style={styles.sectionTextImportantStyle}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                            </div>

                            <div style={{padding:20, marginTop:30}}>
                                <div style={styles.sectionTitleImportantStyle}>Contact Us</div>
                                <div style={styles.sectionTextImportantStyle}>If you have any questions about this Privacy Policy, please contact us:</div>
                                <div style={styles.sectionTextImportantIndent2Style}><b>By email: kyle@consultwithkyle.com</b></div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
