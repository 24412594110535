import React, { useState } from "react";

import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Hidden, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import { Store } from "@material-ui/icons";
import { useEffect, useRef } from "react";
import { getUserId } from "../../../../actions/profile/UserActions";
import Colors from "../../../../constants/Colors";
import NotificationBarActions from "../../../../actions/error-pages/NotificationBarActions";
import { getAllSitesForUser } from "../../../../actions/internal/SiteActions";


const styles = theme => ({
   subtitleText: {
     fontSize:18,
     color: Colors.defaultDarkText,
     fontFamily:'Nunito Sans, sans-serif',
     textAlign:'center',
     marginTop: 5,
     marginBottom:20,
     [theme.breakpoints.up('sm')]: {
         textAlign:'left',
         fontSize:20
     },
   },
   root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
 });

const SitesCard = ({classes, history}) => {
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const sitesData = useRef();

  const [ isLoading, setIsLoading ] = useState(true);
  
  useEffect(() => {
    loadData();
  }, [])

  const handleScheduleConsult = () => {
    window.open('https://consultwithkyle.com/book', '_blank').focus();
  };

  const loadData = async () => {
    try {
      const user_id = getUserId();
      if (user_id === undefined) throw 'No User ID';
      const sites = await getAllSitesForUser(user_id);
      sitesData.current = sites;
    } catch (ex) {
      NotificationBarActions.showNotificationBar('error', 'Could not load business account information at this time. Please try again later.')
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid item sm={12} xs={12}>
       <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h6" component="h2">
            Business Accounts
          </Typography>
          <List className={classes.root}>
            {isLoading && <CircularProgress style={{ color: Colors.primaryColor, marginLeft: 10, marginRight: 10 }} thickness={5} />}
            {sitesData.current && sitesData.current.sites.map((site) => (
              <ListItem key={site.site_id} button={matches} onClick={() => matches && history.push(`/dashboard/site/${site.site_id}`, { site })}>
                <ListItemAvatar>
                  {site && site.site_details && site.site_details.site_img_url ?
                    <Avatar alt={site.site_details.site_name} src={site.site_details.site_img_url} />
                  :
                    <Avatar><Store /></Avatar>
                  }
                </ListItemAvatar>
                <ListItemText primary={site.site_details.site_name} />
                <ListItemSecondaryAction>
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <Button edge="end" aria-label="delete" variant='outlined' onClick={() => history.push(`/dashboard/site/${site.site_id}`, { site })}>
                      Manage
                    </Button>
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {!sitesData.current || sitesData.current.sites.length === 0 && 
              <React.Fragment>
                <Typography variant="body1" component="h2">
                  No Accounts Open Yet...
                </Typography>
                <Typography variant="caption" component="h2" style={{ lineHeight: 'normal' }}>
                  Let's set up a time to talk about your technology goals and how BCK Consulting can help! Click the button below to set up a call.
                </Typography>
                <Button edge="end" aria-label="delete" variant='outlined' style={{ marginTop: 20 }} onClick={handleScheduleConsult}>
                  Book An Appointment
                </Button>
              </React.Fragment>
            }
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(SitesCard);