import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Styles from '../../../constants/Styles';
import { JOB_STATUS_MAP } from '../../../constants/Statuses';

const theme = createMuiTheme({
    palette: {
        primary: {
        main: '#757776'
        },
    },
    typography: { useNextVariants: true, fontSize:20, fontFamily:'Nunito Sans, sans-serif' },
});

const styles = {
    h4Text: Styles.h4Text,
    instructionText: {
        color: '#353736',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:26,
        marginBottom: 15
    },
    detailsText: {
        color: '#959796',
        fontFamily:'Nunito Sans, sans-serif',
        textAlign: 'center',
        fontSize:16,
    },
    headerTextStyle: {
        fontSize:36,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        fontWeight:'bold',
        color:'#030507',
        textAlign:'left',
        paddingTop: 20,
        paddingLeft: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize:30,
        },
      },
      headerSubTextStyle: {
        fontSize:20,
        width:'auto',
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        textAlign:'left', 
        [theme.breakpoints.down('sm')]: {
            fontSize:18,
        },
      },
      buttonStyle: {
        fontSize:18,
        fontFamily:'Nunito Sans, sans-serif',
        color:'#353736',
        fontWeight: 'bold'
      }
}

const AcceptProjectModal = ({ classes, onCancel, onAccept, open, project }) => {

    const renderTerms = () => {
        if (!project) return <div>Are you sure you want to accept this project?</div>
        const { job_quote, job_hours, job_details } = project;
        switch(project.job_details.job_payment_structure) {
            case 'HOURLY':
                return (
                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                        By approving this proposal for <b>{job_details.job_name}</b>, you understand that you are accepting the scope of work, and approving the start of this project. You understand that hours may and will incurr during work on this project, and you are aware of the hourly charge stated on the proposal. 
                        You understand that when this project is completed, the total cost for hours incurred will be billed to the invoice of the month of completion, and you plan to pay the full amount.
                    </Typography>
                )
            case 'PRO_BONO':
            case 'NO_COST':
                return (
                    <>
                        <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                            By approving this proposal for <b>{job_details.job_name}</b>, you understand that you are accepting the scope of work, and approving the start of this project. 
                        </Typography>
                    </>
                )
            case 'QUOTE':
            default:
                const { quote_amount, quote_amount_due_at_complete, quote_amount_due_at_start } = job_quote || {}
                return (
                    <>
                        <Typography variant="caption" component="h2">
                            By pressing "I UNDERSTAND & ACCEPT" you acknowledge your understanding and agreement with the outlined scope of work for the project titled "{job_details.job_name}". <b>You are aware that the total project cost is set at ${((quote_amount)/100).toFixed(2)}</b>, and you have the intention to fulfill this payment in full.
                        </Typography>
                        <br />
                        {quote_amount_due_at_start ?
                        <>
                            <Typography variant="caption" component="h2">
                                Please be informed that upon accepting this proposal, <b>the amount of ${(quote_amount_due_at_start/100).toFixed(2)} will be due prior to this project being started.</b> An invoice for this amount will be sent promptly to you on acceptance.
                            </Typography>
                            <br />
                        </>
                        : undefined
                        }
                        <Typography variant="caption" component="h2">
                            Upon project completion, an invoice detailing the "Due At Completion" amount of ${(quote_amount_due_at_complete/100).toFixed(2)} will be sent to you within 24 hours. We ask that this invoice be settled within a 30-day period from its receipt.
                        </Typography>
                    </>
                )
        }
    }

    return (
            <Dialog
            aria-labelledby="add-item-modal"
            aria-describedby="add an item to your inventory"
            open={open}
            onClose={onCancel}
            >
                <DialogTitle>
                    <Typography variant="subtitle1" component="h2">
                        Please Review:
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    {renderTerms()}
                    <br />
                    <Typography variant="caption" component="h2" style={{ marginTop: -5}}>
                        If you have any questions please contact Kyle at kyle@consultwithkyle.com.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={onAccept} color="primary">
                        I Understand & Accept
                    </Button>
                    <Button 
                        className={classes.buttonStyle}
                        onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
}

export default withStyles(styles)(AcceptProjectModal);